
import { isString } from 'lodash-es'
import { MediaPreview } from '~/core/components'
import { isHeic, isImage, isPDF } from '~/core/functions'
import { NO_AVATAR } from '~/core/constants'
import { computed, defineComponent, PropType } from '~/bridge'
import { MediaSourcesInnerItem, MediaSourcesItem } from './types'

export default defineComponent({
  name: 'MediaSources',
  components: {
    MediaPreview
  },
  props: {
    items: { type: Array as PropType<MediaSourcesItem[]>, default: () => [] },
    defaultRetryMessage: { type: String, default: 'Не загружен' },
    fromMedcard: { type: Boolean, default: false }
  },
  emits: ['click:icon', 'click:delete', 'click:item', 'click:edit', 'click:retry'],
  setup(props, { emit }) {
    const innerItems = computed<MediaSourcesInnerItem[]>(() => props.items!.map((item) => {
      const isFile = item.src instanceof File
      const isPDFFile = isFile ? isPDF(item.src as File) : false
      const isHeicFile = isFile ? isHeic(item.src as File) : false
      const isImageFile = isFile ? isImage(item.src as File) : false

      return {
        key: item.id,
        value: {
          src: getItemSrc(item.src, isHeicFile, isPDFFile),
          hint: getItemHint(item),
          hintColor: getItemHintColor(item),
          icon: getItemIcon(item),
          iconColor: item.iconColor || 'ui-kit-icon-secondary',
          loading: item.loading ?? false,
          deletable: item.deletable ?? false,
          bordered: isPDFFile || isHeicFile,
          imgProps: {
            maxWidth: isPDFFile || isHeicFile ? 24 : '100%',
            maxHeight: isPDFFile || isHeicFile ? 24 : '100%',
            contain: isPDFFile || isHeicFile,
            cover: !isFile || (isImageFile && !isHeicFile),
            lazySrc: NO_AVATAR
          }
        }
      }
    }))

    function getItemIcon(item: MediaSourcesItem) {
      if (item.retry) {
        return 'ui-icon-reset'
      }

      if (item.editable) {
        return 'ui-icon-create'
      }

      return item.icon || ''
    }

    function getItemSrc(src: string | File, isHeic: boolean, isPDF: boolean) {
      if (isString(src)) {
        return src
      }

      if (isHeic) {
        return '/graphics/icons/files/HEIC.svg'
      }

      if (isPDF) {
        return '/graphics/icons/files/PDF.svg'
      }

      return src
    }

    function getItemHint(item: MediaSourcesItem): string {
      if (item.retry) {
        return isString(item.retry) ? item.retry : props.defaultRetryMessage
      }

      return item.errorMessage || item.successMessage || item.hint || ''
    }

    function getItemHintColor(item: MediaSourcesItem): string {
      if (item.errorMessage || item.retry) {
        return 'secondary'
      }

      if (item.successMessage) {
        return 'success'
      }

      return item.hintColor || 'ui-kit-text-info'
    }

    function handleClickItem(index: number) {
      emit('click:item', {
        index,
        item: props.items[index]
      })
    }

    function handleClickIcon(index: number) {
      const payload = {
        index,
        item: props.items[index]
      }

      emit('click:icon', payload)

      if (payload.item.retry) {
        emit('click:retry', payload)

        return
      }

      if (payload.item.editable) {
        emit('click:edit', payload)
      }
    }

    function emitClickDelete(index: number) {
      emit('click:delete', {
        index,
        item: props.items[index]
      })
    }

    return {
      innerItems,
      handleClickItem,
      handleClickIcon,
      emitClickDelete
    }
  }
})
