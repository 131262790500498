var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-badge',_vm._b({},'v-badge',{
    overlap: true,
    dot: true,
    bordered: true,
    bottom: true,
    offsetX: 10,
    offsetY: 24,
    ..._vm.$attrs,
  },false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }