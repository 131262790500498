import type { ActionTree, MutationTree } from 'vuex'
import { GetterTree } from 'vuex'
import { CORE_ACTIONS, CORE_GETTERS, CORE_MUTATIONS } from '~/core/constants'
import { SpecialitiesApi } from '~/core/api'
import { FamilyUser } from '~/core/types/family'
import { familyApi } from '~/core/api/familyApi'
import { SpecialityDto } from '~/core/api/specialities.api'
import { mapFamily } from '~/core/functions/mappers/mapFamily'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'

export const state = () => ({
  family: [] as FamilyUser[],
  featureFlags: {} as Record<string, boolean>,
  specialities: [] as SpecialityDto[],
  isLayoutError: false,
  overlaysCount: 0,
  isSmartAppBannerActive: false
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  [CORE_GETTERS.GET_FAMILY_PROFILE]: state =>
    (uuid: string) => state.family.find(({ uuid: userUuid }) => userUuid === uuid),
  [CORE_GETTERS.GET_SPECIALITY_BY_ID]: state =>
    (id: number) => state.specialities.find(speciality => speciality.id === id)
}

export const mutations: MutationTree<RootState> = {
  [CORE_MUTATIONS.SET_FEATURE_FLAGS](state, payload: Record<string, boolean>) {
    state.featureFlags = payload
  },
  [CORE_MUTATIONS.SET_IS_LAYOUT_ERROR](state, payload: boolean) {
    state.isLayoutError = payload
  },
  [CORE_MUTATIONS.INC_OVERLAYS_COUNT](state) {
    state.overlaysCount++
  },
  [CORE_MUTATIONS.DEC_OVERLAYS_COUNT](state) {
    if (state.overlaysCount > 0) {
      state.overlaysCount--
    }
  },
  [CORE_MUTATIONS.CLEAR_OVERLAYS_COUNT](state) {
    state.overlaysCount = 0
  },
  [CORE_MUTATIONS.SET_SPECIALITIES](state, payload: SpecialityDto[]) {
    state.specialities = payload
  },
  [CORE_MUTATIONS.SET_IS_SMART_APP_BANNER_ACTIVE](state, payload: boolean) {
    state.isSmartAppBannerActive = payload
  },
  [CORE_MUTATIONS.SET_FAMILY](state, payload: FamilyUser[]) {
    state.family = payload
  }
}

export const actions: ActionTree<RootState, any> = {
  async [CORE_ACTIONS.FETCH_SPECIALITIES]({ state, commit, rootState }) {
    if (state.specialities.length) {
      return
    }

    try {
      const { data } = isFeatureEnabled('sff_mt_specialities', rootState.featureFlags)
        ? await SpecialitiesApi.getAllV2()
        : await SpecialitiesApi.getAll()
      commit(CORE_MUTATIONS.SET_SPECIALITIES, data)
    } catch (e) {
      this.$captureException(e, {
        component: 'vuex',
        method: 'FETCH_SPECIALITIES'
      })
    }
  },
  async [CORE_ACTIONS.FETCH_FAMILY]({ commit }) {
    const [
      { data: masterData },
      { data: profileData },
      { data: infoData }
    ] = await Promise.all([
      familyApi.getFamilyListFromMaster(),
      familyApi.getFamilyListFromProfile(),
      familyApi.getFamilyListFromInfo()
    ])

    commit(CORE_MUTATIONS.SET_FAMILY, mapFamily(masterData, profileData, infoData, this.$sentry.captureException))
  }
}
