import { isNumber } from 'lodash-es'

export type GetOnlyAddedFileIdsPayload = Array<{ preview: { retry?: boolean | string }, id: number | string }>

type GetOnlyAddedFileIdsReturn = number[]

type GetOnlyAddedFileIds = (payload: GetOnlyAddedFileIdsPayload) => GetOnlyAddedFileIdsReturn

/**
 * Возвращает id файлов, которые провалидированы и успешно отправлены на бекенд.
 * */
export const getOnlyAddedFileIds: GetOnlyAddedFileIds = files => files
  .filter(({ preview: { retry }, id }) => !retry && isNumber(id))
  .map(({ id }) => id) as number[]
