import { defineAsyncComponent } from 'vue'
import { defineComponent } from '~/bridge'

export const defineAsyncComponentWithLoader = (component: () => Promise<any>) => {
  const OverlayLoading = () => import('~/core/components/OverlayLoading/OverlayLoading.vue')

  return defineAsyncComponent({
    loader: component,
    delay: 100,
    loadingComponent: defineComponent({
      functional: true,
      render: (h) => {
        return h(OverlayLoading, {
          props: {
            absolute: true,
            value: true
          }
        })
      }
    })
  })
}
