import { dayDeclension, hoursDeclension, minutesDeclension, secondsDeclension } from '~/core/constants/wordForms'
import { pluralize } from '~/core/utils/pluralize'

type FormatSecondsToValidTime = (secs: string | number, options?: { words: boolean }) => string

export const formatSecondsToValidTime: FormatSecondsToValidTime = (secs, { words } = { words: false }) => {
  const numberSecs = typeof secs === 'string' ? Number(secs) : secs
  const minutes = Math.trunc((numberSecs / 60) % 60)
  const hours = Math.trunc(((numberSecs / 60) / 60) % 24)
  const days = Math.trunc((numberSecs / 60) / 60 / 24)
  const formattedSecs = String(numberSecs % 60).length === 1 ? `0${numberSecs % 60}` : String(numberSecs % 60)
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  if (words) {
    if (days > 0) {
      const daysString = pluralize(days, dayDeclension)

      return hours > 0 ? `${daysString} ${pluralize(hours, hoursDeclension)}` : daysString
    }

    if (hours > 0) {
      const minutesSentence = minutes > 0 ? ` ${pluralize(minutes, minutesDeclension)}` : ''

      return pluralize(hours, hoursDeclension) + minutesSentence
    }

    if (minutes > 0) {
      return pluralize(minutes, minutesDeclension)
    }

    if (numberSecs > 0) {
      return pluralize(numberSecs, secondsDeclension)
    }
  }

  const resString = `${formattedMinutes}:${formattedSecs}`

  return hours > 0 ? `${hours}:${resString}` : resString
}
