export const getIosVersion = () => {
  try {
    const v = (window.navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/)

    if (!v) {
      return null
    }

    return `${v[1]}.${v[2]}.${v[3] || 0}`
  } catch {
    return null
  }
}
