import { infoClient } from '~/core/utils/clients/infoClient'
import { masterClient } from '~/core/utils/clients/masterClient'
import { RateType, SkipReason } from '~/features/Rates/enums'
import { RateDetailsDto } from '~/features/Rates/api/chat.api.types'
import { RateStatus } from '~/features/Rates/api/chat.api.enums'

const RESOURCE = '/rates'

export type RateExpectedDto = {
  name: string
  avatarPath: string
  datetimeVisitUtc: string
  info: string
  lpuIdProdoctorov: number
  doctorIdProdoctorov: number | null
  lpuName: string
  appointmentId: number
  isLpuRateRequired: boolean
  isDoctorRateRequired: boolean
}

export type RatePreviewDto = {
  name: string
  avatar: string
  rateType: string
  id: number
  messagesCount: number | null
  dtCreated: string
  status: RateStatus
  info: string,
  mainText: string,
  negativeText: string,
  positiveText: string,
  hasModeratorMessage: boolean
}

export type RateListDtoGeneric<T extends RateStatus> = {
  status: T,
  rates: T extends RateStatus.Waiting ? RateExpectedDto[] : RatePreviewDto[]
}

export type RateListDto =
  RateListDtoGeneric<Exclude<RateStatus, RateStatus.Waiting>> |
  RateListDtoGeneric<RateStatus.Waiting>

export type RateCommentDto = {
  comment: string
  commentPos: string
  commentNeg: string
  textHelp: string
  ignoreBadWords: boolean
  id: number
}

type RateDocumentDto = {
  id: number | string
  imgS: string
  imgL?: string
  status?: string
  preview?: string
}

export type RateDocumentsDto = {
  id: number
  help: {
    text: string
    detail: string
  }
  images: RateDocumentDto[]
}

export default {
  get() {
    return masterClient.get<RateListDto[]>(`${RESOURCE}/`, { camelize: true })
  },
  getWaitingRates(payload?: { limit?: number, type?: RateType}) {
    return masterClient.get<RateExpectedDto[]>(`${RESOURCE}/waiting/`, {
      params: payload,
      camelize: true
    })
  },
  getById(type: string, id: string) {
    return masterClient.get<RateDetailsDto>(`${RESOURCE}/${type}/${id}/`, { camelize: true })
  },
  getComment(type: string, id: string) {
    return masterClient.get<RateCommentDto>(`${RESOURCE}/${type}/${id}/comment/`, { camelize: true })
  },
  getDocument(type: string, id: string) {
    return masterClient.get<RateDocumentsDto>(`${RESOURCE}/${type}/${id}/document/`, { camelize: true })
  },
  getPublishedRateUrl(type: string, id: string) {
    return masterClient.get<{ similarRateUrl: string }>(
      `${RESOURCE}/${type}/${id}/similar/`,
      { camelize: true }
    )
  },
  postComment({ type, id }: { type: string, id: string }, payload: Omit<RateCommentDto, 'id' | 'textHelp'>) {
    return masterClient.post(`${RESOURCE}/${type}/${id}/comment/`, payload, { camelize: true })
  },
  postCommentV2({ type, id }: { type: string, id: string }, payload: Omit<RateCommentDto, 'id' | 'textHelp' | 'ignoreBadWords'>) {
    return masterClient.post(`${RESOURCE}/${type}/${id}/comment/`, payload, { camelize: true })
  },
  postDocument({ type, id }: { type: string, id: string }, payload: string) {
    return masterClient.post<RateDocumentDto>(
      `${RESOURCE}/${type}/${id}/document/`,
      { image: payload },
      { camelize: true }
    )
  },
  postComplete({ type, id }: { type: string, id: string }) {
    return masterClient.post(`${RESOURCE}/${type}/${id}/complete/`, {}, { camelize: true })
  },
  postCompleteV2({ type, id }: { type: string, id: string }, ignoreBadWords: boolean) {
    return masterClient.post(
      `${RESOURCE}/${type}/${id}/complete/`,
      { ignoreBadWords },
      { camelize: true }
    )
  },
  deleteDocument({ type, id }: { type: string, id: string }, documentId: number | string) {
    return masterClient.delete<RateDocumentDto>(
      `${RESOURCE}/${type}/${id}/document/${documentId}/`,
      { camelize: true }
    )
  },
  patchScore({ type, id }: { type: string, id: string }, payload: Record<string, number>) {
    return masterClient.patch(`${RESOURCE}/${type}/${id}/`, payload, { camelize: true })
  },
  skipRate(appointmentId: number, reason: SkipReason) {
    return infoClient.post(
      `/appointments/${appointmentId}/skip_rate/`,
      { rateSkipReason: reason },
      { camelize: true }
    )
  },
  publish(type: string, id: string) {
    return masterClient.post(`/rates/${type}/${id}/publish_agreement/`, {}, { camelize: true })
  },
  getRatesCount() {
    return masterClient.get<{ doctorRatesCount: number, lpuRatesCount: number }>(
      `${RESOURCE}/count/`,
      { camelize: true }
    )
  }
}
