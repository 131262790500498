import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=243c4c7e&"
import script from "./Divider.vue?vue&type=script&lang=ts&"
export * from "./Divider.vue?vue&type=script&lang=ts&"
import style0 from "./Divider.vue?vue&type=style&index=0&id=243c4c7e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports