import { DEFAULT_MIN_IMAGE_HEIGHT, DEFAULT_MIN_IMAGE_WIDTH } from '~/core/constants'
import { getImageByFile } from '~/core/functions'

export const validateImageFile = async (
  file: File,
  options = {
    width: DEFAULT_MIN_IMAGE_WIDTH,
    height: DEFAULT_MIN_IMAGE_HEIGHT
  }
) => {
  const { width, height } = await getImageByFile(file)

  return !(width < options.width || height < options.height)
}
