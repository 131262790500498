export const SNACKBAR_MESSAGE = {
  ERROR_WITH_RELOAD: {
    text: 'Произошла ошибка. Попробуйте обновить страницу',
    cancelText: 'обновить',
    cancelHandler: () => {
      window.location.reload()
    }
  },
  FAVOURITES_ADDED: {
    text: 'Добавлено в избранное',
    cancelText: 'перейти'
  },
  FAVOURITES_DELETED: {
    text: 'Удалено из избранного',
    cancelText: 'отменить'
  },
  LINK_COPIED: {
    text: 'Ссылка скопирована'
  },
  MAIL_CONFIRMED: {
    text: 'Почта подтверждена'
  },
  MAIL_CONFIRMED_WITH_ACHIEVEMENT: {
    text: 'Почта подтверждена',
    cancelText: 'К наградам',
    cancelHandler: () => {
      window.location.assign('/achievements')
    }
  },
  MAIL_CHANGED: {
    text: 'Почта изменена'
  },
  RESTORED: {
    text: 'Восстановлено'
  },
  SAVED_WITH_ACHIEVEMENT: {
    text: 'Сохранено',
    cancelText: 'К наградам',
    cancelHandler: () => {
      window.location.assign('/achievements')
    }
  },
  TRY_AGAIN_ERROR: {
    text: 'Произошла ошибка, попробуйте ещё раз'
  },
  TRY_AGAIN_OR_RELOAD_ERROR: {
    text: 'Произошла ошибка. Попробуйте ещё раз или обновите страницу',
    closeText: 'Обновить',
    cancelHandler: () => location.reload()
  }
} as const
