export enum MedcardDocumentType {
  Analysis = 'analysis',
  Conclusion = 'check_up',
  Survey = 'report',
  Recommendation = 'recommendations'
}

export enum TreatmentPlanTaskType {
  ReadRecommendations = 'read_recommendations',
  MakeAppointment = 'make_appointment',
  VisitAppointment = 'visit_appointment'
}

export enum TreatmentPlanRoutes {
  DocumentGroupDetail = 'document_group_detail',
  AppointmentRepeat = 'appointment_repeat',
  AppointmentDetail = 'appointment_detail'
}
