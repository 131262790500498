import { Context } from '@nuxt/types'
import { MINIMUM_ANDROID_VERSION } from '~/core/constants'
import { getAndroidVersion } from './getAndroidVersion'

/**
 * Позволяет проверить является ли текущий userAgent подходящим для App'а.
 * */
export const isValidAndroidVersion = ({ $device, $sentry }: Pick<Context, '$device' | '$sentry'>) => {
  if (!$device.isAndroid) {
    return false
  }

  const fullVersion = getAndroidVersion()
  const majorVersion = fullVersion?.split('.')[0]

  if (!fullVersion || !majorVersion) {
    $sentry.captureMessage('Unknown Android version')

    return true
  }

  return Number(majorVersion) >= MINIMUM_ANDROID_VERSION
}
