export const DISCUSS_GETTERS = {
  IS_EACH_ATTACHMENT_FULFILLED: 'rate/discuss/IS_EACH_ATTACHMENT_FULFILLED',
  IS_EACH_ATTACHMENT_SUCCESSFUL: 'rate/discuss/IS_EACH_ATTACHMENT_SUCCESSFUL',
  GET_ATTACHMENT_BY_KEY: 'rate/discuss/GET_ATTACHMENT_BY_KEY'
}

export const DISCUSS_MUTATIONS = {
  SET_DISCUSSION_ID: 'rate/discuss/SET_DISCUSSION_ID',
  SET_SUPPORT_MESSAGE: 'rate/discuss/SET_SUPPORT_MESSAGE',
  SET_USER_MESSAGE: 'rate/discuss/SET_USER_MESSAGE',
  SET_ATTACHMENTS: 'rate/discuss/SET_ATTACHMENTS',
  SET_REMIND_LATER_AVAILABLE: 'rate/discuss/SET_REMIND_LATER_AVAILABLE',
  ADD_ATTACHMENT: 'rate/discuss/ADD_ATTACHMENT',
  CHANGE_ATTACHMENT: 'rate/discuss/CHANGE_ATTACHMENT',
  DELETE_ATTACHMENT: 'rate/discuss/DELETE_ATTACHMENT',
  CLEAR: 'rate/discuss/CLEAR'
}

export const DISCUSS_ACTIONS = {
  INIT: 'rate/discuss/INIT',
  SAVE_MESSAGE: 'rate/discuss/SAVE_MESSAGE',
  SAVE_ATTACHMENT: 'rate/discuss/SAVE_ATTACHMENT',
  UPDATE_ATTACHMENT: 'rate/discuss/UPDATE_ATTACHMENT',
  DELETE_ATTACHMENT: 'rate/discuss/DELETE_ATTACHMENT',
  SEND: 'rate/discuss/SEND'
}
