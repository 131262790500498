export const isFeatureEnabled = (feature: string, featureFlags: Record<string, boolean>) => {
  if (feature === undefined) {
    return true
  }

  if (feature === '') {
    return false
  }

  return Boolean(featureFlags[feature])
}
