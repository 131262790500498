// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chips-bar{position:relative;scrollbar-width:none;z-index:1}.chips-bar::-webkit-scrollbar{display:none}.chips-bar .v-slide-group__content{padding-left:8px!important;padding-right:8px!important}.chips-bar .v-slide-group__wrapper{flex-shrink:0}.chips-bar__chip{border-color:#8da2ba!important}.chips-bar__chip:last-child{margin-right:0!important}.chips-bar__chip_secondary{background-color:#ecf1fb!important}.chips-bar__chip_secondary_active{background-color:#607a93!important;color:#fff!important}.chips-bar__chip_secondary_active .v-chip__content .base-badge-container{background-color:#1a5dd0!important}.chips-bar__chip_active{background-color:#fff!important;border-color:#1a5dd0!important;color:#1a5dd0!important}.chips-bar__chip_active .v-chip__content .base-badge-container{background-color:#1a5dd0!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#1a5dd0",
	"secondary": "#ff4a37",
	"info": "#1a5dd0",
	"success": "#1eb37c",
	"error": "#ff4a37",
	"warning": "#ffa928",
	"anchor": "#1a5dd0",
	"ui-kit-brand": "#3981f1",
	"ui-kit-illustration": "#3981f1",
	"ui-kit-special": "#904fe2",
	"ui-kit-rating": "#ffa928",
	"ui-kit-button-text-snackbars": "#76cbff",
	"ui-kit-text": "#111",
	"ui-kit-text-secondary": "#424b58",
	"ui-kit-text-info": "#607a93",
	"ui-kit-text-success": "#00815a",
	"ui-kit-text-error": "#f52a14",
	"ui-kit-text-warning": "#df5000",
	"ui-kit-disabled": "#8da2ba",
	"ui-kit-icon-primary": "#111",
	"ui-kit-icon-secondary": "#607a93",
	"ui-kit-bg-primary": "#ecf1fb",
	"ui-kit-bg-secondary": "#f6f9ff",
	"ui-kit-bg-special": "#f0e4f5",
	"ui-kit-bg-success": "#e0f6ee",
	"ui-kit-bg-error": "#fce8e8",
	"ui-kit-bg-warning": "#fff2d9",
	"ui-kit-bg-club": "#ffeee5",
	"ui-kit-bg-gray-80": "#8da2ba",
	"ui-kit-bg-gray-60": "#c7d1df",
	"ui-kit-bg-gray-40": "#f3f6fa",
	"ui-kit-bg-gray-0": "#fff",
	"ui-kit-shadow": "rgba(14,39,77,.129)"
};
module.exports = ___CSS_LOADER_EXPORT___;
