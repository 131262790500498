import { QuestionnaireQuestion } from '~/core/api/questionnaire.api.types'
import { ORDERED_QUESTIONS_IDS } from '~/core/components/Questionnaire/constants'

export const generateQuestions = () => {
  return ORDERED_QUESTIONS_IDS.map(id => ({
    questionId: id,
    answer: null,
    completed: false
  } as QuestionnaireQuestion))
}
