import { pdClient } from '~/core/utils/clients/pdClient'
import { infoClient } from '~/core/utils/clients/infoClient'

export type SpecialityDto = {
  id: number
  name: string
  translit: string
}

export default {
  // NOTE; удалить вместе с флагом sff_mt_specialities
  getAll() {
    return pdClient.get<SpecialityDto[]>('/medtochka/specialities/', { camelize: true })
  },
  getAllV2(): Promise<{ data: SpecialityDto[] }> {
    return infoClient.get('/specialities/')
  }
}
