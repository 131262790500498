export const getImageByFile = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const img = new Image()

    const onLoad = () => {
      URL.revokeObjectURL(url)
      img.removeEventListener('error', onError)
      img.removeEventListener('load', onLoad)
      resolve(img)
    }

    const onError = () => {
      URL.revokeObjectURL(url)
      img.removeEventListener('error', onError)
      img.removeEventListener('load', onLoad)
      reject(new Error('load'))
    }

    img.addEventListener('load', onLoad)

    img.addEventListener('error', onError)

    img.src = url
  })
}
