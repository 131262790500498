export const SNACKBAR_MESSAGE = {
  ACCESS_CLOSED: {
    text: 'Доступ закрыт',
    dontCloseOnRouteChange: true
  },
  CHANGES_SAVED: {
    text: 'Изменения сохранены'
  },
  DOCUMENT_ADDED: {
    text: 'Документ добавлен'
  },
  DOCUMENTS_ADDED: {
    text: 'Документы добавлены'
  },
  DOCUMENT_ADDED_WITH_ACHIEVEMENT: {
    text: 'Документ добавлен',
    cancelText: 'К наградам',
    cancelHandler: () => {
      window.location.assign('/achievements')
    }
  },
  DOCUMENT_DELETED: {
    text: 'Документ удален',
    dontCloseOnRouteChange: true
  },
  DOCUMENT_DELETED_ERROR: {
    text: 'Не получилось удалить документ'
  },
  FOLDER_CREATED: {
    text: 'Папка создана'
  },
  MEDCARD_DOCUMENT_ADDED: {
    text: 'Документ добавлен в медкарту',
    cancelText: 'Перейти'
  },
  TRY_LATER_ERROR: {
    text: 'Произошла ошибка, повторите позже'
  }
} as const
