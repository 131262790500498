
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'ActionBtn',
  props: {
    icon: { type: String, required: true },
    text: { type: String, required: true }
  }
})
