
import { truncate } from 'lodash-es'
import Divider from '~/core/components/Divider/Divider.vue'
import ExpansionPanel from '~/core/components/ExpansionPanel/ExpansionPanel.vue'
import { RateWarningMessage } from '~/core/components/WarningMessage/types'
import { computed, defineComponent, PropType, ref } from '~/bridge'
import RateAbout from '~/features/Rates/components/RateAbout.vue'
import MediaRules from '~/features/Rates/components/RateEdit/MediaRules.vue'
import { RateAboutDetailsDto, RateDetailTextDto } from '~/features/Rates/api/chat.api.types'

const LINK_MAX_LEN = 28

export default defineComponent({
  name: 'WarningMessage',
  components: {
    ExpansionPanel,
    RateAbout,
    MediaRules,
    Divider
  },
  props: {
    message: { type: Object as PropType<RateWarningMessage>, default: null },
    detailInfo: { type: Object as PropType<RateDetailTextDto>, default: null },
    aboutFrom: { type: Object as PropType<RateAboutDetailsDto>, default: null },
    about: { type: Object as PropType<RateAboutDetailsDto>, default: null },
    color: { type: String, default: '' },
    isActive: { type: Boolean }
  },
  setup(props) {
    const isShowAboutFrom = ref(false)
    const isShowDetail = ref(false)

    const hasDetailInfo = computed(() => Boolean(props.detailInfo?.link))

    const messageTextWithLinks = computed(() => {
      const re = /([a-z]+:\/\/)([A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9-]*(\.[A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9-]*)+)((\/|&)\S*)?/g

      return props.message?.text.replaceAll(re, (link) => {
        const linkText = truncate(link, { length: LINK_MAX_LEN })

        return `<a href="${link}">${linkText}</a>`
      }) ?? ''
    })

    function handleDetailLinkClick() {
      if (props.detailInfo?.link === 'Опубликованный отзыв') {
        location.assign(props.detailInfo?.detail)

        return
      }

      isShowDetail.value = true
    }

    return {
      isShowAboutFrom,
      isShowDetail,
      hasDetailInfo,
      messageTextWithLinks,
      handleDetailLinkClick
    }
  }
})
