export enum Category {
  Lpu = 'lpu',
  Doctor = 'doctor',
  Doctors = 'doctors',
  Drug = 'drug',
  All = 'all'
}

export enum CategoryPd {
  Lpu = 'lpu',
  Doctor = 'vrach',
  Services = 'uslugi',
  Diagnostic = 'diagnostika'
}
