import axiosRetry from 'axios-retry'
import { createDefaultClient } from '~/core/functions/client/createDefaultClient'
import { PD_URL } from '~/core/constants'
import { retryCondition, retryDelay } from '~/core/functions'

const client = createDefaultClient(PD_URL)

client.interceptors.request.use((config) => {
  config.withCredentials = true

  return config
})

axiosRetry(client, {
  retries: 3,
  retryCondition,
  retryDelay
})

export { client as pdClient }
