import { useClipboard } from '@vueuse/core'
import { SNACKBAR_MESSAGE as CORE_SNACKBAR } from '~/core/constants/snackbar'
import { useNuxtApp } from '~/bridge'

export const useLinkShare = () => {
  const { $device, $store } = useNuxtApp()
  const { copy } = useClipboard()

  function handleShare(message: string) {
    if ($device.isDesktop || !navigator.share) {
      // NOTE: Clipboard API Apple ждёт промис когда мы пытается что-то записать в буфер обмена.
      // В целом можно решить через использование макротаски
      setTimeout(() => copy(message), 0)
      $store.dispatch('main-snackbar/handleOpen', { ...CORE_SNACKBAR.LINK_COPIED })

      return
    }

    // Пустой catch чтобы не отправлять в sentry unhandled ошибки
    navigator.share({
      text: message
    }).catch(() => { })
  }

  return {
    handleShare
  }
}
