
import { useWebSocket } from '@vueuse/core'
import { useLinkShare } from '~/core/composables/useLinkShare'
import {
  CONTAINER_WIDTH,
  DIALOG_MESSAGE as CORE_DIALOG,
  MEDCARD_GETTERS,
  PROFILE_MUTATIONS
} from '~/core/constants'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import QrReaderPopup from '~/core/components/QrReaderPopup/QrReaderPopup.vue'
import { CameraErrors } from '~/core/enums/errors'
import { useAppLink, usePending } from '~/core/composables'
import { uuidv4 } from '~/core/utils/uuidv4'
import { DEFAULT_HEARTBEAT_MESSAGE } from '~/core/constants/socket'
import { createMtlinkSession } from '~/core/functions'
import { SocketStatus } from '~/core/enums/socketStatus'
import BottomControlsButtons from '~/core/components/BottomControlsButtons/BottomControlsButtons.vue'
import ResultsPlaceholder from '~/core/components/ResultsPlaceholer/ResultsPlaceholder.vue'
import { DOCUMENTS_ACTIONS } from '~/core/constants/store/medcard/documents'
import MedcardShareSelectItems, {
  MedcardShareSelectItemsExpose
} from '~/core/components/MedcardShare/MedcardShareSelectItems.vue'
import { MedcardSessionData } from '~/core/api/mtlink.api'
import { computed, defineComponent, ref, useNuxtApp, useRouter, watch } from '~/bridge'
import { getShareLink } from '~/features/Medcard/functions'
import { MedcardStatus } from '~/features/Profile/enums'

export default defineComponent({
  name: 'MedcardShare',
  components: {
    MedcardShareSelectItems,
    ResultsPlaceholder,
    BottomControlsButtons,
    QrReaderPopup
  },
  emits: ['scanned'],
  setup(_, { emit, expose }) {
    const qrReaderPopup = ref<{ show:() => void, hide:() => void } | null>(null)
    const medcardShareSelectItems = ref<MedcardShareSelectItemsExpose | null>(null)

    const steps = [
      'Попросите врача открыть сайт mt.link',
      'Отсканируйте QR-код c монитора врача',
      'Готово, медкарта сразу откроется'
    ]

    const isSuccessPopupActive = ref(false)
    const retryPopup = ref({
      isActive: false,
      resumeCallback: null as null | (() => void)
    })
    const medcardShareStep = ref<1 | 2>(1)

    const { $dialog, $captureException, $device, $store } = useNuxtApp()
    const { handleShare } = useLinkShare()
    const { appLink } = useAppLink($device)
    const $router = useRouter()

    const { pending: isLoading, refresh } = usePending(async () => {
      try {
        if ($store.state.medcard.status === MedcardStatus.Expired || !isMedcardAvailable.value) {
          $router.push({ name: 'medcard' })

          return
        }

        await $store.dispatch(DOCUMENTS_ACTIONS.FETCH_MEDCARD_DOCUMENTS_COUNT)
        await $store.dispatch(DOCUMENTS_ACTIONS.FETCH_MEDCARD_FOLDERS)
      } catch (e) {
        $captureException(e, {
          component: 'MedcardShare',
          method: 'usePending'
        })
      }
    })

    const medcardActiveSessionsCount = computed(() => $store.state.profile.medcardActiveSessionsCount)
    const isMedcardAvailable = computed(() => $store.getters[MEDCARD_GETTERS.IS_MEDCARD_TOKEN_VALID])
    const isNextStepButtonDisabled = computed(() => !medcardShareSelectItems.value?.selected)

    watch(() => retryPopup.value.isActive, (val: boolean) => {
      if (!val) {
        retryPopup.value.resumeCallback = null
      }
    })

    async function handleLinkShare() {
      if (!medcardShareSelectItems.value) {
        return
      }

      try {
        const payload: MedcardSessionData = medcardShareSelectItems.value.selectedItems

        const { link, code } = await getShareLink(payload)
        const message = `Ссылка на медкарту ${link}\nКод доступа ${code} — он действует только один раз. Если нужен будет новый доступ, пришлю ссылку снова.`

        ymGoal('clickSendMedcard')
        handleShare(message)
      } catch (e) {
        $dialog.open({ ...CORE_DIALOG.BASE_ERROR })
        $captureException(e, { component: 'MedcardShare', method: 'handleLinkShare' })
      }
    }

    function handleOpenQr() {
      qrReaderPopup.value?.show()
    }

    async function handleCameraError(e: Error | string | CameraErrors) {
      if (e === CameraErrors.NotAllowed) {
        await $dialog.open(CORE_DIALOG.CAMERA_ACCESS)

        return
      }

      const isResolved = await $dialog.open(CORE_DIALOG.CAMERA_CONNECTION_ERROR)

      if (isResolved) {
        window.open(appLink.value, '_blank')
      }
    }

    function handleQrScanned({ decodedText, pause, resume, loading }: {
      decodedText: string,
      pause: (v: boolean) => void,
      resume: () => void,
      loading: (v: boolean) => void
    }) {
      let isSocketInitialized = false

      const errorCallback = () => {
        loading(false)
        retryPopup.value.isActive = true
        retryPopup.value.resumeCallback = resume

        if (isSocketInitialized) {
          wsClose()
          isSocketInitialized = false
        }
      }

      pause(true)
      loading(true)

      const url = new URL(decodedText)
      const composedQrTokens = new URLSearchParams(url.search).get('qr_tokens')

      if (!composedQrTokens) {
        errorCallback()

        return
      }

      const [masterToken, infoToken] = composedQrTokens.split('XXX')
      const uuid = uuidv4()

      const { data: wsMessage, status, close: wsClose } = useWebSocket(
        `wss://${location.host}/ws/mt_link/confirmation/${uuid}?subscribe-user`,
        {
          autoReconnect: true,
          heartbeat: { message: DEFAULT_HEARTBEAT_MESSAGE },
          onConnected: async () => {
            if (!medcardShareSelectItems.value) {
              return
            }

            try {
              const payload = {
                uuid,
                masterAnonymousToken: masterToken,
                infoAnonymousToken: infoToken,
                ...medcardShareSelectItems.value.selectedItems
              }

              await createMtlinkSession(payload)
            } catch (e) {
              $captureException(e, {
                component: 'MedcardShare',
                method: 'handleQrScanned'
              })
              errorCallback()
            }
          }
        }
      )

      isSocketInitialized = true

      watch(wsMessage, () => {
        qrReaderPopup.value?.hide()

        ymGoal('clickMtLink')
        $store.commit(PROFILE_MUTATIONS.SET_MEDCARD_ACTIVE_SESSIONS_COUNT, medcardActiveSessionsCount.value + 1)
        emit('scanned')

        isSuccessPopupActive.value = true
        isSocketInitialized = false
        wsClose()
      })

      setTimeout(() => {
        if (status.value !== SocketStatus.Closed) {
          errorCallback()
        }
      }, 5000)
    }

    function handleSubmitRetryPopup() {
      retryPopup.value.isActive = false
      retryPopup.value.resumeCallback?.()
    }

    function handleCloseRetryPopup() {
      retryPopup.value.isActive = false
      qrReaderPopup.value?.hide()
    }

    function resetState() {
      medcardShareStep.value = 1
      medcardShareSelectItems.value?.resetState()
      refresh()
    }

    expose({
      resetState
    })

    return {
      CONTAINER_WIDTH,
      qrReaderPopup,
      medcardShareSelectItems,
      steps,
      retryPopup,
      isSuccessPopupActive,
      medcardShareStep,
      isNextStepButtonDisabled,
      isLoading,
      handleLinkShare,
      handleOpenQr,
      handleCameraError,
      handleQrScanned,
      handleSubmitRetryPopup,
      handleCloseRetryPopup
    }
  }
})
