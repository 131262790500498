import { repeat } from 'lodash-es'

export const base64ToHex = (base64: string) => {
  const raw = window.atob(base64)
  let result = ''
  // eslint-disable-next-line
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16)
    result += (hex.length === 2 ? hex : '0' + hex)
  }

  return result
}

export const base64ToUint8Array = (b64: string) => Uint8Array.from(window.atob(b64), c => c.charCodeAt(0))

export const stringToUint8Array = (s: string) => new TextEncoder().encode(s)

export const uint8ArrayToString = (u8: Uint8Array) => String.fromCharCode.apply(null, Array.from(u8))

export const uint8ArrayToBase64 = (u8: Uint8Array) => window.btoa(uint8ArrayToString(u8))

export const stringToUnicode = (str: string) => str
  .split('')
  .map((value) => {
    const temp = value.charCodeAt(0).toString(16).toUpperCase()

    const requiredZeros = 4 - temp.length

    return `\\u${repeat('0', requiredZeros)}${temp}`
  })
  .join('')
