import type { MutationTree } from 'vuex'
import { removeNamespace } from '~/core/functions'
import { CHAT_MUTATIONS } from '~/core/constants'

/**
 * Todo:
 * здесь должна быть функция-фабрика, которая возвращает default state,
 * но мы тогда теряем intelliSense у редактора при обращении к state
 */
export const state = () => ({
  wsToken: '',
  authorizationToken: ''
})

export type RootState = ReturnType<typeof state>

const MUTATIONS_TYPES = removeNamespace('chat/', CHAT_MUTATIONS)

export const mutations: MutationTree<RootState> = {
  [MUTATIONS_TYPES.SET_WS_TOKEN](state, payload) {
    state.wsToken = payload
  },
  [MUTATIONS_TYPES.SET_AUTH_TOKEN](state, payload) {
    state.authorizationToken = payload
  }
}
