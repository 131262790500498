import { Plugin } from '@nuxt/types'
import { YM_COUNTER_ID } from '~/core/constants'

const initYandexMetrika = () => {
  (function(m, e, t, r, i, k, a) {
    // @ts-ignore
    m[i] = m[i] || function() {
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      (m[i].a = m[i].a || []).push(arguments)
    }
    // @ts-ignore
    m[i].l = 1 * new Date()
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions,no-sequences
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

  window.ym(YM_COUNTER_ID, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  })
}

export default <Plugin> function({ app: { router } }) {
  initYandexMetrika()

  router?.afterEach((to, from) => {
    const baseUrl = location.origin

    window.ym(YM_COUNTER_ID, 'hit', baseUrl + to.fullPath, {
      referer: baseUrl + from.fullPath
    })
  })
}
