import { MedcardDocumentType } from '~/features/Medcard/enums'

export type GetDocumentGroupDatePayload = {
  documentType: MedcardDocumentType
  dtGenerated: DateTimeISO | null
  dtVisit: DateTimeISO | null
}

export default (group: GetDocumentGroupDatePayload) => group.documentType === MedcardDocumentType.Analysis
  ? group.dtVisit!
  : group.dtGenerated || group.dtVisit!
