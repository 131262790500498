import { render, staticRenderFns } from "./MedcardSharePopup.vue?vue&type=template&id=29fe6af2&"
import script from "./MedcardSharePopup.vue?vue&type=script&lang=ts&"
export * from "./MedcardSharePopup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseFullscreenDialog: require('/builds/private/medtochka-frontend/core/components/Base/BaseFullscreenDialog.vue').default})
