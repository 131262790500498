
import { useWindowSize } from '@vueuse/core'
import { computed, defineComponent, nextTick, provide, readonly, ref, UnwrapRef, useNuxtApp } from '~/bridge'
import FullscreenGalleryContent from './FullscreenGalleryContent.vue'
import { GALLERY_EMIT_ERROR_INJECTION, GALLERY_IS_COMPARE_ACTIVE_INJECTION } from './constants'
import { GalleryContentExposed, GalleryFile } from './types'

export default defineComponent({
  name: 'FullscreenGallery',
  components: { FullscreenGalleryContent },
  props: {
    downloadable: {
      type: Boolean,
      default: true
    },
    printable: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    comparable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide', 'delete', 'error', 'document:open'],
  setup(props, { expose, emit }) {
    const isActive = ref(false)
    const activationKey = ref(Date.now())
    const innerFiles = ref<GalleryFile[]>([])
    const activeGalleries = ref({
      left: { isActive: true, fileIndex: 0 },
      right: { isActive: false, fileIndex: 0 }
    })
    const leftGallery = ref<GalleryContentExposed | null>(null)
    const rightGallery = ref<GalleryContentExposed | null>(null)

    const { $device } = useNuxtApp()
    const { width: windowWidth, height: windowHeight } = useWindowSize()

    provide(GALLERY_EMIT_ERROR_INJECTION, e => emit('error', e))
    provide(GALLERY_IS_COMPARE_ACTIVE_INJECTION, readonly(computed(() => isBothGalleriesActive.value)))

    const isBothGalleriesActive = computed(() => activeGalleries.value.left.isActive &&
      activeGalleries.value.right.isActive)

    const width = computed(() => {
      return isBothGalleriesActive.value ? (windowWidth.value / 2) - 2 : windowWidth.value
    })

    const height = computed(() => windowHeight.value)

    const isCompareBtnVisible = computed(() => {
      return !isBothGalleriesActive.value && props.comparable && !$device.isMobile && innerFiles.value.length > 1
    })

    function handleHideGallery(key: keyof UnwrapRef<typeof activeGalleries>) {
      if (isBothGalleriesActive.value) {
        activeGalleries.value[key].isActive = false

        // Этот обработчик вызывается засчёт нажатия, которое уже вызывает блюр, поэтому надо дождаться следующего макротаска, чтобы совершить фокус
        setTimeout(() => {
          leftGallery.value?.focus()
          rightGallery.value?.focus()
        })

        return
      }

      hide()
    }

    function handleShowGallery(key: keyof UnwrapRef<typeof activeGalleries>) {
      activeGalleries.value[key].isActive = true
      // Этот обработчик вызывается засчёт нажатия, которое уже вызывает фокус, поэтому надо дождаться следующего макротаска, чтобы совершить блюр
      setTimeout(() => {
        leftGallery.value?.blur()
        rightGallery.value?.blur()
      })
    }

    async function show(files: GalleryFile[], fromIndex?: number, compare = false) {
      innerFiles.value = files
      activationKey.value = Date.now()

      isActive.value = true

      activeGalleries.value.left.isActive = true
      activeGalleries.value.left.fileIndex = fromIndex ?? 0
      activeGalleries.value.right.isActive = compare && props.comparable && !$device.isMobile
      activeGalleries.value.right.fileIndex = fromIndex ?? 0

      if (!isBothGalleriesActive.value) {
        await nextTick()
        leftGallery.value?.focus()
      }
    }

    function hide() {
      isActive.value = false
      emit('hide')
    }

    function removeIndexes(index: number, count: number) {
      innerFiles.value.splice(index, count)

      if (!innerFiles.value.length) {
        hide()

        return
      }

      correctGalleryIndexAfterRemove(activeGalleries.value.left, index, count)
      correctGalleryIndexAfterRemove(activeGalleries.value.right, index, count)
    }

    function addFiles(files: GalleryFile[], index = 0) {
      innerFiles.value.splice(index, 0, ...files)

      correctGalleryIndexAfterAdded(activeGalleries.value.left, index, files.length)
      correctGalleryIndexAfterAdded(activeGalleries.value.right, index, files.length)
    }

    function correctGalleryIndexAfterRemove(gallery: { fileIndex: number }, index: number, count: number) {
      const lastDeletedIndex = index + count - 1

      if (gallery.fileIndex > lastDeletedIndex) {
        gallery.fileIndex -= count
      } else if (gallery.fileIndex >= index) {
        gallery.fileIndex = index < innerFiles.value.length ? index : index - 1
      }
    }

    function correctGalleryIndexAfterAdded(gallery: { fileIndex: number }, index: number, count: number) {
      if (gallery.fileIndex >= index) {
        gallery.fileIndex += count
      }
    }

    expose({
      show,
      hide,
      removeIndexes,
      addFiles
    })

    return {
      leftGallery,
      rightGallery,
      handleHideGallery,
      handleShowGallery,
      activeGalleries,
      isCompareBtnVisible,
      innerFiles,
      hide,
      activationKey,
      isActive,
      isBothGalleriesActive,
      width,
      height
    }
  }
})
