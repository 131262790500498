import { tryOnBeforeUnmount, tryOnMounted } from '@vueuse/core'
import { ref } from '~/bridge'

export const useMultipleTouchActive = () => {
  const isMultipleTouchActive = ref(false)

  const touchMoveListener = (ev: TouchEvent) => {
    isMultipleTouchActive.value = ev.touches.length >= 2
  }

  const touchEndListener = () => {
    isMultipleTouchActive.value = false
  }

  tryOnMounted(() => {
    document.addEventListener('touchmove', touchMoveListener)

    document.addEventListener('touchend', touchEndListener)
  })

  tryOnBeforeUnmount(() => {
    document.removeEventListener('touchmove', touchMoveListener)

    document.removeEventListener('touchend', touchEndListener)
  })

  return isMultipleTouchActive
}
