
import { repeat } from 'lodash-es'
import { BottomControlsButtons } from '~/core/components'
import { numberDeclension } from '~/core/constants/wordForms'
import { pluralize } from '~/core/utils/pluralize'
import { computed, defineComponent, ref, watch } from '~/bridge'
import { VForm } from '~/types'
import { MailStatus } from '~/features/Profile/enums'

export default defineComponent({
  name: 'EnterCode',
  components: { BottomControlsButtons },
  props: {
    mail: { type: String, required: true },
    codeTimer: { type: String, default: '' },
    errorCode: { type: String, default: '' },
    codeLength: { type: Number, default: 4 },
    noTitle: { type: Boolean, default: false },
    confirmOnFill: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    dailyLimit: { type: Boolean, default: false }
  },
  emits: ['send:code', 'confirm:mail', 'enter', 'input'],
  setup(props, { emit }) {
    const form = ref<VForm | null>(null)
    const isFormCorrect = ref(false)
    const code = ref('')

    const rules = [
      () => props.errorCode !== MailStatus.Expired || 'Время для ввода кода истекло, попробуйте еще раз',
      () => props.errorCode !== MailStatus.AttemptsOut || 'Вы 3 раза ввели код неверно, отправьте новый',
      () => props.errorCode !== MailStatus.WrongCode || 'Неверный код',
      () => props.errorCode !== MailStatus.Invalid || 'Неверный код',
      (v: string) => {
        if (props.confirmOnFill) {
          return true
        }

        const regex = new RegExp(`\\d{${props.codeLength}}`)
        const errorText = `Введите ${pluralize(props.codeLength, numberDeclension)} кода из письма`

        return (v.trim().length === props.codeLength && regex.test(v.trim())) || errorText
      }
    ]

    const inputMask = computed(() => {
      return repeat('#', props.codeLength)
    })

    const isDailyLimit = computed(() => props.dailyLimit || props.errorCode === MailStatus.DailyLimit)

    watch(() => props.errorCode, () => {
      form.value!.validate()
    })

    watch(code, (val) => {
      if (!props.confirmOnFill) {
        return
      }

      if (val.length === props.codeLength &&
        form.value!.validate()
      ) {
        emitConfirmMail(val.trim())
      }
    })

    function handleSendCode() {
      if (isDailyLimit.value) {
        return
      }

      emit('send:code')
      code.value = ''
    }

    function emitConfirmMail(val: string) {
      emit('confirm:mail', val)
    }

    function emitEnter(val: KeyboardEvent) {
      emit('enter', val)
    }

    function emitInput(val: string) {
      emit('input', val)
    }

    return {
      form,
      isFormCorrect,
      code,
      rules,
      inputMask,
      isDailyLimit,
      handleSendCode,
      emitConfirmMail,
      emitEnter,
      emitInput
    }
  }
})
