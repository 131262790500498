import { pdClient } from '~/core/utils/clients/pdClient'

const RESOURCE = '/medtochka'

type UserTypeDto = {
  userType: string | null
}

export default {
  get() {
    return pdClient.get<UserTypeDto>(
      `${RESOURCE}/users/user_type/`,
      { withCredentials: true, camelize: true }
    )
  },
  logOut() {
    return pdClient.post(`${RESOURCE}/sso/logout/`, null, { withCredentials: true, camelize: true })
  }
}
