
import { SupportContactDetails } from '~/core/types'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import { computed, defineComponent, PropType } from '~/bridge'

export default defineComponent({
  name: 'SupportContacts',
  props: {
    support: { type: Object as PropType<SupportContactDetails>, required: true }
  },
  setup(props) {
    const text = computed(() =>
      props.support.phone
        ? 'Ответим на ваши вопросы быстрее, если позвоните с номера, который указан в профиле.'
        : 'Ответим на все вопросы и поможем решить проблему.'
    )

    function handleClickMail() {
      ymGoal('clickMail')
    }

    function handleClickPhone() {
      ymGoal('clickCall')
    }

    return {
      text,
      handleClickMail,
      handleClickPhone
    }
  }
})
