import { infoClient } from '~/core/utils/clients/infoClient'
import { AppointmentStatus, AppointmentType, TelemedState } from '~/features/Appointments/enums'

const RESOURCE = '/appointments/'

type AppointmentListItemDtoBody<T extends AppointmentType> = {
  id: number
  type: T
  title: string
  timedelta: number
  patientTimedelta: number | null
  datetimeVisit: DateTimeISO
  avatarPath: string
  isOnline: boolean
  currentStatus: Exclude<AppointmentStatus, AppointmentStatus.Absence>
  familyProfileUuid: string | null
}

export type AppointmentDoctorListItemDto = AppointmentListItemDtoBody<AppointmentType.Doctor> & {
  specialityName: string
}

export type AppointmentServiceListItemDto = AppointmentListItemDtoBody<AppointmentType.Service>

export type AppointmentTelemedListItemDto = AppointmentListItemDtoBody<AppointmentType.Telemed> & {
  specialityName: string
  telemedState: TelemedState
}

export type AppointmentsListItemDto = AppointmentDoctorListItemDto | AppointmentServiceListItemDto |
  AppointmentTelemedListItemDto

export type AppointmentsListDto = Array<AppointmentsListItemDto>

export default {
  get(status?: Exclude<AppointmentStatus, AppointmentStatus.Absence>, limit?: number) {
    return infoClient.get<AppointmentsListDto>(`${RESOURCE}v2/`, {
      params: { status, limit },
      camelize: true
    })
  },
  getAppointmentsCount() {
    return infoClient.get<{ doctorAppointmentsCount: number, serviceAppointmentsCount: number }>(
      `${RESOURCE}v2/count/`,
      { camelize: true }
    )
  }
}
