import { render, staticRenderFns } from "./FullscreenImageEditor.vue?vue&type=template&id=c9b70f22&"
import script from "./FullscreenImageEditor.vue?vue&type=script&lang=ts&"
export * from "./FullscreenImageEditor.vue?vue&type=script&lang=ts&"
import style0 from "./FullscreenImageEditor.vue?vue&type=style&index=0&id=c9b70f22&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/builds/private/medtochka-frontend/core/components/Base/BaseBtn.vue').default})
