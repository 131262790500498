import { Category } from '~/core/enums'
import { SNACKBAR_MESSAGE as CORE_SNACKBAR } from '~/core/constants/snackbar'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import { ref, Ref, useNuxtApp, useRouter } from '~/bridge'
import { FavouritesApi } from '~/features/Favourites/api'

type UseFavouritesPayload = {
  afterRemoveFromFavourites: () => void,
  afterAddToFavourites: () => void
}

type UseFavouritesReturn = {
  isLoadingFavourites: Ref<boolean>
  addToFavourites: (id: number, type: string, successMessage?: () => void) => void
  removeFromFavourites: (id: number, type: string) => void
  handleClickFavourites: (id: number, type: string, isFavourites: boolean) => void
  showAddToFavouritesSnackbar: () => void
}

type UseFavourites = (payload: UseFavouritesPayload) => UseFavouritesReturn

export const useFavourites: UseFavourites = (payload) => {
  const isLoadingFavourites = ref(false)

  const { $store } = useNuxtApp()
  const $router = useRouter()

  function sendMetricEvent(rateType: string) {
    if (rateType === Category.Doctor) {
      ymGoal('clickFavouritesDoctor')

      return
    }

    if (rateType === Category.Lpu) {
      ymGoal('clickFavouritesClinic')
    }
  }

  async function addToFavourites(id: number, type: string, successMessage?: () => void) {
    isLoadingFavourites.value = true

    try {
      await FavouritesApi.add(type, id)

      sendMetricEvent(type)
      payload.afterAddToFavourites()

      successMessage?.()
    } catch {
      await $store.dispatch('main-snackbar/handleOpen', { ...CORE_SNACKBAR.ERROR_WITH_RELOAD })
    } finally {
      isLoadingFavourites.value = false
    }
  }

  function showRemoveFromFavouritesSnackbar(id: number, type: string) {
    $store.dispatch('main-snackbar/handleOpen', {
      ...CORE_SNACKBAR.FAVOURITES_DELETED,
      cancelHandler: () => {
        addToFavourites(id, type, () => {
          $store.dispatch('main-snackbar/handleOpen', CORE_SNACKBAR.RESTORED)
        })
      }
    })
  }

  async function removeFromFavourites(id: number, type: string) {
    isLoadingFavourites.value = true

    try {
      await FavouritesApi.delete(type, id)

      payload.afterRemoveFromFavourites()
      showRemoveFromFavouritesSnackbar(id, type)
    } catch {
      await $store.dispatch('main-snackbar/handleOpen', { ...CORE_SNACKBAR.ERROR_WITH_RELOAD })
    } finally {
      isLoadingFavourites.value = false
    }
  }

  function showAddToFavouritesSnackbar() {
    $store.dispatch('main-snackbar/handleOpen', {
      ...CORE_SNACKBAR.FAVOURITES_ADDED,
      cancelHandler: () => {
        $router.push({ name: 'favourites' })
      }
    })
  }

  function handleClickFavourites(id: number, type: string, isFavourites: boolean) {
    if (isFavourites) {
      removeFromFavourites(id, type)

      return
    }

    addToFavourites(id, type, showAddToFavouritesSnackbar)
  }

  return {
    isLoadingFavourites,
    addToFavourites,
    removeFromFavourites,
    handleClickFavourites,
    showAddToFavouritesSnackbar
  }
}
