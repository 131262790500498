import type { MutationTree } from 'vuex'

export const state = () => ({
  isActive: false
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  open(state) {
    state.isActive = true
  },
  close(state) {
    state.isActive = false
  }
}
