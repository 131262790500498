import Cookies from 'js-cookie'

/**
 * @description
 *
 * Функция достает из кук CSRF токен и возвращает его
 *
 * @return { String } - CSRF токен
 * */

export default function getCSRFToken() {
  return Cookies.get('csrftoken') || ''
}
