import { isNumber, pick } from 'lodash-es'
import { differenceInMonths, differenceInYears } from 'date-fns'
import { CaptureContext } from '@sentry/types'
import { FamilyUser } from '~/core/types/family'
import { composeFullName } from '~/core/functions'
import { monthDeclension, yearDeclension } from '~/core/constants/wordForms'
import { FamilyListFromInfoDto, FamilyListFromMasterDto, FamilyListFromProfileDto } from '~/core/api/familyApi'
import { pluralize } from '~/core/utils/pluralize'

export const mapFamily = (
  masterData: FamilyListFromMasterDto[],
  profileData: FamilyListFromProfileDto[],
  infoData: FamilyListFromInfoDto[],
  captureException: (exception: any, captureContext?: CaptureContext) => string
) => {
  if (
    !infoData.length ||
    !masterData.length ||
    !profileData.length ||
    masterData.findIndex(({ isMain }) => isMain) === -1
  ) {
    const errorText = !masterData.length
      ? 'Получен пустой список семейных профилей'
      : 'В списке семейных профилей отсутствует основной профиль'

    const error = new Error(errorText)

    captureException(error)

    throw error
  }

  return masterData
    .map<FamilyUser | null>((item) => {
      const profileItem = profileData.find(({ masterFamilyProfileUuid }) => masterFamilyProfileUuid === item.uuid)
      const infoItem = infoData.find(({ masterFamilyProfileUuid }) => masterFamilyProfileUuid === item.uuid)

      if (!profileItem || !infoItem) {
        if (item.isMain) {
          const error = new Error('Не найден соответствующий элемент списка профилей с profile сервера для основного профиля')

          captureException(error, {
            tags: {
              master_uuid: item.uuid
            }
          })

          throw error
        }

        captureException(
          new Error('Не найден соответствующий элемент списка профилей с profile сервера'), {
            tags: {
              master_uuid: item.uuid
            }
          })

        return null
      }

      const familyItem: Omit<FamilyUser, 'avatar'> = {
        uuid: item.uuid,
        isMain: item.isMain,
        get fullName() {
          return composeFullName(this)
        },
        ...pick(profileItem, ['forename', 'patronymic', 'surname', 'gender']),
        dateBirth: item.dtBirth,
        get age() {
          if (!this.dateBirth) {
            return null
          }

          return differenceInYears(new Date(), new Date(this.dateBirth))
        },
        get formattedAge() {
          if (!isNumber(this.age)) {
            return null
          }

          if (this.age < 1) {
            const months = differenceInMonths(new Date(), new Date(this.dateBirth))

            return pluralize(months, monthDeclension)
          }

          return pluralize(this.age, yearDeclension)
        },
        medformsUpdateDate: infoItem.dtMedformsUpdatedUtc,
        femaleCalendarId: infoItem.femaleCalendarId,
        hasDentalTreatmentPlan: infoItem.hasDentalTreatmentPlan
      }

      return {
        ...familyItem,
        avatar: familyItem.gender && item.ageGroup && item.avatarType
          ? `/graphics/family-avatars/${item.ageGroup}-${familyItem.gender}-${item.avatarType}.png`
          : '/graphics/sample-user-avatar.svg'
      }
    })
    .filter(item => Boolean(item))
}
