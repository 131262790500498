/**
 * Функция позволяет проверить произошёл ли клик на секцию с ошибками у v-text-field.
 * Применимо для случаев когда на v-text-field навешивается click.native.
 * */
export const isClearTextFieldClick = (ev: MouseEvent) => {
  const clickTarget = ev?.target
  const errorMessageContainer = (ev?.currentTarget as HTMLElement)?.querySelector('.v-text-field__details')

  return !(clickTarget instanceof HTMLElement && errorMessageContainer?.contains(clickTarget))
}
