var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BottomControls',{attrs:{"data-test":"bottom-controls","row":!_vm.onlySubmit && !_vm.column}},[(!_vm.onlySubmit && !_vm.column)?_c('BaseBtn',_vm._b({attrs:{"data-test":"cancel"},on:{"click":_vm.emitClickCancel},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [(_vm.cancelIcon || _vm.$slots['cancel-icon'])?_c('v-icon',[_vm._t("cancel-icon",function(){return [_vm._v("\n          "+_vm._s(_vm.cancelIcon)+"\n        ")]})],2):_vm._e()]},proxy:true}],null,true)},'BaseBtn',{
      text: true,
      depressed: true,
      ..._vm.cancelProps
    },false),[_vm._v("\n    "+_vm._s(_vm.cancelText)+"\n  ")]):_vm._e(),_vm._v(" "),_c('BaseBtn',_vm._b({attrs:{"data-test":"submit","data-qa":"submit"},on:{"click":_vm.emitClickSubmit},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [(_vm.submitIcon || _vm.$slots['submit-icon'])?_c('v-icon',[_vm._t("submit-icon",function(){return [_vm._v("\n          "+_vm._s(_vm.submitIcon)+"\n        ")]})],2):_vm._e()]},proxy:true}],null,true)},'BaseBtn',{
      color: 'primary',
      depressed: true,
      ..._vm.submitProps
    },false),[_vm._v("\n    "+_vm._s(_vm.submitText)+"\n  ")]),_vm._v(" "),(!_vm.onlySubmit && _vm.column)?_c('BaseBtn',_vm._b({attrs:{"data-test":"cancel"},on:{"click":_vm.emitClickCancel},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [(_vm.cancelIcon || _vm.$slots['cancel-icon'])?_c('v-icon',[_vm._t("cancel-icon",function(){return [_vm._v("\n          "+_vm._s(_vm.cancelIcon)+"\n        ")]})],2):_vm._e()]},proxy:true}],null,true)},'BaseBtn',{
      text: true,
      depressed: true,
      ..._vm.cancelProps
    },false),[_vm._v("\n    "+_vm._s(_vm.cancelText)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }