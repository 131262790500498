import { OurProjectItem } from '~/core/types'
import { ML_URL, PB_URL, PD_URL, PT_URL } from '~/core/constants/urls'

export const OUR_PROJECTS: OurProjectItem[] = [
  {
    name: 'prodoctorov',
    logo: '/graphics/logo/prodoctorov.svg',
    description: 'Сайт отзывов о врачах №1 в России',
    url: PD_URL
  },
  {
    name: 'protabletky',
    logo: '/graphics/logo/protabletky.svg',
    description: 'Сайт отзывов врачей о лекарствах',
    url: PT_URL
  },
  {
    name: 'probolezny',
    logo: '/graphics/logo/probolezny.svg',
    description: 'Научно-популярная энциклопедия заболеваний',
    url: PB_URL
  },
  {
    name: 'medlock',
    logo: '/graphics/logo/medlock.svg',
    description: 'Медицинская информационная система',
    url: ML_URL
  }
]

Object.freeze(OUR_PROJECTS)
