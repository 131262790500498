import { isEmpty, isUndefined, omitBy } from 'lodash-es'
import { YM_COUNTER_ID } from '~/core/constants'

type YmUserParams = {
  UserID?: string | number
  ReferralDoctorID?: string | number
}

// NOTE: https://yandex.ru/support/metrica/data/visit-params_data.html
export const ymGoal = (target: string, params?: unknown) => {
  if (window.ym) {
    window.ym(YM_COUNTER_ID, 'reachGoal', target, params)
  }
}

export const ymUserParams = (payload: YmUserParams) => {
  const nonNullablePayload = omitBy(payload, isUndefined)

  if (window.ym && !isEmpty(nonNullablePayload)) {
    window.ym(YM_COUNTER_ID, 'userParams', nonNullablePayload)
  }
}
