
import { first, last } from 'lodash-es'
import DatePickerDialog from '~/core/components/DatePickerDialog/DatePickerDialog.vue'
import { formatDate } from '~/core/utils/formatDate'
import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from '~/bridge'
import { ScheduleItem, ScheduleSelectedSlot } from './types'

export default defineComponent({
  name: 'SlotsBySchedule',
  components: { DatePickerDialog },
  props: {
    value: { type: Object as PropType<ScheduleSelectedSlot | null>, default: null },
    showCurrentDate: { type: [String, Boolean], default: true },
    schedule: {
      type: Array as PropType<ScheduleItem[]>,
      required: true,
      validator: (v: ScheduleItem[]) => Array.isArray(v) && v.length > 0
    },
    initialDate: {
      type: String as PropType<DateISO>,
      default: ''
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const scheduleIndex = ref(0)
    const lazyValue = ref<null | ScheduleSelectedSlot>(props.value ?? null)
    const calendarValue = ref<null | DateISO>(null)

    const selectedScheduleItem = computed(() => props.schedule[scheduleIndex.value])
    const isFirstDaySelected = computed(() => scheduleIndex.value === 0)
    const isLastDaySelected = computed(() => scheduleIndex.value === props.schedule.length - 1)
    const titles = computed(() => props.schedule.map(({ day }) => formatDate(day, 'd MMMM (EEEEEE)')))

    const allowedDatesBounds = computed(() => ({
      min: first(props.schedule)?.day,
      max: last(props.schedule)?.day
    }))

    const formattedSchedule = computed(() => props.schedule.map(({ day, slots }) => ({
      day,
      slots: slots.map(time => ({
        originTime: time,
        formattedTime: formatDate(`${day} ${time}`, 'HH:mm'),
        selected: isSlotSelected(day, time)
      }))
    })))

    watch(() => props.value, (val: ScheduleSelectedSlot | null) => {
      lazyValue.value = val
    })

    watch(scheduleIndex, () => {
      lazyValue.value = null
      emitInput(null)
    })

    onBeforeMount(() => {
      const setIndex = (targetDay?: string) => {
        const index = props.schedule.findIndex(({ day }) => day === targetDay)
        scheduleIndex.value = index !== -1 ? index : 0
      }

      if (props.value) {
        setIndex(props.value.day)
      } else if (props.initialDate) {
        setIndex(props.initialDate)
      }

      resetCalendarValue()
    })

    function resetCalendarValue() {
      calendarValue.value = selectedScheduleItem.value?.day || null
    }

    function handleSelectSlot(time: TimeISO | TimeISOLong, day: DateISO) {
      lazyValue.value = { time, day }
      emitInput(lazyValue.value)
    }

    function handleClickNext() {
      scheduleIndex.value++
      resetCalendarValue()
    }

    function handleClickPrev() {
      scheduleIndex.value--
      resetCalendarValue()
    }

    function handleSubmitCalendarValue(calendarDay: DateISO) {
      const index = props.schedule.findIndex(({ day }) => day === calendarDay)
      scheduleIndex.value = index === -1 ? 0 : index
    }

    function isSlotSelected(day: DateISO, slotTime: string) {
      return day === lazyValue.value?.day && slotTime === lazyValue.value?.time
    }

    function isDateAllowed(date: DateISO) {
      return props.schedule.findIndex(({ day }) => day === date) !== -1
    }

    function emitInput(val: ScheduleSelectedSlot | null) {
      emit('input', val)
    }

    return {
      scheduleIndex,
      lazyValue,
      calendarValue,
      formattedSchedule,
      selectedScheduleItem,
      isFirstDaySelected,
      isLastDaySelected,
      titles,
      allowedDatesBounds,
      resetCalendarValue,
      handleSelectSlot,
      handleClickNext,
      handleClickPrev,
      handleSubmitCalendarValue,
      isDateAllowed,
      emitInput
    }
  }
})
