
import { computed, defineComponent, inject, useNuxtApp } from '~/bridge'
import { GALLERY_IS_COMPARE_ACTIVE_INJECTION } from './constants'

const BTNS_SIZE_BREAKPOINT_FOR_COMPARE_MODE = 1475

export default defineComponent({
  name: 'FullscreenGalleryToolbarBtn',
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup() {
    const { $vuetify } = useNuxtApp()
    const isCompareActive = inject(GALLERY_IS_COMPARE_ACTIVE_INJECTION)

    const isBig = computed(() => {
      return isCompareActive?.value
        ? $vuetify.breakpoint.width > BTNS_SIZE_BREAKPOINT_FOR_COMPARE_MODE
        : $vuetify.breakpoint.lgAndUp
    })

    return {
      isBig
    }
  }
})
