
import { computed, defineComponent, PropType } from '~/bridge'
import { setImage } from '~/features/Rates/functions'
import { RateAboutDetailsDto } from '~/features/Rates/api/chat.api.types'
import { RateType } from '~/features/Rates/enums'

export default defineComponent({
  name: 'RateAbout',
  props: {
    about: { type: Object as PropType<RateAboutDetailsDto>, default: () => ({}) },
    showFavourites: { type: Boolean },
    isLoading: { type: Boolean },
    pdUrl: { type: String, default: '' }
  },
  emits: ['click:favourite'],
  setup(props, { emit }) {
    const hasFavouriteBtn = computed(() => props.about.inUserFavourites !== null && props.showFavourites)

    function handleSetImage() {
      return setImage(props.about.avatar, props.about.rateType)
    }

    function handleClickFavourite(ev: PointerEvent) {
      if (props.isLoading) {
        return
      }

      emitClickFavourite()

      if (ev.currentTarget instanceof HTMLElement && ev.currentTarget.tagName === 'BUTTON') {
        ev.currentTarget.blur()
      }
    }

    function handleClickDoctorInfo(ev: PointerEvent) {
      if (props.pdUrl) {
        window.open(props.pdUrl)
      }

      if (ev.currentTarget instanceof HTMLElement && ev.currentTarget.classList.contains('v-card')) {
        ev.currentTarget.blur()
      }
    }

    function emitClickFavourite() {
      emit('click:favourite')
    }

    return {
      RateType,
      hasFavouriteBtn,
      handleSetImage,
      handleClickFavourite,
      handleClickDoctorInfo
    }
  }
})
