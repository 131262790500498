import { masterClient } from '~/core/utils/clients/masterClient'
import { AgreementStatus, MedcardStatus } from '~/features/Profile/enums'

const RESOURCE = '/me/'

export type MeDto = {
  id: number,
  featureFlags: Record<string, boolean>
  phone: string,
  townName: string,
  townKey: string,
  townId: number,
  townConfirmed: boolean,
  rulesAgreementStatus: AgreementStatus
  medcardStatus: MedcardStatus,
  unreadNotificationsCount: number,
  medcardActiveSessionsCount: number
}

export default {
  get(token: string | undefined) {
    return masterClient.get<MeDto>(RESOURCE, {
      params: { token },
      camelize: true
    })
  }
}
