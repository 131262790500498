
import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue'
import { OurProjectItem } from '~/core/types'
import { defineComponent, PropType } from '~/bridge'

/** Служит для отображения полноэкранного диалога со списком проектов. */
export default defineComponent({
  name: 'OurProjectsDialog',
  components: { BaseFullscreenDialog },
  props: {
    /** Определяет, является ли диалог с проектами активным. */
    isActive: { type: Boolean, default: false },
    /** Массив с проектами, которые необходимо отобразить. */
    projects: { type: Array as PropType<OurProjectItem[]>, required: true }
  },
  emits: ['click:close'],
  setup(props, { emit }) {
    const title = 'Наши проекты'

    function handleClose() {
      emit('click:close')
    }

    function goToProject(url: string) {
      location.assign(url)
    }

    return {
      title,
      handleClose,
      goToProject
    }
  }
})

