/**
 * Приводит ФИО в формате фамилии и инициалов
 *
 * @param name ФИО.
 * @return Возвращает ФИО в виде фамилии и инициалов
 */
export const formatFullName = (name: string): string => {
  const splitted = name.trim().split(' ')

  return `${splitted[0]} ${splitted.slice(1).map(nameItem => `${nameItem[0].toUpperCase()}.`).join(' ')}`
}
