import Cookies from 'js-cookie'
import { createClientWithTokenAuth } from '~/core/functions/client/createClientWithTokenAuth'
import { INFO_URL } from '~/core/constants'
import { CookieKey } from '~/core/enums'
import { infoTokenManager } from '~/core/utils/tokenManagers/infoTokenManager'
import { FAMILY_PROFILE_UUID_STORAGE_KEY } from '~/core/constants/sessionStorage'

const client = createClientWithTokenAuth({
  baseURL: INFO_URL,
  tokenManager: infoTokenManager
})

client.interceptors.request.use((config) => {
  config.headers!['Medcard-Token'] = Cookies.get(CookieKey.MedcardToken) || ''

  const familyProfileUuid = sessionStorage.getItem(FAMILY_PROFILE_UUID_STORAGE_KEY)

  if (familyProfileUuid) {
    config.headers!['Family-Profile-Uuid'] = familyProfileUuid
  }

  return config
})

export { client as medcardClient }
