
import { SupportContactDetails } from '~/core/types'
import SupportContacts from '~/core/components/SupportContacts/SupportContacts.vue'
import { defineComponent, PropType } from '~/bridge'

export default defineComponent({
  name: 'SupportContactsBottomSheet',
  components: { SupportContacts },
  props: {
    support: { type: Object as PropType<SupportContactDetails>, required: true }
  }
})
