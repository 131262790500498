import type { Device } from '@nuxtjs/device'
import { Route } from 'vue-router'
import { StoreAppLinks } from '~/core/enums/appLinks'
import { computed, ComputedRef, Ref, useRoute } from '~/bridge'

type UseAppLinkReturn = {
  appLink: Ref<StoreAppLinks>,
  deepLink: ComputedRef<string>
}

type UseAppLink = ($device: Device, $route?: Route) => UseAppLinkReturn

export const useAppLink: UseAppLink = ($device, $route) => {
  const route = $route ?? useRoute()

  const DEEP_LINK_HOST = 'medtochka://app'

  const appLink = computed(() => $device.isIos || $device.isMacOS
    ? StoreAppLinks.AppStore
    : StoreAppLinks.PlayMarket
  )

  const deepLinkPath = computed(() => {
    switch (route.name) {
      case 'authorization':
        return '/authorization/'
      case 'medcard':
        return '/medcard/'
      case 'appointments-id':
        return `/appointments/${route.params.id}/`
      case 'appointments-id-transfer':
        return `/appointments/${route.params.id}/transfer/`
      case 'rates-type-id':
        return `/rates/${route.params.type}/${route.params.id}/`
      case 'rates-type-id-chat':
        return `/rates/${route.params.type}/${route.params.id}/chat/`
      case 'profile':
        return '/profile/'
      default:
        return '/'
    }
  })

  const deepLink = computed(() => DEEP_LINK_HOST + deepLinkPath.value)

  return {
    appLink,
    deepLink
  }
}
