
import { dragscroll } from 'vue-dragscroll'
import { usePinchZoom, useScrollZoom } from '~/core/composables'
import { defineComponent, onBeforeUnmount, onMounted, ref, toRefs } from '~/bridge'
import { usePDFJS, usePDFSize } from './composables'
import PDFViewPage from './PDFViewerPage.vue'

export default defineComponent({
  name: 'PDFViewer',
  components: { PDFViewPage },
  directives: {
    dragscroll
  },
  props: {
    src: {
      type: String,
      required: true
    },
    isScaled: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  setup(props, { expose }) {
    const refProps = toRefs(props)
    const { load, pdf, destroy: destroyPDF } = usePDFJS()

    const pages = ref<number[]>([])
    const container = ref<HTMLDivElement | null>(null)
    const viewer = ref<HTMLDivElement | null>(null)

    const {
      scale,
      width: innerWidth,
      height: innerHeight,
      setPDFSizes,
      setScale,
      isZoomApplied
    } = usePDFSize(pdf, {
      width: refProps.width,
      height: refProps.height
    })

    usePinchZoom({
      container,
      content: viewer,
      onPinch: setScale
    })

    useScrollZoom({
      target: container,
      onZoom: setScale
    })

    onMounted(async () => {
      await load(props.src)
      pages.value = new Array(pdf.value!.numPages).fill(0).map((_, index) => ++index)
      await setPDFSizes()
    })

    onBeforeUnmount(() => {
      destroyPDF()
    })

    expose({
      zoom: setScale,
      isZoomApplied
    })

    return {
      scale,
      pdf,
      pages,
      innerWidth,
      innerHeight,
      container,
      viewer
    }
  }
})
