
import { promiseTimeout } from '@vueuse/core'
import { BottomControlsButtons } from '~/core/components'
import { blockedDomainRegExp } from '~/core/constants/regex'
import { isValidEmail } from '~/core/utils/isValidEmail'
import { defineComponent, onMounted, PropType, ref, watch, computed } from '~/bridge'
import { VForm } from '~/types'
import { MailStatus } from '~/features/Profile/enums'

const emailRules = [
  (v: string) => !!v.trim().length || 'Укажите почту',
  (v: string) => !blockedDomainRegExp.test(v.trim()) || 'Не поддерживаем outlook, hotmail, live',
  (v: string) => isValidEmail(v.trim()) || 'Неверный формат email',
  (v: string) => v.trim().length < 100 || 'Должно быть не больше 100 символов'
]

export default defineComponent({
  name: 'EnterMail',
  components: { BottomControlsButtons },
  props: {
    errorCode: { type: String as PropType<MailStatus | ''>, default: '' },
    value: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    noBottomPanel: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    label: { type: String, default: 'Электронная почта' },
    rules: { type: Array as PropType<Array<() => boolean | string>>, default: () => [] }
  },
  emits: ['input', 'enter', 'send:mail', 'click:edit'],
  setup(props, { emit, expose }) {
    const form = ref<VForm | null>(null)
    const enterMailTextField = ref<{ focus:() => void } | null>(null)

    const isFormCorrect = ref(false)
    const rules = computed(() => [
      ...emailRules,
      ...props.rules,
      () => props.errorCode !== MailStatus.Invalid || 'Неверный формат email',
      () => props.errorCode !== MailStatus.Unique || 'Эта почта привязана к другому пользователю',
      () => props.errorCode !== MailStatus.SameAsBefore || 'Это текущая почта. Укажите новую',
      () => props.errorCode !== MailStatus.DailyLimit || 'Отправить новый код можно через сутки',
      () => props.errorCode !== MailStatus.Confirmed || 'Данная почта уже подтверждена'
    ])

    watch(() => props.errorCode, () => {
      form.value!.validate()
    })

    onMounted(async () => {
      // NOTE: Ждём завершение следующих анимаций во избежание небольших пролагиваний
      //       - перехода stepper на новый шаг для изменения почты
      //       - открытия попапа подтверждения почты при её добавлении
      await promiseTimeout(250)
      enterMailTextField.value?.focus()
    })

    function sendCode() {
      form.value!.validate()

      if (!isFormCorrect.value) {
        return
      }

      emitInput(props.value.trim())
      emit('send:mail')
    }

    function emitInput(val: string) {
      emit('input', val)
    }

    function emitEnter(val: KeyboardEvent) {
      sendCode()
      emit('enter', val)
    }

    function emitClickEdit() {
      emit('click:edit')
    }

    expose({
      isFormCorrect
    })

    return {
      enterMailTextField,
      form,
      isFormCorrect,
      inputRules: rules,
      sendCode,
      emitInput,
      emitEnter,
      emitClickEdit
    }
  }
})
