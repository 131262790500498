
import Cookies from 'js-cookie'
import { promiseTimeout } from '@vueuse/core'
import { CookieKey } from '~/core/enums'
import { useAppLink } from '~/core/composables'
import { CORE_MUTATIONS } from '~/core/constants'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import { computed, defineComponent, useNuxtApp } from '~/bridge'

export default defineComponent({
  name: 'AppSmartBanner',
  props: {
    app: { type: Boolean, default: true }
  },
  setup() {
    const { $device, $store } = useNuxtApp()
    const { appLink, deepLink } = useAppLink($device)

    const isActive = computed(() => $store.state.isSmartAppBannerActive)

    function handleOpenStore() {
      if ($device.isIos) {
        window.open(appLink.value)

        return
      }

      location.href = appLink.value
    }

    async function handleOpen() {
      ymGoal('clickApp')

      location.href = deepLink.value
      await promiseTimeout(1000)
      location.href = appLink.value
    }

    function close() {
      Cookies.set(CookieKey.AppSmartBannerWasClosed, 'true', { expires: 1 })
      $store.commit(CORE_MUTATIONS.SET_IS_SMART_APP_BANNER_ACTIVE, false)
    }

    return {
      appLink,
      isActive,
      handleOpen,
      handleOpenStore,
      close
    }
  }
})
