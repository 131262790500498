import { FileType } from '~/core/enums'

// https://medium.com/the-everyday-developer/detect-file-mime-type-using-magic-numbers-and-javascript-16bc513d4e1e
export const getFileInfoFromBlob = (blob: Blob): Promise<{ type: FileType, extension: string } | null> => {
  return new Promise((resolve) => {
    const filereader = new FileReader()
    filereader.readAsArrayBuffer(blob.slice(0, 4))
    filereader.onloadend = function(evt) {
      if (evt.target?.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result as ArrayBuffer)
        const bytes: string[] = []
        uint.forEach((byte) => {
          bytes.push(byte.toString(16))
        })
        const hex = bytes.join('').toUpperCase()

        switch (hex) {
          case '89504E47':
            return resolve({
              type: FileType.Png,
              extension: '.png'
            })
          case '25504446':
            return resolve({
              type: FileType.Pdf,
              extension: '.pdf'
            })
          case 'FFD8FFDB':
          case 'FFD8FFE0':
          case 'FFD8FFEE':
          case 'FFD8FFE1':
            return resolve({
              type: FileType.Jpeg,
              extension: '.jpeg'
            })
          default:
            resolve(null)
        }
      }
    }
  })
}
