export const DIALOG_MESSAGE = {
  CAMERA_ACCESS: {
    title: 'Разрешите доступ к камере',
    text: 'Чтобы отсканировать QR-код, разрешите доступ в настройках телефона.'
  },
  CAMERA_CONNECTION_ERROR: {
    title: 'Не получается открыть камеру через браузер',
    text: 'Чтобы отсканировать QR-код, скачайте приложение МедТочка.',
    closeText: 'закрыть',
    confirmText: 'к приложению'
  }
} as const
