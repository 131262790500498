import { createClientWithTokenAuth } from '~/core/functions/client/createClientWithTokenAuth'
import { PROFILE_URL } from '~/core/constants'
import { profileTokenManager } from '~/core/utils/tokenManagers/profileTokenManager'

const client = createClientWithTokenAuth({
  baseURL: PROFILE_URL,
  tokenManager: profileTokenManager
})

export { client as profileClient }
