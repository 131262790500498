import { promiseTimeout } from '@vueuse/core'
import { DIALOG_MESSAGE, PD_URL, PROFILE_MUTATIONS } from '~/core/constants'
import { useNuxtApp } from '~/bridge'
import { createConfirmTownPopup } from '~/features/Appointments/functions/createConfirmTownPopup'

export const useTownConfirm = () => {
  const { $store, $dialog } = useNuxtApp()

  function openUrlWithTownConfirm(url = PD_URL, townKey = '', category = '', showDialogBeforeRedirect = false) {
    const town = $store.state.profile.town

    const redirect = (town = townKey || '') => {
      town = townKey !== 'online' ? town : 'online'

      if (!showDialogBeforeRedirect) {
        window.location.assign(`${url}/${town}/${category}`)

        return
      }

      $dialog.open({
        ...DIALOG_MESSAGE.REDIRECT_TO_PD,
        confirmHandler: () => window.location.assign(`${url}/${town}/${category}`)
      })
    }

    if (!$store.state.profile.townConfirmed) {
      $dialog.open({
        ...createConfirmTownPopup({
          town,
          selectTownHandler: () => $store.commit('town-modal/open', {
            afterTownSelectHandler: async (selectedTown: string) => {
              $store.commit('town-modal/close')
              // NOTE: Ждём завершения анимации закрытия попапа. Иначе анимация непосредственно перед редиректом может лагать
              await promiseTimeout(500)
              redirect(selectedTown)
            }
          }),
          redirectHandler: async () => {
            $store.commit('town-modal/close')
            // TODO: после перехода на Pinia перенести изменение статуса туда, в запрос на подтверждение
            $store.commit(PROFILE_MUTATIONS.SET_TOWN_CONFIRMED, true)
            // NOTE: Ждём завершения анимации закрытия попапа. Иначе анимация непосредственно перед редиректом может лагать
            await promiseTimeout(500)
            redirect()
          }
        })
      })

      return
    }

    redirect()
  }

  return {
    openUrlWithTownConfirm
  }
}
