import { Context } from '@nuxt/types'
import { getIosVersion } from './getIosVersion'

const MINIMUM_IOS_VERSION = 14

/**
 * Позволяет проверить является ли текущий userAgent подходящим для App'а.
 * */
export const isValidIosVersion = ({ $device, $sentry }: Pick<Context, '$device' | '$sentry'>) => {
  if (!$device.isIos) {
    return false
  }

  const fullVersion = getIosVersion()
  const majorVersion = fullVersion?.split('.')[0]

  if (!fullVersion || !majorVersion) {
    $sentry.captureMessage('Unknown Ios version')

    return true
  }

  return Number(majorVersion) >= MINIMUM_IOS_VERSION
}
