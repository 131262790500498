export default class TokenManager {
  private readonly refreshCallback!: () => Promise<string>
  private token = ''
  private refreshPromise: null | Promise<string> = null

  constructor(refreshCallback: () => Promise<string>) {
    this.refreshCallback = refreshCallback
  }

  async refreshToken() {
    if (this.isRefreshing) {
      return this.refreshPromise!
    }

    try {
      this.refreshPromise = this.refreshCallback()
      this.token = await this.refreshPromise

      return this.token
    } finally {
      this.refreshPromise = null
    }
  }

  async getActualToken() {
    if (this.isRefreshing) {
      return this.refreshPromise!
    }

    if (!this.token) {
      this.token = await this.refreshToken()
    }

    return this.token
  }

  get isRefreshing() {
    return this.refreshPromise instanceof Promise
  }
}
