import { Plugin } from '@nuxt/types'
import { GTAG_ID } from '~/core/constants'

// https://developers.google.com/tag-platform/gtagjs/install?hl=ru#google-analytics
export default <Plugin> function({ app: { router }, $sentry }, inject) {
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`)
  document.head.appendChild(script)

  script.addEventListener('error', () => {
    $sentry.captureMessage('Google-gtag failed to load')
  })

  // @ts-ignore
  window.dataLayer = window.dataLayer || []

  function gtag() {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }

  // @ts-ignore
  gtag('js', new Date())
  // @ts-ignore
  gtag('config', GTAG_ID)

  inject('gtag', gtag)

  router?.afterEach((to) => {
    // @ts-ignore
    gtag('config', GTAG_ID, { page_path: to.fullPath })
  })
}
