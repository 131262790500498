export const BaseBadge = () => import('../../core/components/Base/BaseBadge.vue' /* webpackChunkName: "components/base-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseBtn = () => import('../../core/components/Base/BaseBtn.vue' /* webpackChunkName: "components/base-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../core/components/Base/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCircularPreloader = () => import('../../core/components/Base/BaseCircularPreloader.vue' /* webpackChunkName: "components/base-circular-preloader" */).then(c => wrapFunctional(c.default || c))
export const BaseContainer = () => import('../../core/components/Base/BaseContainer.vue' /* webpackChunkName: "components/base-container" */).then(c => wrapFunctional(c.default || c))
export const BaseFullscreenDialog = () => import('../../core/components/Base/BaseFullscreenDialog.vue' /* webpackChunkName: "components/base-fullscreen-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../core/components/Base/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImg = () => import('../../core/components/Base/BaseImg.vue' /* webpackChunkName: "components/base-img" */).then(c => wrapFunctional(c.default || c))
export const BaseNumberMarker = () => import('../../core/components/Base/BaseNumberMarker.vue' /* webpackChunkName: "components/base-number-marker" */).then(c => wrapFunctional(c.default || c))
export const BasePage = () => import('../../core/components/Base/BasePage.vue' /* webpackChunkName: "components/base-page" */).then(c => wrapFunctional(c.default || c))
export const BasePagePreloader = () => import('../../core/components/Base/BasePagePreloader.vue' /* webpackChunkName: "components/base-page-preloader" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../core/components/Base/BaseRadio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseSheetItem = () => import('../../core/components/Base/BaseSheetItem.vue' /* webpackChunkName: "components/base-sheet-item" */).then(c => wrapFunctional(c.default || c))
export const BaseSkeletonLoader = () => import('../../core/components/Base/BaseSkeletonLoader.vue' /* webpackChunkName: "components/base-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseSkeletonTextPreloader = () => import('../../core/components/Base/BaseSkeletonTextPreloader.vue' /* webpackChunkName: "components/base-skeleton-text-preloader" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../core/components/Base/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseSliderItem = () => import('../../core/components/Base/BaseSliderItem.vue' /* webpackChunkName: "components/base-slider-item" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitch = () => import('../../core/components/Base/BaseSwitch.vue' /* webpackChunkName: "components/base-switch" */).then(c => wrapFunctional(c.default || c))
export const BaseTag = () => import('../../core/components/Base/BaseTag.vue' /* webpackChunkName: "components/base-tag" */).then(c => wrapFunctional(c.default || c))
export const BaseTextField = () => import('../../core/components/Base/BaseTextField.vue' /* webpackChunkName: "components/base-text-field" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../core/components/Base/BaseTextarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
