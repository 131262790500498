export enum TherapeuticQuestionId {
  Profile = 'profile',
  HeightWeight = 'height_weight',
  CovidContacts = 'covid_contacts',
  VriSymptoms = 'vri_symptoms',
  CovidVaccination = 'covid_vaccination',
  ChronicDiseases = 'chronic_diseases',
  Smoking = 'smoking',
  Activity = 'activity',
  Alcohol = 'alcohol',
  BloodPressure = 'blood_pressure',
  BloodSugar = 'blood_sugar',
  RelativesDiseases = 'relatives_diseases',
  Monitoring = 'monitoring',
  Drugs = 'drugs',
  Allergy = 'allergy',
  Pregnancy = 'pregnancy',
  OtherInformation = 'other_information'
}

export enum ActivityQuestionValue {
  Sedentary = 'sedentary',
  SportOnceAWeek = 'sport_once_a_week',
  SportRegularly = 'sport_regularly',
  Walking = 'walking'
}

export enum FrequencyQuestionValue {
  No = 'no',
  Year = 'year',
  Month = 'month',
  Week = 'week'
}

export enum BloodPressureQuestionValue {
  High = 'high',
  Low = 'low',
  Hypertonie = 'hypertonie',
  No = 'no',
  DidNotCheck = 'didnt_check'
}

export enum RelativeQuestionValue {
  Father = 'father',
  Mother = 'mother',
  Son = 'son',
  Daughter = 'daughter',
  Brother = 'brother',
  Sister = 'sister',
  Grandfather = 'grandfather',
  GrandMother = 'grandmother'
}

export enum PregnancyQuestionValue {
  Planning = 'planning',
  NotPlanning = 'not_planning',
  Pregnant = 'pregnant',
  BreastFeeding = 'breastfeeding'
}
