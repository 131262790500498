var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"fullscreen-gallery-item-img",style:({
    width: `${_vm.width}px`,
    height: `${_vm.height}px`
  })},[(_vm.width && _vm.height)?_c('PinchScrollZoom',{key:_vm.renderKey,ref:"zoomer",attrs:{"width":_vm.width,"height":_vm.height,"content-height":_vm.height,"content-width":_vm.width,"draggable":true,"scale":_vm.innerScale,"min-scale":_vm.START_IMAGE_SCALE,"max-scale":4},on:{"scaling":_vm.handleScale}},[_c('img',{staticClass:"fullscreen-gallery-item-img__img ym-hide user-select-none",style:({
        width: `${_vm.width}px`,
        height: `${_vm.height}px`,
        paddingTop: `${_vm.GALLERY_TOOLBAR_HEIGHT}px`
      }),attrs:{"alt":"Изображение","src":_vm.src}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }