// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject
import { Context } from '@nuxt/types'
import { TDialogPayload } from '~/store/main-dialog'

export type TDialog = {
  open: (payload: TDialogPayload) => Promise<boolean>
  close: () => void
}

type Injectable = (s: string, object: TDialog) => {}

declare module 'vue/types/vue' {
  // this.$dialog inside Vue components
  interface Vue {
    $dialog: TDialog
  }
}

declare module '@nuxt/types' {
  // nuxtContext.$dialog
  interface Context {
    $dialog: TDialog
  }
}

declare module 'vuex/types/index' {
  // this.$dialog inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $dialog: TDialog
  }
}

export default function({ store }: Context, inject: Injectable) {
  inject('dialog', {
    open(payload: TDialogPayload) {
      return new Promise((resolve) => {
        let dialogPayload: TDialogPayload = {
          ...payload
        }

        if (payload.confirmText) {
          dialogPayload = {
            ...dialogPayload,
            confirmHandler: () => {
              payload.confirmHandler?.()
              resolve(true)
            },
            closeHandler: () => {
              payload.closeHandler?.()
              resolve(false)
            }
          }
        } else {
          dialogPayload = {
            ...dialogPayload,
            closeHandler: () => {
              payload.closeHandler?.()
              resolve(true)
            }
          }
        }

        store.commit('main-dialog/open', dialogPayload)
      })
    },
    close() {
      store.commit('main-dialog/close')
    }
  })
}
