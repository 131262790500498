import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { compareDesc, format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { removeNamespace } from '~/core/functions'
import { DOCUMENTS_ACTIONS, DOCUMENTS_GETTERS, DOCUMENTS_MUTATIONS } from '~/core/constants/store/medcard/documents'
import { FAMILY_PROFILE_UUID_STORAGE_KEY } from '~/core/constants/sessionStorage'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'
import { state as MedcardState } from '~/store/medcard/index'
import { reduceByDate } from '~/features/Medcard/functions'
import {
  ExternalDocumentGroup,
  MedcardApi,
  MedcardDocumentGroupListItemDto
} from '~/features/Medcard/api/medcard.api'
import { FoldersApi, MedcardFolderDto } from '~/features/Medcard/api/folders.api'
import getDocumentGroupDate from '~/features/Medcard/functions/getDocumentGroupDate'
import { MedcardDocumentGroupItem } from '~/features/Medcard/types'
import { TreatmentPlanApi } from '~/features/Medcard/api/treatmentPlan.api'
import { UnconfirmedDocumentType } from '~/features/UnconfirmedDocuments/enums'
import { UnconfirmedDocument } from '~/features/UnconfirmedDocuments/types'

type DocumentsStoreState = {
  totalDocumentCount: number,
  documentsCount: Record<string, number>
  flatMedcardDocuments: Record<string, MedcardDocumentGroupListItemDto[]>
  folders: MedcardFolderDto[],
  externalDocumentGroup: Omit<MedcardDocumentGroupItem, 'id'> | null,
  unconfirmedDocuments: UnconfirmedDocument[] | null
}

type MedcardStoreState = ReturnType<typeof MedcardState>

export const state = (): DocumentsStoreState => ({
  totalDocumentCount: 0,
  documentsCount: {},
  flatMedcardDocuments: {},
  folders: [],
  externalDocumentGroup: null,
  unconfirmedDocuments: null
})

const ACTIONS_TYPES = removeNamespace('medcard/documents/', DOCUMENTS_ACTIONS)

const MUTATIONS_TYPES = removeNamespace('medcard/documents/', DOCUMENTS_MUTATIONS)

const GETTERS_TYPES = removeNamespace('medcard/documents/', DOCUMENTS_GETTERS)

export const getters: GetterTree<DocumentsStoreState, { medcard: MedcardStoreState }> = {
  [GETTERS_TYPES.GET_MEDCARD_DOCUMETS_COUNT]: (state, _, rootState) => {
    const uuid = rootState.medcard.familyProfileUuid

    return uuid ? state.documentsCount[uuid] ?? 0 : 0
  },
  [GETTERS_TYPES.GET_MEDCARD_FLAT_DOCUMENTS](state, _, rootState) {
    const uuid = rootState.medcard.familyProfileUuid

    return uuid ? state.flatMedcardDocuments[uuid] || [] : []
  },
  [GETTERS_TYPES.GET_MEDCARD_DOCUMENT_GROUPS](state, _, rootState) {
    const uuid = rootState.medcard.familyProfileUuid

    return uuid && state.flatMedcardDocuments[uuid]
      ? reduceByDate(state.flatMedcardDocuments[uuid])
      : []
  }
}

export const mutations: MutationTree<DocumentsStoreState> = {
  [MUTATIONS_TYPES.SET_MEDCARD_DOCUMENTS_COUNT](
    state,
    { count, uuid }: { count: number, uuid: string }
  ) {
    if (uuid) {
      state.documentsCount = { ...state.documentsCount, [uuid]: count }
    }
  },
  [MUTATIONS_TYPES.SET_MEDCARD_DOCUMENTS](
    state,
    { payload, uuid } : { payload: MedcardDocumentGroupListItemDto[], uuid: string}
  ) {
    if (uuid) {
      state.flatMedcardDocuments = { ...state.flatMedcardDocuments, [uuid]: payload }
      state.documentsCount = { ...state.documentsCount, [uuid]: payload.length }
    }
  },
  [MUTATIONS_TYPES.SET_FOLDERS](state, payload: MedcardFolderDto[]) {
    state.folders = payload
  },
  [MUTATIONS_TYPES.DELETE_DOCUMENTS_GROUP](state, id: number) {
    Object.keys(state.flatMedcardDocuments).forEach((uuid) => {
      const index = state.flatMedcardDocuments[uuid].findIndex(item => item.id === id)

      state.flatMedcardDocuments[uuid].splice(index, 1)
    })
  },
  [MUTATIONS_TYPES.SET_TOTAL_DOCUMENTS_COUNT](state, payload: number) {
    state.totalDocumentCount = payload
  },
  [MUTATIONS_TYPES.SET_EXTERNAL_DOCUMENT_GROUP](state, payload: ExternalDocumentGroup) {
    const date = getDocumentGroupDate(payload)

    state.externalDocumentGroup = {
      ...payload,
      dateVisit: format(parseISO(date), 'd MMMM yyyy', { locale: ru }),
      dateGenerated: parseISO(date),
      isNew: true
    }
  },
  [MUTATIONS_TYPES.SET_UNCONFIRMED_DOCUMENTS](state, payload: UnconfirmedDocument[]) {
    state.unconfirmedDocuments = payload
  },
  [MUTATIONS_TYPES.READ_DOCUMENT_GROUP](state, id: number) {
    Object.keys(state.flatMedcardDocuments).forEach((uuid) => {
      const documentGroup = state.flatMedcardDocuments[uuid].find(item => item.id === id)

      if (documentGroup) {
        documentGroup.isNew = false
      }
    })
  }
}

export const actions: ActionTree<DocumentsStoreState, DocumentsStoreState> = {
  async [ACTIONS_TYPES.FETCH_MEDCARD_STATE]({ commit }) {
    try {
      const { data } = await MedcardApi.getState()

      data.familyProfilesStatistics.forEach(({ familyProfileUuid: uuid, documentGroupsCount: count }) =>
        commit(MUTATIONS_TYPES.SET_MEDCARD_DOCUMENTS_COUNT, { count, uuid }))

      commit(MUTATIONS_TYPES.SET_TOTAL_DOCUMENTS_COUNT, data.totalDocumentGroupsCount)

      if (data.externalDocumentGroup !== null) {
        commit(MUTATIONS_TYPES.SET_EXTERNAL_DOCUMENT_GROUP, data.externalDocumentGroup)
      }
    } catch (e) {
      this.$captureException(e, {
        component: 'DocumentsStore',
        method: 'fetchMedcardState'
      })
    }
  },
  async [ACTIONS_TYPES.FETCH_MEDCARD_DOCUMENTS_COUNT]({ commit }) {
    const uuid = sessionStorage.getItem(FAMILY_PROFILE_UUID_STORAGE_KEY)

    try {
      const { data } = await MedcardApi.getDocumentsCount()

      commit(MUTATIONS_TYPES.SET_MEDCARD_DOCUMENTS_COUNT, { count: data.documentsCount, uuid })
    } catch (e) {
      this.$captureException(e, {
        component: 'DocumentsStore',
        method: 'fetchMedcardDocumentsCount'
      })
    }
  },
  async [ACTIONS_TYPES.FETCH_MEDCARD_GROUPS]({ state, commit }, payload: { mandatory?: boolean, withError: boolean }) {
    const mandatory = payload.mandatory ?? false
    const withError = payload.withError ?? false
    const uuid = sessionStorage.getItem(FAMILY_PROFILE_UUID_STORAGE_KEY)

    if (uuid && state.flatMedcardDocuments[uuid] && !mandatory) {
      return
    }

    try {
      const { data } = await MedcardApi.getAll()

      commit(MUTATIONS_TYPES.SET_MEDCARD_DOCUMENTS, { payload: data, uuid })
    } catch (e) {
      this.$captureException(e, {
        component: 'DocumentsStore',
        method: 'fetchMedcardGroups'
      })

      if (withError) {
        throw e
      }
    }
  },
  async [ACTIONS_TYPES.FETCH_MEDCARD_FOLDERS]({ commit }) {
    try {
      const { data } = await FoldersApi.getMedcardFolders()

      commit(MUTATIONS_TYPES.SET_FOLDERS, data)
    } catch (e) {
      this.$captureException(e, {
        component: 'DocumentsStore',
        method: 'fetchMedcardFolders'
      })
    }
  },
  async [ACTIONS_TYPES.FETCH_UNCONFIRMED_DOCUMENTS]({ commit, rootState }) {
    try {
      const { data: documentsData } = await MedcardApi.getUnconfirmedDocuments()

      // @ts-ignore
      if (!isFeatureEnabled('sff_dental_treatment_plan_in_document_arrived', rootState.featureFlags)) {
        commit(MUTATIONS_TYPES.SET_UNCONFIRMED_DOCUMENTS,
          documentsData.map(item => ({ type: UnconfirmedDocumentType.Document, ...item })))

        return
      }

      const { data: treatmentPlansData } = await TreatmentPlanApi.getUnconfirmedTreatmentPlans()

      const unconfirmedDocuments = [
        ...documentsData.map(item => ({ type: UnconfirmedDocumentType.Document, ...item })),
        ...treatmentPlansData.map(item => ({ type: UnconfirmedDocumentType.TreatmentPlan, ...item }))
      ].sort((a, b) => compareDesc(new Date(a.dtCreated), new Date(b.dtCreated)))

      commit(MUTATIONS_TYPES.SET_UNCONFIRMED_DOCUMENTS, unconfirmedDocuments)
    } catch (e) {
      this.$captureException(e, {
        component: 'DocumentsStore',
        method: 'fetchUnconfirmedDocuments'
      })
      throw e
    }
  }
}
