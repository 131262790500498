export enum DentalQuestionId {
  OralHygiene = 'oral_hygiene',
  ToothbrushChange = 'toothbrush_change',
  InflammatoryDiseases = 'inflammatory_diseases'
}

export enum OralHygieneQuestionValue {
  OnceADay = 'once_a_day',
  TwiceADay = 'twice_a_day',
  AfterEating= 'after_eating'
}

export enum ToothbrushChangeQuestionValue {
  ThreeMonths = 'three_months',
  SixMonths = 'six_months',
  Year = 'year'
}
