export const PD_URL = `https://${process.env.apiUrl || 'prodoctorov.ru'}`
export const PT_URL = `https://${process.env.ptUrl || 'protabletky.ru'}`
export const PB_URL = `https://${process.env.pbUrl || 'probolezny.ru'}`
export const MO_URL = `https://${process.env.moUrl || 'medotvet.me'}`
export const INFO_URL = `https://${process.env.infoUrl || 'info.medtochka.ru'}`
export const ML_URL = 'https://medlock.ru'
export const PROFILE_URL = `https://${process.env.profileUrl || 'profile.medtochka.ru'}`
export const FINGERPRINT_URL = `https://${process.env.FINGERPRINT_URL || 'r.medtochka.ru/api/v1/fingerprint'}`
export const PAY_PD_URL = 'https://pay-stage.prodoctorov.com'
export const LANDING_URL = `https://${process.env.landingUrl || 'medtochka.ru'}`
export const JITSI_MEET_DOMAIN = process.env.jitsiMeetDomain || 'meet.medtochka.ru'
export const FOREIGN_MT_URL = `https://${process.env.foreignMtUrl || 'app.medtochka.me'}`
