
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom'
import { PinchScrollZoomEmitData } from '@coddicat/vue-pinch-scroll-zoom/lib/types'
import { debounce } from 'lodash-es'
import { defineComponent, ref, watch } from '~/bridge'
import { GALLERY_TOOLBAR_HEIGHT } from './constants'

const START_IMAGE_SCALE = 0.94

export default defineComponent({
  name: 'FullscreenGalleryItemImg',
  components: {
    PinchScrollZoom
  },
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  setup(props, { expose }) {
    const zoomer = ref < null | PinchScrollZoom >(null)
    const innerScale = ref(START_IMAGE_SCALE)
    const renderKey = ref(props.width + props.height)

    watch(() => props.width || props.height, () => {
      changeRenderKey()
    })

    const isZoomApplied = () => innerScale.value > 1.05

    const changeRenderKey = debounce(() => {
      renderKey.value = props.width + props.height
    }, 30)

    function zoom(zoomFactor: number) {
      innerScale.value *= zoomFactor

      if (innerScale.value < START_IMAGE_SCALE) {
        innerScale.value = START_IMAGE_SCALE
      }
    }

    function handleScale(ev: PinchScrollZoomEmitData) {
      innerScale.value = ev.scale
    }

    expose({
      zoom,
      isZoomApplied
    })

    return {
      GALLERY_TOOLBAR_HEIGHT,
      START_IMAGE_SCALE,
      renderKey,
      innerScale,
      handleScale,
      zoomer
    }
  }
})
