import { DentalQuestionId, TherapeuticQuestionId } from '../enums'
import { generateYesNoForm } from './generateYesNoForm'

export const composeLazyImports = () => ({
  QuestionnaireFormProfile: () => import('~/core/components/Questionnaire/components/common/QuestionnaireFormProfile.vue'),
  QuestionnaireFormCovidContacts: generateYesNoForm(TherapeuticQuestionId.CovidContacts),
  QuestionnaireFormVriSymptoms: generateYesNoForm(TherapeuticQuestionId.VriSymptoms),
  QuestionnaireFormInflammatoryDiseases: generateYesNoForm(DentalQuestionId.InflammatoryDiseases),
  QuestionnaireFormWeightHeight: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormWeightHeight.vue'),
  QuestionnaireFormCovidVaccination: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormCovidVaccination.vue'),
  QuestionnaireFormChronicDiseases: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormChronicDiseases.vue'),
  QuestionnaireFormSmoke: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormSmoke.vue'),
  QuestionnaireFormAlcohol: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormAlcohol.vue'),
  QuestionnaireFormActivity: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormActivity.vue'),
  QuestionnaireFormBloodPressure: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormBloodPressure.vue'),
  QuestionnaireFormBloodSugar: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormBloodSugar.vue'),
  QuestionnaireFormRelativeDiseases: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormRelativeDiseases.vue'),
  QuestionnaireFormMonitoring: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormMonitoring.vue'),
  QuestionnaireFormDrugs: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormDrugs.vue'),
  QuestionnaireFormAllergy: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormAllergy.vue'),
  QuestionnaireFormPregnancy: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormPregnancy.vue'),
  QuestionnaireFormOtherInfo: () => import('~/core/components/Questionnaire/components/Therapeutic/QuestionnaireFormOtherInfo.vue'),
  QuestionnaireFormFirstMenstruation: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormFirstMenstruation.vue'),
  QuestionnaireFormLastMenstruation: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormLastMenstruation.vue'),
  QuestionnaireFormPregnancyCount: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormPregnancyCount.vue'),
  QuestionnaireFormPregnancySuccessCount: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormPregnancySuccessCount.vue'),
  QuestionnaireFormChildbirth: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormChildbirth.vue'),
  QuestionnaireFormSexualPartner: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormSexualPartner.vue'),
  QuestionnaireFormContraceptionMethod: () => import('~/core/components/Questionnaire/components/Gynecological/QuestionnaireFormContraceptionMethod.vue'),
  QuestionnaireFormImplants: () => import('~/core/components/Questionnaire/components/common/QuestionnaireFormImplants.vue'),
  QuestionnaireFormOralHygiene: () => import('~/core/components/Questionnaire/components/Dental/QuestionnaireFormOralHygiene.vue'),
  QuestionnaireFormToothbrushChange: () => import('~/core/components/Questionnaire/components/Dental/QuestionnaireFormToothbrushChange.vue'),
  QuestionnaireFormSurgicalOperations: () => import('~/core/components/Questionnaire/components/Surgical/QuestionnaireFormSurgicalOperations.vue'),
  QuestionnaireFormHazards: () => import('~/core/components/Questionnaire/components/Surgical/QuestionnaireFormHazards.vue'),
  QuestionnaireFormInvasiveDiagnostics: () => import('~/core/components/Questionnaire/components/Surgical/QuestionnaireFormInvasiveDiagnostics.vue')
})
