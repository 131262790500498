import { RateType } from '~/features/Rates/enums'

export const setImage = (avatar: string, type: string) => {
  if (avatar) {
    return avatar
  }

  if (type === RateType.Clinic) {
    return '/graphics/no-avatar-clinic.svg'
  }

  if (type === RateType.Doctor) {
    return '/graphics/no-avatar-doctor.svg'
  }

  if (type === RateType.Drug) {
    return '/graphics/no-avatar-drug.svg'
  }
}
