export const QUESTIONNAIRE_MUTATIONS = {
  SET_QUESTION: 'medcard/questionnaire/SET_QUESTION',
  SET_QUESTIONNAIRE: 'medcard/questionnaire/SET_QUESTIONNAIRE',
  SET_LOADING: 'medcard/questionnaire/SET_LOADING',
  SET_QUESTION_GROUPS: 'medcard/questionnaire/SET_QUESTION_GROUPS',
  SET_IS_CHANGES_APPLIED: 'medcard/questionnaire/SET_IS_CHANGES_APPLIED',
  SET_IS_FILLING_STATE: 'medcard/questionnaire/SET_IS_FILLING_STATE'
}

export const QUESTIONNAIRE_ACTIONS = {
  FETCH_QUESTIONNAIRE: 'medcard/questionnaire/FETCH_QUESTIONNAIRE',
  SAVE_QUESTION: 'medcard/questionnaire/SAVE_QUESTION',
  DELETE_QUESTIONNAIRE: 'medcard/questionnaire/DELETE_QUESTIONNAIRE',
  CLEAR_QUESTIONNAIRE: 'medcard/questionnaire/CLEAR_QUESTIONNAIRE'
}

export const QUESTIONNAIRE_GETTERS = {
  FULL_NAME: 'medcard/questionnaire/FULL_NAME',
  IS_EMPTY: 'medcard/questionnaire/IS_EMPTY',
  GET_QUESTION_BY_ID: 'medcard/questionnaire/GET_QUESTION_BY_ID',
  COMPOSED_QUESTIONS: 'medcard/questionnaire/COMPOSED_QUESTIONS',
  COMPLETION_PERCENTAGE: 'medcard/questionnaire/COMPLETION_PERCENTAGE',
  AVAILABLE_QUESTION_GROUPS: 'medcard/questionnaire/AVAILABLE_QUESTION_GROUPS'
}
