
import { defineComponent } from '~/bridge'
import ActionsToolbar from './ActionsToolbar.vue'
import { IMAGE_EDITOR_TOOLBAR_HEIGHT } from './constants'

export default defineComponent({
  name: 'EditorTemplate',
  components: { ActionsToolbar },
  setup() {
    return { IMAGE_EDITOR_TOOLBAR_HEIGHT }
  }
})
