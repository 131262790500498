
import { defineComponent, computed, PropType } from '~/bridge'

const COLORS = ['primary', 'secondary', 'red', 'white', 'default']
/**
 * Компонент-обертка над v-btn.
 * Меняет стиль текса под material 3.
 * Добавляет slot и prop prepend-icon.
 * */
export default defineComponent({
  name: 'BaseBtn',
  inheritAttrs: false,
  props: {
    small: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    /**
     * Не будет работать если color="secondary"
     * */
    text: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: ''
    },
    /**
     * primary, secondary, red, default - специальные цвета для кнопок из ui-kit (Не путать с основными цветами ui-kit).
     * Помимо этих цветом можно также использовать основные цвета ui-kit (Отличные от указанных)
     * */
    color: {
      type: String as PropType<'primary' | 'secondary' | 'red' | 'white' | 'default' | string>,
      default: ''
    }
  },
  setup(props, ctx) {
    const classes = computed(() => {
      return [
        'base-button',
        {
          [`base-button_${props.color}`]: COLORS.includes(props.color),
          'base-button_outlined': props.outlined,
          'base-button_text': props.text,
          'base-button_small': props.small
        }
      ]
    })

    const binds = computed(() => {
      return {
        depressed: true,
        ...ctx.attrs,
        small: props.small,
        outlined: props.outlined,
        text: props.text,
        color: COLORS.includes(props.color) ? '' : props.color
      }
    })

    return {
      binds,
      classes
    }
  }
})
