import { TIMER_TIMEOUT } from '~/core/constants'
import { computed, ref, watch } from '~/bridge'
import { formatSecondsToValidTime } from '~/features/Profile/functions/format-seconds-to-valid-time'

export const useTimer = (options = ref({ words: false })) => {
  const codeTimer = ref<number | null>(null)
  const intervalId = ref(0)

  const formattedTimer = computed(() => {
    if (!codeTimer.value) {
      return
    }

    return formatSecondsToValidTime(codeTimer.value, options.value)
  })

  watch(codeTimer, (val: number | null) => {
    if (val && val <= 0) {
      clearTimer()
    }
  })

  function startCodeTimer(time?: number) {
    clearTimer()
    codeTimer.value = time || TIMER_TIMEOUT

    intervalId.value = window.setInterval(() => {
      codeTimer.value!--
    }, 1000)
  }

  function clearTimer() {
    codeTimer.value = null
    window.clearInterval(intervalId.value)
  }

  return {
    codeTimer,
    formattedTimer,
    startCodeTimer,
    clearTimer
  }
}
