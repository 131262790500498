export enum RateStatus {
  Waiting = 'waiting',
  OnMod = 'on_mod',
  Accept = 'accept',
  Reject = 'reject',
  Correct = 'correct',
  Default = ''
}

export enum DeclineReasons {
  UseCorrectWords = 'use_correct_words',
  SimilarToOthers = 'similar_to_others',
  UseFirstPerson = 'use_first_person',
  NotEnoughStory = 'not_enough_story',
  DontCommentOthers = 'dont_comment_others',
  DoctorPersonalInfo = 'doctor_personal_info',
  AvoidAccusing = 'avoid_accusing',
  RelateToDoctor = 'relate_to_doctor',
  AnswerPatientInPA = 'answer_patient_in_pa',
  OneRatePerPatient = 'one_rate_per_patient',
  CanContactLpu = 'can_contact_lpu',
  OnlyLpuPatients = 'only_lpu_patients',
  RateWillBeDeleted = 'rate_will_be_deleted',
  AlreadyPublished = 'already_published',
  ConfirmDocuments = 'confirm_documents',
  ConfirmRate = 'confirm_rate',
  ProveOnlineConsult = 'prove_online_consult',
  SpecifyWhoVisited = 'specify_who_visited',
  ConfirmDoctor = 'confirm_doctor',
  ReplacedToDoctor = 'replaced_to_doctor',
  ReplacedToLpu = 'replaced_to_lpu',
  RateDiscussion = 'rate_discussion',
  RateAgreement = 'rate_agreement',
  SelfPromotion = 'self_promotion'
}

export enum ActionType {
  Discussion = 'support_message',
  Publish = 'publish_agreement',
  Default = ''
}

export enum AttachmentStatus {
  Uploading = 'UPLOADING',
  Failed = 'FAILED',
  Sent = 'SENT'
}
