
import OurProjectsDialog from '~/core/components/OurProjectsDialog/OurProjectsDialog.vue'
import { DIALOG_MESSAGE as CORE_DIALOG, OUR_PROJECTS, PD_URL } from '~/core/constants'
import { SNACKBAR_MESSAGE as CORE_SNACKBAR } from '~/core/constants/snackbar'
import { CustomDotBadge } from '~/core/components'
import { useLogout } from '~/core/composables'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import { computed, defineComponent, useNuxtApp, useRoute } from '~/bridge'
import { MedcardStatus } from '~/features/Profile/enums'

export default defineComponent({
  name: 'AppMenu',
  components: {
    CustomDotBadge,
    OurProjectsDialog
  },
  props: {
    value: { type: Boolean }
  },
  setup(props, { emit }) {
    const { name: routeName } = useRoute()
    const { $store, $dialog } = useNuxtApp()
    const { logout } = useLogout()

    const isOurProjectsDialogActive = computed(() => $store.state['our-projects-dialog'].isActive)
    const medcardStatus = computed(() => $store.state.medcard.status)
    const hasUnreadNotifications = computed(() => $store.state.profile.unreadNotificationsCount > 0)
    const isUserLogged = computed(() => $store.state.profile.phone)
    const isTownSelectActive = computed(() => $store.state['town-modal'].isActive)

    const medcardStatusText = computed(() => {
      switch (medcardStatus.value) {
        case MedcardStatus.Protected:
          return null
        case MedcardStatus.Expired:
          return 'Заблокирована'
        case MedcardStatus.NeedConfirmation:
          return 'Подтвердите доступ'
      }
    })

    const townName = computed(() => $store.state.profile.town)

    function handleClickTown() {
      $store.commit('town-modal/open')
    }

    function isCurrentPath(name: string) {
      return routeName === name
    }

    function handleClickMedcard() {
      ymGoal('clickMK')
    }

    function handleClickAppointment() {
      ymGoal('clickMyAppointments')
    }

    function handleClickFavourites() {
      ymGoal('clickFavourites')
    }

    function handleClickProfile() {
      ymGoal('clickProfile')
    }

    function handleClickRates() {
      ymGoal('clickMyRates')
    }

    function handleOurProjectsDialogClose() {
      $store.commit('our-projects-dialog/close')
    }

    function openOurProjectsDialog() {
      $store.commit('our-projects-dialog/open')
    }

    async function handleLogout() {
      const isResolved = await $dialog.open({ ...CORE_DIALOG.LOGOUT_DANGER })

      if (!isResolved) {
        return
      }

      try {
        await logout()
      } catch (e) {
        await $store.dispatch('main-snackbar/handleOpen', { ...CORE_SNACKBAR.ERROR_WITH_RELOAD })
      }
    }

    function handleClickPatientLogin() {
      emit('input', false)
    }

    function handleClickLpuLogin() {
      location.assign(`${PD_URL}/profile/login/`)
    }

    return {
      townName,
      OUR_PROJECTS,
      isOurProjectsDialogActive,
      medcardStatus,
      hasUnreadNotifications,
      isUserLogged,
      isTownSelectActive,
      medcardStatusText,
      isCurrentPath,
      handleClickMedcard,
      handleClickAppointment,
      handleClickFavourites,
      handleClickProfile,
      handleClickRates,
      handleOurProjectsDialogClose,
      openOurProjectsDialog,
      handleLogout,
      handleClickPatientLogin,
      handleClickLpuLogin,
      handleClickTown
    }
  }
})
