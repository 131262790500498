
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'SearchBar',
  props: {
    value: { type: [String, Number], default: '' },
    label: { type: String, default: 'Найти' },
    type: { type: String, default: 'text' },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false }
  },
  emits: ['input', 'focus', 'blur', 'click:clear'],
  setup() {
    const rules = [
      (v: string) => (v || '').trim()?.length <= 100 || 'Не больше 100 символов'
    ]

    return {
      rules
    }
  }
})
