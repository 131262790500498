
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'BaseIcon',
  props: {
    color: { type: String, default: '' },
    icon: { type: String, required: true },
    size: { type: Number, default: 40 },
    iconSize: { type: Number, default: 24 },
    rounded: { type: String, default: '12' }
  }
})
