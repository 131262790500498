
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, useNuxtApp, useRouter } from '~/bridge'

const DEFAULT_DIALOG_WIDTH = 550

export default defineComponent({
  name: 'MainDialog',
  setup() {
    const { $store, $dialog } = useNuxtApp()
    const $router = useRouter()

    const persistent = computed(() => $store.state['main-dialog'].persistent)
    const closeText = computed(() => $store.state['main-dialog'].closeText)
    const confirmText = computed(() => $store.state['main-dialog'].confirmText)
    const isDanger = computed(() => $store.state['main-dialog'].isDanger)
    const isDialogActive = computed(() => $store.state['main-dialog'].isActive)
    const title = computed(() => $store.state['main-dialog'].title)
    const text = computed(() => $store.state['main-dialog'].text)
    const image = computed(() => $store.state['main-dialog'].image)
    const afterText = computed(() => $store.state['main-dialog'].afterText)
    const beforeTitle = computed(() => $store.state['main-dialog'].beforeTitle)
    const dialogProps = computed(() => $store.state['main-dialog'].dialogProps)

    onBeforeMount(() => {
      window.addEventListener('popstate', $dialog.close)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('popstate', $dialog.close)
    })

    function closeHandlerOutside() {
      if (persistent.value) {
        return
      }

      closeHandler()
    }

    function closeHandler() {
      if ($store.state['main-dialog'].redirectOnClose) {
        $router.push({
          name: $store.state['main-dialog'].redirectOnClose
        })
      }

      if ($store.state['main-dialog'].closeHandler) {
        $store.state['main-dialog'].closeHandler()
      }

      $dialog.close()
    }

    function confirmHandler() {
      if ($store.state['main-dialog'].confirmHandler) {
        $store.state['main-dialog'].confirmHandler()
        $dialog.close()
      }
    }

    return {
      DEFAULT_DIALOG_WIDTH,
      persistent,
      closeText,
      confirmText,
      isDanger,
      isDialogActive,
      title,
      text,
      image,
      afterText,
      beforeTitle,
      dialogProps,
      closeHandlerOutside,
      closeHandler,
      confirmHandler
    }
  }
})
