import type { MutationTree } from 'vuex'
import Component from 'vue'

/**
 * Todo:
 * здесь должна быть функция-фабрика, которая возвращает default state,
 * но мы тогда теряем intelliSense у редактора при обращении к state
 */
export const state = () => ({
  isActive: false as boolean | undefined,
  persistent: false as boolean | undefined,
  isDanger: false as boolean | undefined,
  redirectOnClose: undefined as string | undefined,
  confirmHandler: undefined as Function | undefined,
  closeHandler: undefined as Function | undefined,
  title: '' as string | undefined,
  text: '' as string | undefined,
  image: '' as string | undefined,
  closeText: '' as string | undefined,
  confirmText: '' as string | undefined,
  beforeTitle: null as null | Component | undefined,
  afterText: null as null | Component | undefined,
  dialogProps: undefined
})

export type RootState = ReturnType<typeof state>
export type TDialogPayload = Partial<RootState>

export const mutations: MutationTree<RootState> = {
  open(state, payload) {
    state.isActive = true
    state.isDanger = payload.isDanger
    state.persistent = payload.persistent
    state.redirectOnClose = payload.redirectOnClose
    state.closeHandler = payload.closeHandler
    state.confirmHandler = payload.confirmHandler
    state.title = payload.title
    state.text = payload.text
    state.image = payload.image
    state.closeText = payload.closeText ?? 'понятно'
    state.confirmText = payload.confirmText
    state.afterText = payload.afterText
    state.beforeTitle = payload.beforeTitle
    state.dialogProps = payload.dialogProps
  },
  close(state) {
    /**
     * Todo:
     * Здесь можно указать Object.assign(state, getDefaultState()),
     * но тогда теряется intelliSence
     */
    state.isActive = false
    state.isDanger = false
    state.persistent = false
    state.redirectOnClose = undefined
    state.confirmHandler = undefined
    state.closeHandler = undefined
    state.title = ''
    state.text = ''
    state.image = ''
    state.closeText = ''
    state.confirmText = ''
    state.afterText = null
    state.beforeTitle = null
    state.dialogProps = undefined
  }
}
