const declensionByNum = (num: number, wordForms: string[]) => {
  num = Math.abs(num) % 100

  const balanceOfTen = num % 10

  const fromTwoForms = () => {
    if (num > 10 && num < 20) {
      return wordForms[1]
    }

    return balanceOfTen === 1 ? wordForms[0] : wordForms[1]
  }

  const fromThreeForms = () => {
    if (num > 10 && num < 20) {
      return wordForms[2]
    }

    if (balanceOfTen > 1 && balanceOfTen < 5) {
      return wordForms[1]
    }

    if (balanceOfTen === 1) {
      return wordForms[0]
    }

    return wordForms[2]
  }

  switch (wordForms.length) {
    case 2:
      return fromTwoForms()
    default:
      return fromThreeForms()
  }
}

const pluralize = (
  value: number,
  arr: string[],
  options: { withValue: boolean, locale?: boolean } = { withValue: true }
) => {
  if (options.withValue && options.locale) {
    return `${value.toLocaleString('ru')} ${declensionByNum(value, arr)}`
  }

  if (options.withValue) {
    return `${value} ${declensionByNum(value, arr)}`
  }

  return `${declensionByNum(value, arr)}`
}

export { pluralize }
