import { masterClient } from '~/core/utils/clients/masterClient'
import TokenManager from '~/core/utils/tokenManagers/tokenManager'

export const profileTokenManager = new TokenManager(async () => {
  const res = await masterClient.post<{ profile_token: string }>('/tokens/medtochka_profile/profile_token/', {}, {
    'axios-retry': {
      retries: 0
    }
  })

  return res.data.profile_token
})
