
import FullscreenSelect from '~/core/components/FullscreenSelect/FullscreenSelect.vue'
import { useTownSearch } from '~/core/composables'
import { TownSelectItemDto } from '~/core/components/TownSelect/api'
import { defineComponent, PropType, ref, watch } from '~/bridge'
import { Validatable } from '~/types'

export default defineComponent({
  name: 'FullscreenTownSelect',
  components: { FullscreenSelect },
  props: {
    value: { type: Object as PropType<TownSelectItemDto | null>, default: null },
    rules: { type: Array, default: () => [] },
    isActive: { type: Boolean, default: false },
    hideOnSelect: { type: Boolean, default: true },
    hideActivator: { type: Boolean, default: false }
  },
  emits: ['input', 'update:isActive'],
  setup(props, { emit, expose }) {
    const lazyValue = ref<TownSelectItemDto | null>(null)
    const searchQuery = ref('')
    const select = ref<Validatable | null>(null)
    const lazyIsActive = ref(false)

    const { towns, loading, forceSearch } = useTownSearch({
      searchQuery
    })

    watch(() => props.value, (val) => {
      lazyValue.value = val
    }, { immediate: true, deep: true })

    watch(lazyValue, (val) => {
      if (val === props.value) {
        return
      }

      emit('input', val)
    }, { deep: true })

    watch(() => props.isActive, (val) => {
      lazyIsActive.value = val
    }, { immediate: true })

    watch(lazyIsActive, (val) => {
      emit('update:isActive', val)

      if (val) {
        if (!searchQuery.value) {
          forceSearch('')
        }

        searchQuery.value = ''
      }
    })

    expose({
      validate: (...args) => select.value?.validate(...args),
      resetValidation: () => select.value?.resetValidation(),
      reset: () => select.value?.reset()
    } as Validatable)

    return {
      lazyIsActive,
      select,
      lazyValue,
      searchQuery,
      towns,
      loading
    }
  }
})
