
import { DIALOG_MESSAGE as CORE_DIALOG, PROFILE_GETTERS, PROFILE_MUTATIONS } from '~/core/constants'
import townApi, { TownSelectItemDto } from '~/core/components/TownSelect/api'
import FullscreenTownSelect from '~/core/components/FullscreenTownSelect/FullscreenTownSelect.vue'
import { computed, defineComponent, ref, useNuxtApp } from '~/bridge'

export default defineComponent({
  name: 'TownSelect',
  components: {
    FullscreenTownSelect
  },
  setup() {
    const { $store, $dialog } = useNuxtApp()
    const isPending = ref(false)

    const isActive = computed({
      get() {
        return $store.state['town-modal'].isActive
      },
      set: (val: boolean) => {
        if ($store.state['town-modal'].isActive === val) {
          return
        }

        return val ? $store.commit('town-modal/open') : $store.commit('town-modal/close')
      }
    })

    const town = computed({
      get() {
        return $store.getters[PROFILE_GETTERS.COMPOSED_TOWN_OBJECT]
      },
      async set(val: TownSelectItemDto) {
        if (isPending.value) {
          return
        }

        try {
          isPending.value = true
          await townApi.patch({ town: val.id, townConfirmed: true })
          $store.commit(PROFILE_MUTATIONS.SET_TOWN, { town: val.name, townKey: val.key, townId: val.id })
          $store.commit(PROFILE_MUTATIONS.SET_TOWN_CONFIRMED, true)

          if ($store.state['town-modal'].afterTownSelectHandler) {
            await $store.state['town-modal'].afterTownSelectHandler(val.key)

            return
          }

          isActive.value = false
          await $store.dispatch('main-snackbar/handleOpen', {
            text: `Ваш город ${val.name}`,
            cancelText: 'изменить',
            cancelHandler: () => {
              isActive.value = true
            }
          })
        } catch (e) {
          $dialog.open({ ...CORE_DIALOG.GLOBAL_ERROR })
        } finally {
          isPending.value = false
        }
      }
    })

    return {
      isActive,
      town
    }
  }
})
