
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'AppToolbar',
  props: {
    title: { type: String, default: '' },
    backTo: { type: Boolean, default: false },
    closeTo: { type: Boolean, default: false },
    borderless: { type: Boolean },
    fixed: { type: Boolean, default: false },
    appendIcon: { type: String, default: '' },
    dark: { type: Boolean, default: false }
  },
  emits: ['click:back', 'click:icon', 'click:close'],
  setup(_, { emit }) {
    function emitClickIcon() {
      emit('click:icon')
    }

    return {
      emitClickIcon
    }
  }
})
