// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject
import { Context, Plugin } from '@nuxt/types'
import { composeSentryContext } from '~/core/functions'

type TPlugin = (error: unknown, tags?: { component?: string, method?: string, message?: string }) => string

declare module 'vue/types/vue' {
  // this.$featureFlag inside Vue components
  interface Vue {
    $captureException: TPlugin
  }
}

declare module '@nuxt/types' {
  // nuxtContext.$featureFlag
  interface Context {
    $captureException: TPlugin
  }
}

declare module 'vuex/types/index' {
  // this.$featureFlag inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $captureException: TPlugin
  }
}

const captureException: Plugin = ({ $sentry }: Context, inject) => {
  const callback: TPlugin = (error, tags) => {
    return $sentry.captureException(error, composeSentryContext({
      error,
      tags: tags ?? { }
    }))
  }

  inject('captureException', callback)
}

export default captureException
