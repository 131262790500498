export * from './appointment'
export * from './profile'
export * from './medcard/index'
export * from './medcard/questionnaire'
export * from './chat'
export * from './rate/discuss'
export * from './rate/index'
export * from './rate/publish'

export const CORE_GETTERS = {
  GET_FAMILY_PROFILE: 'GET_FAMILY_PROFILE',
  GET_SPECIALITY_BY_ID: 'GET_SPECIALITY_BY_ID'
}

export const CORE_MUTATIONS = {
  SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
  SET_IS_LAYOUT_ERROR: 'SET_IS_LAYOUT_ERROR',
  INC_OVERLAYS_COUNT: 'INC_OVERLAYS_COUNT',
  DEC_OVERLAYS_COUNT: 'DEC_OVERLAYS_COUNT',
  CLEAR_OVERLAYS_COUNT: 'CLEAR_OVERLAYS_COUNT',
  SET_SPECIALITIES: 'SET_SPECIALITIES',
  SET_IS_SMART_APP_BANNER_ACTIVE: 'SET_IS_SMART_APP_BANNER_ACTIVE',
  SET_FAMILY: 'SET_FAMILY'
}

export const CORE_ACTIONS = {
  FETCH_SPECIALITIES: 'FETCH_SPECIALITIES',
  FETCH_FAMILY: 'FETCH_FAMILY'
}
