import { upperFirst } from 'lodash-es'
import { formatDate } from '~/core/utils/formatDate'

type Weekday = 'Пн' | 'Вт' | 'Ср' | 'Чт' | 'Пт' | 'Сб' | 'Вс'

/**
 * Превращает дату в формат дня недели.
 *
 * @param date Входная дата.
 * @return Возвращает дату в формате дня недели (Пн, Вт, Ср, Чт, Пт, Сб, Вс)
 */
export const formatWeekday = (date: string | Date): Weekday => {
  const weekDay = formatDate(date, 'EEEEEE')

  return upperFirst(weekDay) as Weekday
}
