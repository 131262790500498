
import { useVModel } from '@vueuse/core'
import AppToolbar from '~/core/components/AppToolbar/AppToolbar.vue'
import { CORE_MUTATIONS } from '~/core/constants'
import AppSmartBanner from '~/core/components/AppSmartBanner/AppSmartBanner.vue'
import { defineComponent, onBeforeUnmount, useNuxtApp, watch } from '~/bridge'

export default defineComponent({
  name: 'BaseFullscreenDialog',
  components: { AppSmartBanner, AppToolbar },
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    backTo: { type: Boolean, default: false },
    hideCloseBtn: { type: Boolean, default: false },
    title: { type: String, default: '' },
    borderlessTitle: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    fixedToolbar: { type: Boolean, default: false },
    toolbarAppendIcon: { type: String, default: '' },
    dark: { type: Boolean, default: false },
    showSmartBanner: { type: Boolean, default: false },
    persistentScroll: { type: Boolean, default: false },
    preventFromClose: { type: Boolean, default: false }
  },
  emits: ['click:prepend-icon', 'click:append-icon', 'input'],
  setup(props, { emit }) {
    const { $store } = useNuxtApp()
    const isActive = useVModel(props)

    watch(isActive, (val, prev) => {
      if (val) {
        $store.commit(CORE_MUTATIONS.INC_OVERLAYS_COUNT)
      } else if (prev && !val) {
        $store.commit(CORE_MUTATIONS.DEC_OVERLAYS_COUNT)
      }
    }, { immediate: true })

    onBeforeUnmount(() => {
      if (isActive.value) {
        $store.commit(CORE_MUTATIONS.CLEAR_OVERLAYS_COUNT)
      }
    })

    function handleClickClose() {
      emit('click:prepend-icon')

      if (props.preventFromClose) {
        return
      }

      emitInput(false)
    }

    function emitClickAppendIcon() {
      emit('click:append-icon')
    }

    function emitInput(ev: boolean) {
      isActive.value = ev
    }

    return {
      isActive,
      handleClickClose,
      emitClickAppendIcon,
      emitInput
    }
  }
})
