// Модуль для помощи в переходе на nuxt-bridge/nuxt 3 + vue 3
// Здесь переписаны/добавлены функции которые будут присутствовать в nuxt-bridge
// Также здесь все экспорты vue 3 функций
// Сделано для того чтобы при переходе на nuxt 3 + vue 3 не нужно было менять названия методов по всему проекту а также удалять/менять всего один импорт
import {
  onBeforeMount,
  reactive,
  ref,
  Ref,
  useContext,
  useMeta,
  useRoute as compositionUseRoute, watch
} from '@nuxtjs/composition-api'
import type { Route } from 'vue-router'

export { createApp, createRef, defineAsyncComponent, defineComponent, defineNuxtMiddleware, defineNuxtPlugin, useRouter } from '@nuxtjs/composition-api'
export type { AsyncComponent, Component, ComponentComputedOptions, ComponentCustomOptions, ComponentCustomProperties, ComponentCustomProps, ComponentInstance, ComponentMethodOptions, ComponentOptions, ComponentPropsOptions, ComponentPublicInstance, ComputedGetter, ComputedOptions, ComputedRef, ComputedSetter, CreateComponentPublicInstance, CreateElement, CustomRefFactory, Data, DebuggerEvent, DebuggerEventExtraInfo, DebuggerOptions, DeepReadonly, Directive, DirectiveBinding, DirectiveFunction, DirectiveHook, DirectiveModifiers, DirectiveOptions, EffectScope, ExtractDefaultPropTypes, ExtractPropTypes, FunctionDirective, FunctionalComponentOptions, InferDefault, InferDefaults, InjectionKey, NotUndefined, ObjectDirective, PluginFunction, PluginObject, PropOptions, PropType, PropsWithDefaults, ReactiveFlags, Ref, RefUnwrapBailTypes, RenderContext, SetupContext, SetupFunction, ShallowReactive, ShallowRef, ShallowUnwrapRef, ToRef, ToRefs, TrackOpTypes, TriggerOpTypes, UnwrapNestedRefs, UnwrapRef, VNode, VNodeChildren, VNodeChildrenArrayContents, VNodeComponentOptions, VNodeData, VNodeDirective, VueConstructor, WatchCallback, WatchEffect, WatchHandler, WatchOptions, WatchOptionsBase, WatchOptionsWithHandler, WatchSource, WatchStopHandle, WritableComputedOptions, WritableComputedRef } from 'vue'
export { computed, customRef, del, effectScope, getCurrentInstance, getCurrentScope, h, inject, isProxy, isReactive, isReadonly, isRef, isShallow, markRaw, nextTick, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate, onDeactivated, onErrorCaptured, onMounted, onRenderTracked, onRenderTriggered, onScopeDispose, onServerPrefetch, onUnmounted, onUpdated, provide, proxyRefs, reactive, readonly, ref, set, shallowReactive, shallowReadonly, shallowRef, toRaw, toRef, toRefs, triggerRef, unref, useAttrs, useCssModule as useCSSModule, useCssModule, useCssVars, useSlots, version, watch, watchEffect, watchPostEffect, watchSyncEffect } from 'vue'

export const useNuxtApp = () => {
  const ctx = useContext()

  return {
    ...ctx,
    store: undefined,
    $store: ctx.store
  } as Omit<typeof ctx, 'store'> & { $store: typeof ctx.store }
}

export const useHead = useMeta

export const useRoute = () => {
  const refRoute = compositionUseRoute()

  const route = reactive({ ...refRoute.value })

  watch(refRoute, (val) => {
    Object.entries(val).forEach(([key, value]) => {
      route[key as keyof typeof route] = value
    })
  })

  return route as Route
}

export function useLazyAsyncData<T>(
  handler: (nuxtApp: ReturnType<typeof useNuxtApp>) => Promise<T>
): {
  data: Ref<T | null>
  pending: Ref<boolean>
  refresh: () => Promise<void>
} {
  const data: Ref<T | null> = ref(null)
  const pending = ref(false)
  onBeforeMount(async () => {
    pending.value = true
    await refresh()
    pending.value = false
  })

  const refresh = async () => {
    data.value = await handler(useNuxtApp())
  }

  return {
    data,
    pending,
    refresh
  }
}

