export const MEDCARD_MUTATIONS = {
  SET_MEDCARD_STATUS: 'medcard/SET_MEDCARD_STATUS',
  SET_MEDCARD_MASTER_TOKEN: 'medcard/SET_MEDCARD_MASTER_TOKEN',
  SET_MEDCARD_IS_FULL: 'medcard/SET_MEDCARD_IS_FULL',
  SET_FAMILY_PROFILE_UUID: 'medcard/SET_FAMILY_PROFILE_UUID'
}

export const MEDCARD_GETTERS = {
  IS_MEDCARD_TOKEN_VALID: 'medcard/IS_MEDCARD_TOKEN_VALID'
}

export const MEDCARD_ACTIONS = {
  GET_MASTER_TOKEN: 'medcard/GET_MASTER_TOKEN',
  CHECK_MEDCARD: 'medcard/CHECK_MEDCARD'
}
