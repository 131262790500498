import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { Igrecaptcha } from '~/plugins/recaptcha/types'

declare module 'vue/types/vue' {
  interface Vue {
    $recaptcha: ReCaptcha
  }
}

declare module '@nuxt/types' {
  // nuxtContext.$recaptcha
  interface Context {
    $recaptcha: ReCaptcha
  }
}

declare module 'vuex/types/index' {
  // this.$recaptcha inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $recaptcha: ReCaptcha
  }
}

const API_URL = 'https://www.google.com/recaptcha/api.js'

class ReCaptcha {
  elements: {
    script?: HTMLScriptElement,
    style?: HTMLStyleElement
  }

  grecaptcha: Igrecaptcha | null
  ready: Promise<unknown> | boolean | null
  private siteKey: string

  constructor() {
    this.elements = {}
    this.grecaptcha = null
    this.ready = false
    this.siteKey = ''
  }

  destroy() {
    if (this.ready) {
      this.ready = false

      const { head } = document
      const { style } = this.elements

      const scripts = [...document.head.querySelectorAll('script')]
        .filter(script => script.src.includes('recaptcha'))

      if (scripts.length) {
        scripts.forEach(script => head.removeChild(script))
      }

      if (style && head.contains(style)) {
        head.removeChild(style)
      }

      const badge = document.querySelector('.grecaptcha-badge')

      if (badge) {
        badge.remove()
      }
    }
  }

  async execute(action: string) {
    try {
      await this.init()

      if ('grecaptcha' in window) {
        return this.grecaptcha!.execute(this.siteKey, { action })
      }
    } catch (error) {
      throw new Error(`ReCaptcha error: Failed to execute ${error}`)
    }
  }

  reset() {
    this.grecaptcha?.reset()
  }

  render(container: HTMLElement | string, params: Parameters<Igrecaptcha['render']>[1]) {
    return this.grecaptcha?.render(container, {
      sitekey: this.siteKey,
      ...params
    })
  }

  init(siteKey?: string) {
    this.siteKey = siteKey ?? this.siteKey

    if (!this.siteKey) {
      // eslint-disable-next-line no-console
      return console.error('ReCaptcha error: No site key provided')
    }

    if (this.ready) {
      return this.ready
    }

    this.elements = {
      script: document.createElement('script'),
      style: document.createElement('style')
    }

    const { script } = this.elements

    if (!script) {
      return
    }

    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    script.setAttribute('src', `${API_URL}?hl=ru-Ru`)

    this.ready = new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        this.grecaptcha = (window as any).grecaptcha
        this.grecaptcha?.ready(resolve)
      })

      script.addEventListener('error', () => {
        document.head.removeChild(script)
        reject(new Error('Index error: Failed to load script'))
        this.ready = null
      })

      document.head.appendChild(script)
    })

    return this.ready
  }
}

const recaptcha = (_: Context, inject: Inject) => {
  inject('recaptcha', new ReCaptcha())
}

export default recaptcha
