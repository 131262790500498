import { masterClient } from '~/core/utils/clients/masterClient'
import { Category } from '~/core/enums'

const RESOURCE = '/favourites'

export type FavouritePreviewDto = {
  avatar: string
  // Это id врача/клиники, а не сущности избранного
  id: number
  info: string
  name: string
  type: 'doctors' | 'lpu'
  urlPath: string
}

export const FavouritesApi = {
  get(type?: Category.Doctors | Category.Lpu) {
    return masterClient.get<FavouritePreviewDto[]>(`${RESOURCE}/`, {
      params: {
        type
      },
      camelize: true
    })
  },
  add(type: string, id: number) {
    return masterClient.post(`${RESOURCE}/${type}/${id}/`, { camelize: true })
  },
  delete(type: string, id: number) {
    return masterClient.delete(`${RESOURCE}/${type}/${id}/`, { camelize: true })
  }
}
