import { render, staticRenderFns } from "./BottomControls.vue?vue&type=template&id=bab7c76e&scoped=true&"
import script from "./BottomControls.vue?vue&type=script&lang=ts&"
export * from "./BottomControls.vue?vue&type=script&lang=ts&"
import style0 from "./BottomControls.vue?vue&type=style&index=0&id=bab7c76e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab7c76e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseContainer: require('/builds/private/medtochka-frontend/core/components/Base/BaseContainer.vue').default})
