export const SNACKBAR_MESSAGE = {
  CHAT_CLOSED: {
    text: 'Вы закрыли чат'
  },
  FILE_DELETED: {
    text: 'Файл удалён'
  },
  FILE_DELETED_ERROR: {
    text: 'Не получилось удалить файл'
  },
  IMAGE_SAVED: {
    text: 'Сохранено'
  },
  MESSAGE_TEXT_SAVED: {
    text: 'Текст сохранён',
    cancelable: true,
    timeout: 1700
  },
  PHOTOS_LIMIT: {
    text: 'Не больше 5 фото'
  },
  PROBLEM_RESOLVED: {
    text: 'Вы отметили, что клиника решила проблему'
  },
  RATE_REMIND_LATER: {
    text: 'Принято. Напомним о документе',
    dontCloseOnRouteChange: true
  },
  RATE_REMOVED_FROM_PUBLICATION: {
    text: 'Ответ отправлен',
    dontCloseOnRouteChange: true
  },
  RATE_SEND_ERROR: {
    text: 'Произошёл какой-то сбой. Обновите страницу и попробуйте отправить отзыв ещё раз'
  }
}
