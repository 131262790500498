import { useRoute, useRouter } from '~/bridge'

export const useGoBack = () => {
  const $route = useRoute()
  const $router = useRouter()

  return () => {
    if ($route.params.from) {
      $router.push($route.params.from)
    } else {
      $router.back()
    }
  }
}
