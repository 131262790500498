import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { isBoolean } from 'lodash-es'
import { removeNamespace } from '~/core/functions'
import { MEDCARD_ACTIONS, MEDCARD_GETTERS, MEDCARD_MUTATIONS } from '~/core/constants'
import { HttpStatusCode } from '~/core/enums'
import { FAMILY_PROFILE_UUID_STORAGE_KEY } from '~/core/constants/sessionStorage'
import { MedcardStatus } from '~/features/Profile/enums'
import { MedcardApi } from '~/features/Medcard/api/medcard.api'

interface IState {
  status: MedcardStatus
  masterToken: string
  /**
   * null значит что запрос проверки упал с ошибкой, т.е. потенциально невалидный токен (нет доступа к медкарте).
   * */
  isMedcardFull: boolean | null
  familyProfileUuid: string | null
}

const initialState = (): IState => ({
  status: MedcardStatus.Protected as MedcardStatus,
  masterToken: '',
  isMedcardFull: null,
  familyProfileUuid: sessionStorage.getItem(FAMILY_PROFILE_UUID_STORAGE_KEY)
})

export const state = initialState
export type TRootState = ReturnType<typeof state>

const ACTIONS_TYPES = removeNamespace('medcard/', MEDCARD_ACTIONS)

const MUTATIONS_TYPES = removeNamespace('medcard/', MEDCARD_MUTATIONS)

const GETTERS_TYPES = removeNamespace('medcard/', MEDCARD_GETTERS)

export const getters: GetterTree<TRootState, TRootState> = {
  [GETTERS_TYPES.IS_MEDCARD_TOKEN_VALID](state) {
    return isBoolean(state.isMedcardFull)
  }
}

export const mutations: MutationTree<TRootState> = {
  [MUTATIONS_TYPES.SET_MEDCARD_STATUS](state, payload: MedcardStatus) {
    state.status = payload
  },
  [MUTATIONS_TYPES.SET_MEDCARD_MASTER_TOKEN](state, payload: string) {
    state.masterToken = payload
  },
  [MUTATIONS_TYPES.SET_MEDCARD_IS_FULL](state, payload: boolean | null) {
    state.isMedcardFull = payload
  },
  [MUTATIONS_TYPES.SET_FAMILY_PROFILE_UUID](state, payload: string) {
    state.familyProfileUuid = payload
    sessionStorage.setItem(FAMILY_PROFILE_UUID_STORAGE_KEY, payload)
  }
}

export const actions: ActionTree<TRootState, TRootState> = {
  async [ACTIONS_TYPES.GET_MASTER_TOKEN]({ commit }) {
    const { data } = await MedcardApi.getMedcardToken()
    commit(MUTATIONS_TYPES.SET_MEDCARD_MASTER_TOKEN, data.masterMedcardToken)
  },
  async [ACTIONS_TYPES.CHECK_MEDCARD]({ commit }) {
    try {
      const medcardRes = await MedcardApi.getMedcardIsFull()
      commit(MUTATIONS_TYPES.SET_MEDCARD_IS_FULL, medcardRes.data)
    } catch (e) {
      commit(MUTATIONS_TYPES.SET_MEDCARD_IS_FULL, null)

      if (e.response?.status !== HttpStatusCode.Forbidden) {
        this.$captureException(e, {
          component: 'Vuex',
          method: 'fetchMedcardIsFull'
        })
      }
    }
  }
}
