
import { computed, defineComponent } from '~/bridge'

export default defineComponent({
  name: 'ExpansionPanel',
  props: {
    background: { type: String, default: '' },
    flat: { type: Boolean },
    isActive: { type: Boolean }
  },
  setup(props) {
    const isOpen = computed(() => !props.isActive || 0)

    return {
      isOpen
    }
  }
})
