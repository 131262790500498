import { infoClient } from '~/core/utils/clients/infoClient'
import { Schedule } from '~/core/types'

const RESOURCE = '/appointments'

type TransferScheduleDto = {
  schedule: Schedule
}

export default {
  get(id: number): Promise<{ data: TransferScheduleDto }> {
    return infoClient.get(`${RESOURCE}/${id}/transfer/schedule/`, { camelize: false })
  },
  transfer({ id, ...rest }: { id: number, dtStart: string, duration: number })
    : Promise<void> {
    return infoClient.post(
      `${RESOURCE}/${id}/transfer/`,
      { ...rest },
      {
        // Здесь не нужен retry
        'axios-retry': {
          retries: 0
        },
        camelize: true
      }
    )
  }
}
