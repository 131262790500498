import { MTLinkApi } from '~/core/api'
import { MedcardSessionData } from '~/core/api/mtlink.api'

export const createMtlinkSession = async (payload: {
  uuid: string
  infoAnonymousToken: string
  masterAnonymousToken: string
} & MedcardSessionData) => {
  const { infoAnonymousToken, masterAnonymousToken, uuid, folderIds, femaleCalendarId } = payload

  await MTLinkApi.createInfoMtlinkSession({
    anonymousToken: infoAnonymousToken,
    uuid,
    folderIds,
    femaleCalendarId
  })

  await MTLinkApi.createMasterMtlinkSession({
    anonymousToken: masterAnonymousToken,
    uuid
  })
}
