import { FileType } from '~/core/enums'

export const printFile = (file: File) => {
  const blobURL = URL.createObjectURL(file)
  let iframe = document.querySelector('#pdf-print-iframe') as HTMLIFrameElement | null

  if (iframe) {
    iframe.remove()
  }

  iframe = document.createElement('iframe')
  document.body.appendChild(iframe)

  iframe.id = 'pdf-print-iframe'
  iframe.style.display = 'none'
  iframe.src = blobURL
  iframe.onload = () => {
    setTimeout(() => {
      URL.revokeObjectURL(blobURL)

      if (file.type !== FileType.Pdf) {
        iframe!.contentWindow!.document!.getElementsByTagName('img')[0].style.maxWidth = '100%'
      }

      iframe!.focus()
      iframe!.contentWindow!.print()
    }, 1)
  }
}
