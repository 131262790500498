export const SUPPORT = {
  change_text: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  document: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  change_rate: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  authorize: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  change_scores: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  confirm_documents: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  default: {
    email: 'proverka@medrocket.ru',
    phone: '78006003028'
  },
  publish: {
    phone: '78006003028'
  },
  discuss: {
    phone: '78006003028'
  },
  profile: {
    email: 'help@medrocket.ru',
    phone: '78007073129'
  },
  medcard: {
    email: 'help@medrocket.ru',
    phone: '78007073129'
  },
  appointments: {
    email: 'help@medrocket.ru',
    phone: '78007073129'
  }
} as const
