import { AxiosError } from 'axios'

const NETWORK_ERROR_VALUE = 'Network Error'

/**
 * Возвращает true при Network ошибках
 * */
export const retryCondition = (e: AxiosError) => [e.message, e.name].includes(NETWORK_ERROR_VALUE)

// https://developers.google.com/analytics/devguides/reporting/core/v3/errors#backoff
export const retryDelay = (count: number) => (200 * Math.pow(2, count)) + Math.floor(Math.random() * 200)
