export const DIALOG_MESSAGE = {
  INVALID_FORMAT_IMAGE_PDF: {
    title: 'Неподходящий формат',
    text: 'Подойдёт JPEG, PNG или PDF.'
  },
  MAX_SIZE_PHOTO_16MB: {
    title: 'Слишком большой файл',
    text: 'Загрузите файл до 16 МБ.'
  },
  MIN_IMAGE_SIZES: {
    title: 'Слишком маленькое фото',
    text: 'Загрузите фото от 300x300 пикселей.'
  },
  MULTIPLE_ERRORS: {
    title: 'Не все файлы загружены',
    text: `Проверьте размер и формат.\n
      Должно быть так:\n
      • до 16 МБ\n
      • от 300x300 пикселей\n
      • JPEG, PNG или PDF`
  },
  DELETE_FILE_DANGER: {
    title: 'Удалить файл?',
    closeText: 'Отменить',
    confirmText: 'Удалить',
    isDanger: true
  }
} as const
