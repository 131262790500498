type Declension = [string, string, string]
type Plural = [string, string]

export const secondsDeclension: Declension = ['секунду', 'секунды', 'секунд']
export const minutesDeclension: Declension = ['минуту', 'минуты', 'минут']
export const hoursDeclension: Declension = ['час', 'часа', 'часов']
export const dayDeclension: Declension = ['день', 'дня', 'дней']
export const weekDeclension: Declension = ['неделю', 'недели', 'недель']
export const monthDeclension: Declension = ['месяц', 'месяца', 'месяцев']
export const yearDeclension: Declension = ['год', 'года', 'лет']

export const numberDeclension: Declension = ['цифру', 'цифры', 'цифр']
export const humanDeclension: Declension = ['человек', 'человека', 'человек']
export const documentDeclension: Declension = ['документ', 'документа', 'документов']
export const folderDeclension: Declension = ['папка', 'папки', 'папок']
export const hitDeclension: Declension = ['просмотр', 'просмотра', 'просмотров']
export const appointmentDeclension: Declension = ['запись', 'записи', 'записей']
export const doctorDeclension: Declension = ['врач', 'врача', 'врачей']
export const serviceDeclension: Declension = ['услуга', 'услуги', 'услуг']
export const rateDeclension: Declension = ['отзыв', 'отзыва', 'отзывов']
export const lpuDeclension: Declension = ['клиника', 'клиники', 'клиник']
export const readerDeclension: Declension = ['читатель', 'читателя', 'читателей']
export const attemptsDeclension: Declension = ['попытка', 'попытки', 'попыток']

export const readPlural: Plural = ['прочел', 'прочли']
export const humanPlural: Plural = ['человек', 'человека']
export const yearPlural: Plural = ['года', 'лет']
export const symbolPlural: Plural = ['символа', 'символов']
export const relativePlural: Plural = ['близкий', 'близких']
