import { isFinite, isString } from 'lodash-es'

export const rulesGenerators = {
  lesserThan: (value: number, strict = true, message = 'Некорректное значение') => {
    return (v: number) => strict ? Number(v) < value || message : Number(v) <= value || message
  },
  greaterThan: (value: number, strict = true, message = 'Некорректное значение') => {
    return (v: number) => strict ? Number(v) > value || message : Number(v) >= value || message
  },
  required: (message = 'Обязательное поле', trim = true) => {
    return (v: string | number | null) => {
      const formattedValue = trim && isString(v) ? v.trim() : v

      return isFinite(formattedValue) || Boolean(formattedValue) || message
    }
  }
}
