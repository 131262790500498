import { Context } from '@nuxt/types'

export default function({ store }: Context) {
  if (!store.state['main-snackbar'].isActive) {
    return
  }

  if (store.state['main-snackbar'].dontCloseOnRouteChange) {
    store.commit('main-snackbar/allowClose')
  } else {
    store.commit('main-snackbar/close')
  }
}
