export const getScreenOrientation = (): 'portrait' | 'landscape' => {
  const orientation =
    (screen.orientation || {}).type ||
    (screen as any).mozOrientation ||
    (screen as any).msOrientation

  if (!orientation) {
    return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
  }

  return orientation.includes('portrait') ? 'portrait' : 'landscape'
}
