import { Context } from '@nuxt/types'
import { CORE_MUTATIONS } from '~/core/constants'
import { FeatureFlagsApi } from '~/core/api'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'

export default async function({ store, route, error, $captureException }: Context) {
  try {
    const { data: featureFlags } = await FeatureFlagsApi.get()
    const meta = route.meta?.[0] || {} // NOTE: Используется для ограничения доступа к роутам по флагу, передаваемому в meta

    store.commit(CORE_MUTATIONS.SET_FEATURE_FLAGS, featureFlags)

    if (!isFeatureEnabled(meta.featureFlag, featureFlags)) {
      error({ statusCode: 403, message: 'Forbidden' })
    }
  } catch (error) {
    $captureException(error)
  }
}
