export const PROFILE_GETTERS = {
  GET_FULL_NAME: 'profile/GET_FULL_NAME',
  COMPOSED_TOWN_OBJECT: 'profile/COMPOSED_TOWN_OBJECT',
  IS_PROFILE_FILLED: 'profile/IS_PROFILE_FILLED'
}

export const PROFILE_MUTATIONS = {
  SET_MEDCARD_ACTIVE_SESSIONS_COUNT: 'profile/SET_MEDCARD_ACTIVE_SESSIONS_COUNT',
  SET_UNREAD_NOTIFICATIONS_COUNT: 'profile/SET_UNREAD_NOTIFICATIONS_COUNT',
  SET_PHONE: 'profile/SET_PHONE',
  SET_CLUB_CARD: 'profile/SET_CLUB_CARD',
  SET_TOWN: 'profile/SET_TOWN',
  SET_GENDER: 'profile/SET_GENDER',
  SET_BIRTH: 'profile/SET_BIRTH',
  SET_TOWN_CONFIRMED: 'profile/SET_TOWN_CONFIRMED',
  SET_EMAIL: 'profile/SET_EMAIL',
  SET_FULL_NAME: 'profile/SET_FULL_NAME',
  SET_AGREEMENT_STATUS: 'profile/SET_AGREEMENT_STATUS',
  SET_PREVENT_SHOWING_AGREEMENT: 'profile/SET_PREVENT_SHOWING_AGREEMENT',
  SET_AFTER_AGREEMENT_CALLBACK: 'profile/SET_AFTER_AGREEMENT',
  ALLOW_RECEIVE_NEWS_EMAILS: 'profile/ALLOW_RECEIVE_NEWS_EMAILS',
  ALLOW_RECEIVE_MEDCARD_EMAILS: 'profile/ALLOW_RECEIVE_MEDCARD_EMAILS',
  SET_HAS_LOYALTY_CARDS: 'profile/SET_HAS_LOYALTY_CARDS',
  SET_INFORMATION_BLOCK: 'profile/SET_INFORMATION_BLOCK'
}

export const PROFILE_ACTIONS = {
  RULES_AGREE: 'profile/RULES_AGREE',
  FETCH_PROFILE: 'profile/FETCH_PROFILE',
  FETCH_INFORMATION_BLOCK: 'profile/FETCH_INFORMATION_BLOCK'
}
