export enum MessageType {
  Owner = 'owner',
  Companion = 'companion',
  System = 'system'
}

export enum MessageStatus {
  Uploading = 'UPLOADING',
  Failed = 'FAILED',
  Sent = 'SENT'
}
