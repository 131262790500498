import { render, staticRenderFns } from "./FIOForm.vue?vue&type=template&id=2abab388&"
import script from "./FIOForm.vue?vue&type=script&lang=ts&"
export * from "./FIOForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTextField: require('/builds/private/medtochka-frontend/core/components/Base/BaseTextField.vue').default})
