export * from './therapeutic'
export * from './gynecological'
export * from './dental'
export * from './surgical'

export enum QuestionGroup {
  Therapy = 'therapy',
  Gynecology = 'gynecology',
  Surgery = 'surgery',
  Dentistry = 'dentistry'
}

export enum DentalAndSurgicalCommonQuestionId {
  Implants = 'implants'
}
