import md5 from 'md5'
import { merge } from 'lodash-es'
import getCSRFToken from '~/core/functions/getCSRFToken'

/* eslint-disable no-restricted-syntax */
/**
 * @description
 *
 * https://github.com/fingerprintjs/fingerprintjs
 *
 * В функции внесены незначительные синтаксические коррективы, которые не влияют на работу в целом
 * */

const replaceNaN = (value, replacement) => (typeof value === 'number' && Number.isNaN(value) ? replacement : value)
const countTruthy = values => values.reduce((sum, value) => sum + (value ? 1 : 0), 0)
const getColorDepth = () => window.screen.colorDepth ?? null
const doesMatch = (search, value) => (matchMedia(`(${search}: ${value})`).matches)
const getCpuClass = () => navigator.cpuClass || ''
const getDeviceMemory = () => replaceNaN(parseFloat(navigator.deviceMemory)) ?? null
const getHardwareConcurrency = () => replaceNaN(window.parseInt(`${navigator.hardwareConcurrency}`)) ?? null
const getOpenDatabase = () => !!window.openDatabase
const getOsCpu = () => navigator.oscpu || ''
const getVendor = () => navigator.vendor || ''

const BASE_INTERFACE = {
  hdr: null,
  os_cpu: null,
  vendor: null,
  plugins: [],
  platform: null,
  timezone: null,
  cpu_class: null,
  languages: [],
  screen_frame: [],
  color_depth: null,
  color_gamut: null,
  color_forced: null,
  device_memory: null,
  local_storage: null,
  open_database: null,
  touch_support: null,
  contrast: null,
  motion_reduced: null,
  vendor_flavors: [],
  cookie_enabled: null,
  color_inverted: null,
  session_storage: null,
  monochrome_depth: null,
  screen_resolution: [],
  hardware_concurrency: null,
  service_worker: {
    controller: null,
    onmessage: null,
    onmessageerror: null
  }
}

const getTimezoneOffset = () => {
  const currentYear = new Date().getFullYear()

  return Math.max(
    parseFloat(`${new Date(currentYear, 0, 1).getTimezoneOffset()}`),
    parseFloat(`${new Date(currentYear, 6, 1).getTimezoneOffset()}`)
  )
}

/** Проверка что это хром 86 */
const isChromium86OrNewer = () => (countTruthy([
  !('MediaSettingsRange' in window),
  'RTCEncodedAudioFrame' in window,
  `${window.Intl}` === '[object Intl]',
  `${window.Reflect}` === '[object Reflect]'
]) >= 3)

/** Проверка что, клиент использует хром более старшей версии */
const isChromium = () => (countTruthy([
  'webkitPersistentStorage' in navigator,
  'webkitTemporaryStorage' in navigator,
  navigator.vendor.indexOf('Google') === 0,
  'webkitResolveLocalFileSystemURL' in window,
  'BatteryManager' in window,
  'webkitMediaStream' in window,
  'webkitSpeechGrammar' in window
]) >= 5)

const isIPad = () => {
  // Safari on iPadOS (both mobile and desktop modes): 8, 11, 12, 13, 14
  // Chrome on iPadOS (both mobile and desktop modes): 11, 12, 13, 14
  // Safari on iOS (both mobile and desktop modes): 9, 10, 11, 12, 13, 14
  // Chrome on iOS (both mobile and desktop modes): 9, 10, 11, 12, 13, 14

  // Before iOS 13. Safari tampers the value in "request desktop site" mode since iOS 13.
  if (navigator.platform === 'iPad') {
    return true
  }

  const screenRatio = window.screen.width / window.screen.height

  return (
    countTruthy([
      'MediaSource' in window, // Since iOS 13
      !!Element.prototype.webkitRequestFullscreen, // Since iOS 12
      // iPhone 4S that runs iOS 9 matches this. But it won't match the criteria above, so it won't be detected as iPad.
      screenRatio > 0.65 && screenRatio < 1.53
    ]) >= 2
  )
}

const isWebKit = () => (countTruthy([
  'ApplePayError' in window,
  'CSSPrimitiveValue' in window,
  'Counter' in window,
  navigator.vendor.indexOf('Apple') === 0,
  'getStorageUpdates' in navigator,
  'WebKitMediaKeys' in window
]) >= 4)

const isDesktopSafari = () => (countTruthy([
  'safari' in window, // Always false in Karma and BrowserStack Automate
  !('DeviceMotionEvent' in window),
  !('ongestureend' in window),
  !('standalone' in navigator)
]) >= 3)

const getFullscreenElement = () => (
  document.fullscreenElement ||
  document.msFullscreenElement ||
  document.mozFullScreenElement ||
  document.webkitFullscreenElement ||
  null
)

const exitFullscreen = () => ((document.exitFullscreen ||
  document.msExitFullscreen ||
  document.mozCancelFullScreen ||
  document.webkitExitFullscreen).call(document))

const getColorGamut = () => {
  for (const gamut of ['rec2020', 'p3', 'srgb']) {
    if (matchMedia(`(color-gamut: ${gamut})`).matches) {
      return gamut
    }
  }

  return null
}

const getContrastPreference = () => {
  if (doesMatch('prefers-contrast', 'no-preference')) {
    return null
  }

  if (
    doesMatch('prefers-contrast', 'high') ||
    doesMatch('prefers-contrast', 'more')
  ) {
    return 1
  }

  if (
    doesMatch('prefers-contrast', 'low') ||
    doesMatch('prefers-contrast', 'less')
  ) {
    return -1
  }

  if (doesMatch('prefers-contrast', 'forced')) {
    return 10
  }

  return null
}

const areCookiesEnabled = () => {
  try {
    document.cookie = 'cookieTest=1; SameSite=Strict;'

    const result = document.cookie.includes('cookieTest=')

    document.cookie = 'cookieTest=1; SameSite=Strict; expires=Thu, 01-Jan-1970 00:00:01 GMT'

    return result
  } catch (error) {
    return false
  }
}

const areColorsForced = () => {
  if (doesMatch('forced-colors', 'active')) {
    return true
  }

  if (doesMatch('forced-colors', 'none')) {
    return false
  }

  return null
}

const isHDR = () => {
  if (doesMatch('dynamic-range', 'high')) {
    return true
  }

  if (doesMatch('dynamic-range', 'standard')) {
    return false
  }

  return null
}

const areColorsInverted = () => {
  if (doesMatch('inverted-colors', 'inverted')) {
    return true
  }

  if (doesMatch('inverted-colors', 'none')) {
    return false
  }

  return null
}

const getLanguages = () => {
  const result = []
  const language = navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage

  if (language) {
    result.push([language])
  }

  if (Array.isArray(navigator.languages)) {
    if (!(isChromium() && isChromium86OrNewer())) {
      result.push(navigator.languages)
    }
  } else if (typeof navigator.languages === 'string') {
    const { languages } = navigator

    if (languages) {
      result.push(languages.split(','))
    }
  }

  return result
}

const getLocalStorage = () => {
  try {
    return !!window.localStorage
  } catch (error) {
    return true
  }
}

const getMonochromeDepth = () => {
  if (!matchMedia('(min-monochrome: 0)').matches) {
    return null
  }

  for (let i = 0; i <= 100; ++i) {
    if (matchMedia(`(max-monochrome: ${i})`).matches) {
      return i
    }
  }

  return null
}

const getPlatform = () => {
  if (navigator.platform === 'MacIntel') {
    if (isWebKit() && !isDesktopSafari()) {
      return isIPad() ? 'iPad' : 'iPhone'
    }
  }

  return navigator.platform || ''
}

const getPlugins = () => {
  const rawPlugins = navigator.plugins

  if (!rawPlugins) {
    return null
  }

  const plugins = []

  for (let i = 0; i < rawPlugins.length; ++i) {
    const plugin = rawPlugins[i]

    if (!plugin) {
      continue
    }

    const mimeTypes = []

    for (let j = 0; j < plugin.length; ++j) {
      const mimeType = plugin[j]

      mimeTypes.push({
        type: mimeType.type,
        suffixes: mimeType.suffixes
      })
    }

    plugins.push({
      name: plugin.name,
      description: plugin.description,
      mimeTypes
    })
  }

  return plugins
}

const isMotionReduced = () => {
  if (doesMatch('prefers-reduced-motion', 'reduce')) {
    return true
  }

  if (doesMatch('prefers-reduced-motion', 'no-preference')) {
    return false
  }

  return null
}

const getScreenResolution = () => {
  const parseDimension = value => replaceNaN(window.parseInt(value), null)
  const dimensions = [parseDimension(window.screen.width), parseDimension(window.screen.height)]

  return dimensions.sort().reverse()
}

const getSessionStorage = () => {
  try {
    return !!window.sessionStorage
  } catch (error) {
    return true
  }
}

const getTimezone = () => {
  const DateTimeFormat = window.Intl?.DateTimeFormat

  if (DateTimeFormat) {
    const timezone = new DateTimeFormat().resolvedOptions().timeZone

    if (timezone) {
      return timezone
    }
  }

  const offset = -getTimezoneOffset()

  return `UTC${offset >= 0 ? '+' : ''}${Math.abs(offset)}`
}

const getTouchSupport = () => {
  let maxTouchPoints = 0
  let touchEvent

  if (navigator.maxTouchPoints !== undefined) {
    maxTouchPoints = window.parseInt(navigator.maxTouchPoints)
  } else if (navigator.msMaxTouchPoints !== undefined) {
    maxTouchPoints = navigator.msMaxTouchPoints
  }

  try {
    document.createEvent('TouchEvent')
    touchEvent = true
  } catch {
    touchEvent = false
  }

  const touchStart = 'ontouchstart' in window

  return {
    maxTouchPoints: maxTouchPoints ?? null,
    touchEvent: touchEvent ?? null,
    touchStart: touchStart ?? null
  }
}

const getVendorFlavors = () => {
  const flavors = []

  for (const key of [
    // Blink and some browsers on iOS
    'chrome',

    // Safari on macOS
    'safari',

    // Chrome on iOS (checked in 85 on 13 and 87 on 14)
    '__crWeb',
    '__gCrWeb',

    // Yandex Browser on iOS, macOS and Android (checked in 21.2 on iOS 14, macOS and Android)
    'yandex',

    // Yandex Browser on iOS (checked in 21.2 on 14)
    '__yb',
    '__ybro',

    // Firefox on iOS (checked in 32 on 14)
    '__firefox__',

    // Edge on iOS (checked in 46 on 14)
    '__edgeTrackingPreventionStatistics',
    'webkit',

    // Opera Touch on iOS (checked in 2.6 on 14)
    'oprt',

    // Samsung Internet on Android (checked in 11.1)
    'samsungAr',

    // UC Browser on Android (checked in 12.10 and 13.0)
    'ucweb',
    'UCShellJava',

    // Puffin on Android (checked in 9.0)
    'puffinDevice'

    // UC on iOS and Opera on Android have no specific global variables
    // Edge for Android isn't checked
  ]) {
    const value = window[key]

    if (value && typeof value === 'object') {
      flavors.push(key)
    }
  }

  return flavors.sort()
}

const getScreenFrame = () => {
  const screenFrameCheckInterval = 2500

  let screenFrameBackup
  let screenFrameSizeTimeoutId

  function isFrameSizeNull(frameSize) {
    for (let i = 0; i < 4; ++i) {
      if (frameSize[i]) {
        return false
      }
    }

    return true
  }

  function getCurrentScreenFrame() {
    const s = window.screen

    // Some browsers return screen resolution as strings, e.g. "1200", instead of a number, e.g. 1200.
    // I suspect it's done by certain plugins that randomize browser properties to prevent fingerprinting.
    //
    // Some browsers (IE, Edge ≤18) don't provide `screen.availLeft` and `screen.availTop`. The property values are
    // replaced with 0 in such cases to not lose the entropy from `screen.availWidth` and `screen.availHeight`.
    return [
      replaceNaN(
        parseFloat(s.availTop),
        null
      ),
      replaceNaN(
        parseFloat(s.width) - parseFloat(s.availWidth) - replaceNaN(parseFloat(s.availLeft), 0),
        null
      ),
      replaceNaN(
        parseFloat(s.height) - parseFloat(s.availHeight) - replaceNaN(parseFloat(s.availTop), 0),
        null
      ),
      replaceNaN(
        parseFloat(s.availLeft),
        null
      )
    ]
  }

  function watchScreenFrame() {
    if (screenFrameSizeTimeoutId !== undefined) {
      return
    }

    const checkScreenFrame = () => {
      const frameSize = getCurrentScreenFrame()

      if (isFrameSizeNull(frameSize)) {
        screenFrameSizeTimeoutId = (setTimeout)(checkScreenFrame, screenFrameCheckInterval)
      } else {
        screenFrameBackup = frameSize
        screenFrameSizeTimeoutId = undefined
      }
    }

    checkScreenFrame()
  }

  watchScreenFrame()

  return async () => {
    let frameSize = getCurrentScreenFrame()

    if (isFrameSizeNull(frameSize)) {
      if (screenFrameBackup) {
        return [...screenFrameBackup]
      }

      if (getFullscreenElement()) {
        // Some browsers set the screen frame to zero when programmatic fullscreen is on.
        // There is a chance of getting a non-zero frame after exiting the fullscreen.
        // See more on this at https://github.com/fingerprintjs/fingerprintjs/issues/568
        await exitFullscreen()
        frameSize = getCurrentScreenFrame()
      }
    }

    if (!isFrameSizeNull(frameSize)) {
      screenFrameBackup = frameSize
    }

    return frameSize
  }
}

const getCSRFToMD5Hash = () => md5(getCSRFToken()) ?? null

const mergeSentInterface = ({ resultData }) => merge(BASE_INTERFACE, resultData)

/**
 * Собирает все данные для запроса fingerprint
 * */
const getDataSet = async () => {
  return JSON.stringify({
    hdr: isHDR(),
    os_cpu: getOsCpu(),
    vendor: getVendor(),
    plugins: getPlugins(),
    platform: getPlatform(),
    timezone: getTimezone(),
    cpu_class: getCpuClass(),
    languages: getLanguages(),
    screen_frame: await getScreenFrame()(),
    color_depth: getColorDepth(),
    color_gamut: getColorGamut(),
    color_forced: areColorsForced(),
    device_memory: getDeviceMemory(),
    local_storage: getLocalStorage(),
    open_database: getOpenDatabase(),
    touch_support: getTouchSupport(),
    contrast: getContrastPreference(),
    motion_reduced: isMotionReduced(),
    vendor_flavors: getVendorFlavors(),
    cookie_enabled: areCookiesEnabled(),
    color_inverted: areColorsInverted(),
    session_storage: getSessionStorage(),
    monochrome_depth: getMonochromeDepth(),
    screen_resolution: getScreenResolution(),
    hardware_concurrency: getHardwareConcurrency(),
    service_worker: {
      controller: getCSRFToMD5Hash()
    }
  })
}

export {
  getDataSet,
  isHDR, isIPad, getOsCpu, isWebKit, doesMatch,
  isChromium, replaceNaN, getPlatform, countTruthy,
  getCpuClass, getLanguages, getColorGamut, getColorDepth,
  exitFullscreen, getLocalStorage, getDeviceMemory, areColorsForced,
  isDesktopSafari, getOpenDatabase, areCookiesEnabled, areColorsInverted,
  getMonochromeDepth, getTimezoneOffset, isChromium86OrNewer, getFullscreenElement,
  getContrastPreference, getHardwareConcurrency, getPlugins, isMotionReduced,
  getScreenResolution, getSessionStorage, getTimezone, getTouchSupport, getVendor,
  getVendorFlavors, getScreenFrame, getCSRFToMD5Hash, mergeSentInterface
}
