import { ByteSource, Counter, ModeOfOperation, utils } from 'aes-js'
import { base64ToHex, base64ToUint8Array } from '~/core/functions'

type Base64 = string
type InitialVector = (Counter | undefined) & ByteSource

/**
 * Расшифровывает зашифрованные бэкендом данные.
 *
 * @param method метод шифрования. По проекту используется два метода шифрования - CBC и CTR.
 * @param ciphertext зашифрованный текст.
 * @param key ключ шифрования.
 * @param iv вектор инициализации.
 * @return { string } расшифрованные данные.
 */
export const aesDecryptor = (method: 'ctr' | 'cbc', ciphertext: Base64, key: Base64, iv: Base64): string => {
  const Decryptor = ModeOfOperation[method]

  const aes = new Decryptor(base64ToUint8Array(key), base64ToUint8Array(iv) as InitialVector)
  const cryptoText = aes.decrypt(utils.hex.toBytes(base64ToHex(ciphertext)))

  return utils.utf8.fromBytes(cryptoText)
}
