
import Cookies from 'js-cookie'
import { addSeconds, differenceInSeconds } from 'date-fns'
import { EmailApi } from '~/core/api'
import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue'
import { ConfirmMailAction, CookieKey } from '~/core/enums'
import {
  DIALOG_MESSAGE as CORE_DIALOG,
  MEDCARD_ACTIONS,
  MEDCARD_MUTATIONS,
  PROFILE_MUTATIONS,
  TIMER_TIMEOUT
} from '~/core/constants'
import { useToggleable } from '~/core/composables'
import { useConfirmEmail } from '~/core/composables/useConfirmEmail'
import { SNACKBAR_MESSAGE as CORE_SNACKBAR } from '~/core/constants/snackbar'
import { computed, defineComponent, nextTick, ref, toRefs, useNuxtApp, useRoute, watch } from '~/bridge'
import { MailStatus, MedcardStatus } from '~/features/Profile/enums'
import ConfirmMailForm from './ConfirmMailForm.vue'

const COOKIE_TIMER_KEY = 'mail_confirmation_timer'

export default defineComponent({
  name: 'ConfirmMail',
  components: {
    BaseFullscreenDialog,
    ConfirmMailForm
  },
  props: {
    value: { type: Boolean, default: false },
    hideConfirmationSupport: { type: Boolean, default: false }
  },
  emits: ['email:confirmed', 'email:changed', 'click:close', 'input', 'email:limit', 'click:reset'],
  setup(props, { emit, expose }) {
    const refProps = toRefs(props)

    const currentAction = ref<ConfirmMailAction>(ConfirmMailAction.Addition)
    const changeEmailStep = ref<1 | 2>(1)
    const formKey = ref(Date.now())
    const mailLoading = ref(false)
    const resendTimeout = ref(TIMER_TIMEOUT)
    const isDailyLimitOccurred = ref(false)

    const { $store, $dialog } = useNuxtApp()
    const $route = useRoute()
    const { isActive, hide } = useToggleable(refProps.value, emit, 'input')
    const { storeEmail, masterMedcardToken } = useConfirmEmail()

    const title = computed(() => {
      switch (currentAction.value) {
        case ConfirmMailAction.Medcard:
          return 'Подтверждение почты'

        case ConfirmMailAction.Addition:
          return 'Укажите почту'

        default:
          return 'Электронная почта'
      }
    })

    const singleFormRequest = computed(() => {
      if (currentAction.value === ConfirmMailAction.Addition) {
        return confirmEmailAddition
      }

      return confirmEmailMedcard
    })

    const singleFormConfirm = computed(() => {
      if (currentAction.value === ConfirmMailAction.Addition) {
        return requestEmailAddition
      }

      return requestEmailMedcard
    })

    watch(resendTimeout, () => {
      if (
        isDailyLimitOccurred.value ||
        (currentAction.value === ConfirmMailAction.Change && changeEmailStep.value === 2)
      ) {
        Cookies.remove(COOKIE_TIMER_KEY)

        return
      }

      setCookieTimeout()
    })

    /** public */
    async function show(action: ConfirmMailAction) {
      if (isActive.value) {
        return
      }

      isDailyLimitOccurred.value = false
      const currentTimestamp = Date.now()
      resendTimeout.value = TIMER_TIMEOUT

      try {
        formKey.value = currentTimestamp
        isActive.value = true
        currentAction.value = action
        changeEmailStep.value = 1

        if (currentAction.value !== ConfirmMailAction.Addition) {
          mailLoading.value = true

          if (currentAction.value === ConfirmMailAction.Medcard) {
            if (Cookies.get(COOKIE_TIMER_KEY)?.split('/')[1] === ConfirmMailAction.Medcard) {
              setResendTimeout()
              await setupMedcardMasterToken()

              return
            }

            await requestEmailMedcard()
          }

          if (currentAction.value === ConfirmMailAction.Change) {
            if (Cookies.get(COOKIE_TIMER_KEY)?.split('/')[1] === ConfirmMailAction.Change) {
              setResendTimeout()
              await setupMedcardMasterToken()

              return
            }

            await requestEmailChange()
          }
        }
      } catch (e) {
        if (currentTimestamp !== formKey.value) {
          return
        }

        const errorCode = e?.response?.data?.email?.code

        if (errorCode === MailStatus.DailyLimit) {
          mailLoading.value = false
          isDailyLimitOccurred.value = true
          const isConfirmed = await $dialog.open({ ...CORE_DIALOG.CODE_COOLDOWN })

          if (isConfirmed) {
            emit('email:limit')
          }

          hide()
          Cookies.remove(COOKIE_TIMER_KEY)

          return
        }

        await $dialog.open({ ...CORE_DIALOG.GLOBAL_ERROR })

        hide()
        emit('click:close')
        Cookies.remove(COOKIE_TIMER_KEY)
      } finally {
        if (currentTimestamp === formKey.value) {
          mailLoading.value = false
        }
      }
    }

    function setResendTimeout() {
      const cookieTime = Cookies.get(COOKIE_TIMER_KEY)

      try {
        if (cookieTime && cookieTime.split('/')[1] === currentAction.value) {
          resendTimeout.value = Math.min(differenceInSeconds(new Date(cookieTime.split('/')[0]), new Date()), TIMER_TIMEOUT)
        }
      } catch {
        Cookies.remove(COOKIE_TIMER_KEY)
        resendTimeout.value = TIMER_TIMEOUT
      }
    }

    async function handleFirstStepComplete() {
      if (changeEmailStep.value === 1) {
        changeEmailStep.value++

        await nextTick()
        Cookies.remove(COOKIE_TIMER_KEY)
      }
    }

    async function setupMedcardMasterToken() {
      await $store.dispatch(MEDCARD_ACTIONS.GET_MASTER_TOKEN)
    }

    async function requestEmailChange() {
      await setupMedcardMasterToken()
      await EmailApi.requestChange({
        masterMedcardToken: masterMedcardToken.value
      })
    }

    async function confirmEmailChange({ secretCode }: { secretCode: string }) {
      await EmailApi.confirmChange({
        secretCode,
        masterMedcardToken: masterMedcardToken.value
      })
    }

    async function requestEmailAddition({ email }: { email: string }) {
      await setupMedcardMasterToken()

      await EmailApi.requestAddition({
        email,
        masterMedcardToken: masterMedcardToken.value
      })
    }

    function setEmail(email: string) {
      $store.commit(PROFILE_MUTATIONS.SET_EMAIL, email)
    }

    async function setMedcardState(token: string) {
      Cookies.set(CookieKey.MedcardToken, token, { expires: 14 })

      await $store.dispatch(MEDCARD_ACTIONS.CHECK_MEDCARD)
      $store.commit(MEDCARD_MUTATIONS.SET_MEDCARD_STATUS, MedcardStatus.Protected)
    }

    async function confirmEmailAddition({ secretCode, email }: { email: string, secretCode: string }) {
      const { data } = await EmailApi.confirmAddition({
        email,
        secretCode,
        masterMedcardToken: masterMedcardToken.value
      })

      setEmail(email)
      await setMedcardState(data.medcardToken)

      if ($route.params.achievementAction) {
        $store.commit('main-snackbar/open', CORE_SNACKBAR.MAIL_CONFIRMED_WITH_ACHIEVEMENT)
      }
    }

    async function requestEmailMedcard() {
      await setupMedcardMasterToken()

      await EmailApi.requestMedcard({
        masterMedcardToken: masterMedcardToken.value
      })
    }

    async function confirmEmailMedcard({ secretCode, email }: { secretCode: string, email: string }) {
      const { data } = await EmailApi.confirmMedcard({
        secretCode,
        masterMedcardToken: masterMedcardToken.value
      })

      setEmail(email)
      await setMedcardState(data.medcardToken)
    }

    function handleEmailConfirmed() {
      hide()
      Cookies.remove(COOKIE_TIMER_KEY)
      emit('email:confirmed')
    }

    function handleEmailChanged() {
      hide()
      emit('email:changed')
    }

    function handleClickClose() {
      emit('click:close')
    }

    function handleClickResetMedcard() {
      emit('click:reset')
      hide()
    }

    function setCookieTimeout() {
      if (currentAction.value === ConfirmMailAction.Medcard || currentAction.value === ConfirmMailAction.Change) {
        Cookies.set(
          COOKIE_TIMER_KEY,
          `${addSeconds(new Date(), resendTimeout.value).toISOString()}/${currentAction.value}`,
          { expires: addSeconds(new Date(), resendTimeout.value) }
        )
      } else if (Cookies.get(COOKIE_TIMER_KEY)) {
        Cookies.remove(COOKIE_TIMER_KEY)
      }
    }

    function getChangeSecondStepTitle(step: MailStatus) {
      return step === MailStatus.Sent ? 'Отправили код на новую почту' : ''
    }

    expose({
      show,
      confirmEmailMedcard, // Для теста
      requestEmailMedcard //
    })

    return {
      resendTimeout,
      ConfirmMailAction,
      currentAction,
      changeEmailStep,
      formKey,
      mailLoading,
      isActive,
      storeEmail,
      title,
      singleFormRequest,
      singleFormConfirm,
      isDailyLimitOccurred,
      handleFirstStepComplete,
      requestEmailChange,
      confirmEmailChange,
      requestEmailAddition,
      confirmEmailAddition,
      handleEmailConfirmed,
      handleEmailChanged,
      handleClickClose,
      handleClickResetMedcard,
      getChangeSecondStepTitle
    }
  }
})
