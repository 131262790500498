import { decode } from 'base64-arraybuffer'

type DecryptFile = (encryptedFile: ArrayBufferLike, key: string, iv: string) => Promise<ArrayBuffer>

const decryptFile: DecryptFile = async (encryptedFile, key, iv) => {
  const cryptoKey: CryptoKey = await crypto.subtle.importKey(
    'raw',
    decode(key),
    'AES-CTR',
    true,
    ['decrypt']
  )

  return await crypto.subtle.decrypt(
    {
      name: 'AES-CTR',
      counter: decode(iv),
      length: 64
    },
    cryptoKey,
    encryptedFile
  )
}

export default decryptFile
