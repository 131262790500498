import { computed, useNuxtApp } from '~/bridge'

export const useConfirmEmail = () => {
  const { $store } = useNuxtApp()

  const storeEmail = computed(() => $store.state.profile.email)
  const masterMedcardToken = computed(() => $store.state.medcard.masterToken)

  return {
    storeEmail,
    masterMedcardToken
  }
}
