
import { computed, defineComponent } from '~/bridge'

const __default__ = defineComponent({
  name: 'UploadPlaceholder',
  props: {
    icon: { type: String, default: 'ui-icon-plus' },
    disabled: { type: Boolean, default: false },
    width: { type: [String, Number], default: 80 },
    height: { type: [String, Number], default: 80 },
    fromMedcard: { type: Boolean, default: false }
  },
  emits: ['click'],
  setup(props, { emit }) {
    const borderStyle = computed(() => props.fromMedcard ? '1px solid #1A5DD0' : '1px dashed #1A5DD0')

    function handleClick() {
      if (props.disabled) {
        return
      }

      emit('click')
    }

    return {
      borderStyle,
      handleClick
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "b36c2948": (_vm.borderStyle)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__