import { Context } from '@nuxt/types'
import analyticsApi from '~/core/api/analytics.api'

const STORAGE_KEY = 'open_mt_sent'

export const sendOpenMtAnalytics = async (captureException: Context['$captureException']) => {
  if (sessionStorage.getItem(STORAGE_KEY)) {
    return
  }

  sessionStorage.setItem(STORAGE_KEY, 'true')

  try {
    await analyticsApi.sendOpenMt()
  } catch (e) {
    captureException(e, {
      method: 'sendOpenMtAnalytics'
    })
  }
}
