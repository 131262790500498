export enum GynecologicalQuestionId {
  FirstMenstruation = 'first_menstruation',
  LastMenstruation = 'last_menstruation',
  PregnanciesNumber = 'pregnancies_number',
  PregnanciesWithBirth = 'pregnancies_with_birth',
  Childbirth = 'childbirth',
  SexualPartner = 'sexual_partner',
  ContraceptionMethod = 'contraception_method'
}

export enum ChildbirthType {
  Natural = 'natural',
  Surgical = 'surgical'
}

export enum ContraceptionMethodValue {
  Hormonal = 'hormonal',
  Barrier = 'barrier',
  Other = 'other',
  NotUsing = 'not_using'
}

export enum SexualPartnerValue {
  Yes = 'yes',
  No = 'no',
  NoContacts = 'no_contacts'
}
