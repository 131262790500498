// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./src/Inter-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./src/Inter-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./src/Inter-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./src/Inter-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./src/Inter-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./src/Inter-Light.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Inter\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:\"Inter\";font-style:normal;font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:\"Inter\";font-style:normal;font-weight:300;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#1a5dd0",
	"secondary": "#ff4a37",
	"info": "#1a5dd0",
	"success": "#1eb37c",
	"error": "#ff4a37",
	"warning": "#ffa928",
	"anchor": "#1a5dd0",
	"ui-kit-brand": "#3981f1",
	"ui-kit-illustration": "#3981f1",
	"ui-kit-special": "#904fe2",
	"ui-kit-rating": "#ffa928",
	"ui-kit-button-text-snackbars": "#76cbff",
	"ui-kit-text": "#111",
	"ui-kit-text-secondary": "#424b58",
	"ui-kit-text-info": "#607a93",
	"ui-kit-text-success": "#00815a",
	"ui-kit-text-error": "#f52a14",
	"ui-kit-text-warning": "#df5000",
	"ui-kit-disabled": "#8da2ba",
	"ui-kit-icon-primary": "#111",
	"ui-kit-icon-secondary": "#607a93",
	"ui-kit-bg-primary": "#ecf1fb",
	"ui-kit-bg-secondary": "#f6f9ff",
	"ui-kit-bg-special": "#f0e4f5",
	"ui-kit-bg-success": "#e0f6ee",
	"ui-kit-bg-error": "#fce8e8",
	"ui-kit-bg-warning": "#fff2d9",
	"ui-kit-bg-club": "#ffeee5",
	"ui-kit-bg-gray-80": "#8da2ba",
	"ui-kit-bg-gray-60": "#c7d1df",
	"ui-kit-bg-gray-40": "#f3f6fa",
	"ui-kit-bg-gray-0": "#fff",
	"ui-kit-shadow": "rgba(14,39,77,.129)"
};
module.exports = ___CSS_LOADER_EXPORT___;
