import { values } from 'lodash-es'

/**
 * Проверяет является ли value хотя-бы одним из значений object
 *
 * @param value Значение для проверки.
 * @param object Объект для проверки.
 * @return Возвращает true, если value входит в список значений object, иначе false
 */
export const isValueOf = <T extends object>(value: unknown, object: T): value is T[keyof T] => {
  return values(object).includes(value as T[keyof T])
}
