
import { computed, defineComponent } from '~/bridge'

export default defineComponent({
  name: 'WithRubleIcon',
  props: {
    text: { type: String, required: true }
  },
  setup(props) {
    const innerHtml = computed(() => props.text.replaceAll('₽', '<span class="ruble-icon">₽</span>'))

    return {
      innerHtml
    }
  }
})
