const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['family'] = require('../middleware/family.ts')
middleware['family'] = middleware['family'].default || middleware['family']

middleware['featureFlags'] = require('../middleware/featureFlags.ts')
middleware['featureFlags'] = middleware['featureFlags'].default || middleware['featureFlags']

middleware['fingerprint'] = require('../middleware/fingerprint.ts')
middleware['fingerprint'] = middleware['fingerprint'].default || middleware['fingerprint']

middleware['resetState'] = require('../middleware/resetState.ts')
middleware['resetState'] = middleware['resetState'].default || middleware['resetState']

export default middleware
