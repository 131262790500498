import { masterClient } from '~/core/utils/clients/masterClient'
import { RateReviewAttachmentDto } from '~/features/Rates/api/chat.api.types'
import { CantModifyReasonType } from '~/features/Rates/enums'

const RESOURCE = '/rates/discussions'

export default {
  saveMessage(discussionID: number, text: string) {
    return masterClient.patch(
      `${RESOURCE}/${discussionID}/message/`,
      { text },
      { camelize: true }
    )
  },
  saveAttachment(discussionID: number, attachment: RateReviewAttachmentDto) {
    return masterClient.post<{ id: number }>(
      `${RESOURCE}/${discussionID}/attachments/`,
      {
        fileString: attachment.file,
        originalName: attachment.originalName
      },
      {
        // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true
      }
    )
  },
  deleteAttachment(discussionID: number, attachmentID: number) {
    return masterClient.delete(`${RESOURCE}/${discussionID}/attachments/${attachmentID}/`, { camelize: true })
  },
  sendMessage(discussionID: number) {
    return masterClient.patch(`${RESOURCE}/${discussionID}/`, {}, { camelize: true })
  },
  sendDiscussionAnswer(discussionID: number, answer: CantModifyReasonType) {
    return masterClient.post(`${RESOURCE}/${discussionID}/answer/`, { answer }, { camelize: true })
  }
}
