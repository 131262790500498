export enum MailStatus {
  Expired = 'expired',
  AttemptsOut = 'attempts_out',
  WrongCode = 'wrong_code',
  Success = 'success',
  Sent = 'sent',
  Mail = 'mail',
  Invalid = 'invalid',
  Unique = 'unique',
  SameAsBefore = 'same_as_before',
  DailyLimit = 'daily_limit',
  Confirmed = 'confirmed',
}
