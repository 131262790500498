import Cookies from 'js-cookie'
import { masterClient } from '~/core/utils/clients/masterClient'
import { CookieKey } from '~/core/enums'
import { medcardClient } from '~/core/utils/clients/medcardClient'
import { ServiceMessageType, TelemedMessageClientType } from '~/features/Appointments/enums/telemed'

const RESOURCE = '/consultation_chats'

type SendTelemedMessagePayloadDto = {
  clientMessageUuid: string
  encryptedText: string
  iv: string
}

export type ConsultationChatDetailsDto = {
  id: number
  jitsiMeetRoomName: string
  jitsiMeetRoomJwtToken: string
  videoCallDuration: number | null
  isConsultationFinished: boolean
}

export type TelemedMessageDto = {
  clientMessageUuid: string
  clientType: TelemedMessageClientType
  encryptedText: string
  iv: string
  serviceMessageType: ServiceMessageType
  serviceMessageText: string
  wasRead: boolean
  dtCreated: DateTimeISO
}

export default {
  getConsultationChatDetails(id: string) {
    return medcardClient.get<ConsultationChatDetailsDto>(`${RESOURCE}/${id}/`, { camelize: true })
  },
  getConsultationChatKey(chatId: string) {
    return masterClient.post<{ key: string }>(`${RESOURCE}/${chatId}/key/`, {}, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || ''
      },
      camelize: true
    })
  },
  getMessages(chatId: number, payload?: { lastClientMessageUuid?: string, wasRead: boolean }) {
    return medcardClient.get<{ results: TelemedMessageDto[], count: number }>(
      `${RESOURCE}/${chatId}/messages/patient/`,
      { params: { ...payload }, camelize: true }
    )
  },
  sendTelemedMessage(chatId: number, payload: SendTelemedMessagePayloadDto) {
    return medcardClient.post<TelemedMessageDto>(
      `${RESOURCE}/${chatId}/messages/patient/`,
      payload,
      { camelize: true }
    )
  },
  readTelemedMessage(chatId: number, uuid: string) {
    return medcardClient.post(
      `${RESOURCE}/${chatId}/messages/patient/read/`,
      { clientMessageUuid: uuid },
      { camelize: true }
    )
  },
  sendIsPatientOnline(chatId: number) {
    return medcardClient.post(`${RESOURCE}/${chatId}/online/patient/`, {}, { camelize: true })
  },
  sendIsPatientCallOnline(chatId: number) {
    return medcardClient.post(`${RESOURCE}/${chatId}/video_call/online/patient/`, {}, { camelize: true })
  }
}
