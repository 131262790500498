import { AxiosError } from 'axios'
import { isPlainObject } from 'lodash-es'

// TODO: тесты и комментарии
export const getErrorCode = (error: AxiosError<Array<unknown> | unknown>, fieldName?: string): string | undefined => {
  const data = error?.response?.data

  const errorData = Array.isArray(data) ? data[0] : data

  if (!isPlainObject(errorData)) {
    return undefined
  }

  const key = fieldName ?? Object.keys(errorData)[0]

  return errorData[key]?.code
}
