
import VClamp from 'vue-clamp'
import { useVModel } from '@vueuse/core'
import { debounce, isEqual, sortBy } from 'lodash-es'
import { BottomControlsButtons } from '~/core/components'
import SearchBar from '~/core/components/SearchBar/SearchBar.vue'
import FullscreenGallery from '~/core/components/FullscreenGallery/FullscreenGallery.vue'
import { GalleryExposed } from '~/core/components/FullscreenGallery/types'
import { getFileInfoFromBlob } from '~/core/functions'
import { DOCUMENTS_GETTERS } from '~/core/constants/store/medcard/documents'
import { computed, defineComponent, onMounted, PropType, ref, useNuxtApp, watch } from '~/bridge'
import { MedcardDocumentGroupListItemList } from '~/features/Medcard/types'
import { MedcardDocumentType } from '~/features/Medcard/enums'
import { MedcardApi } from '~/features/Medcard/api/medcard.api'
import { decryptFile } from '~/features/Medcard/functions'
import getDocumentGroupDate from '~/features/Medcard/functions/getDocumentGroupDate'

export default defineComponent({
  name: 'NewDocumentToFolderPopup',
  components: {
    FullscreenGallery,
    VClamp,
    SearchBar,
    BottomControlsButtons
  },
  props: {
    value: { type: Boolean, default: false },
    documentsIds: { type: Array as PropType<number[]>, default: () => [] }
  },
  emits: ['update:value', 'add:documents'],
  setup(props, { emit }) {
    const gallery = ref<GalleryExposed | null>(null)

    const documentSearchQuery = ref('')
    const filteredDocumentsGroups = ref<MedcardDocumentGroupListItemList[]>([])
    const documentIdOnPreview = ref<number | null>(null)
    const selectedDocumentsIds = ref<number[]>([])

    const isActive = useVModel(props, 'value', emit)
    const { $store } = useNuxtApp()

    const documentsGroups = computed<MedcardDocumentGroupListItemList[]>(() =>
      $store.getters[DOCUMENTS_GETTERS.GET_MEDCARD_DOCUMENT_GROUPS]
    )

    const isSubmitButtonDisabled = computed(() =>
      isEqual([...props.documentsIds].sort(), [...selectedDocumentsIds.value].sort())
    )

    onMounted(() => {
      watch(documentSearchQuery, debouncedGetFilteredDocumentsGroups, { immediate: true })
    })

    watch(isActive, (val) => {
      if (!val) {
        documentSearchQuery.value = ''

        return
      }

      selectedDocumentsIds.value = [...props.documentsIds]
      filteredDocumentsGroups.value = getFilteredDocumentsGroups()
    }, { immediate: true })

    function getFilteredDocumentsGroups(): MedcardDocumentGroupListItemList[] {
      if (!documentSearchQuery.value) {
        return documentsGroups.value
      }

      return (documentsGroups.value
        .map(group => [
          group[0],
          group[1].filter(item =>
            item.documentInfo.toLowerCase().includes(documentSearchQuery.value.toLowerCase())
          )
        ]) as MedcardDocumentGroupListItemList[])
        .filter(group => group[1].length)
    }

    function handleCheckboxChange(val: boolean, id: number) {
      if (val) {
        selectedDocumentsIds.value.push(id)
      } else {
        selectedDocumentsIds.value = selectedDocumentsIds.value.filter(item => item !== id)
      }
    }

    const debouncedGetFilteredDocumentsGroups = debounce(() => {
      filteredDocumentsGroups.value = getFilteredDocumentsGroups()
    }, 200)

    function handleClickSubmit() {
      emit('add:documents', selectedDocumentsIds.value)
      documentSearchQuery.value = ''
      isActive.value = false
    }

    async function handleShowGallery(documentId: number) {
      documentIdOnPreview.value = documentId

      const [{ data: keyData }, { data: documentGroupRaw }] = await Promise.all([
        MedcardApi.getKey(documentId),
        MedcardApi.get(documentId)
      ])

      const sortedDocuments = sortBy(await Promise.all(documentGroupRaw.documents), ['sortIndex'])
      const documentDate = getDocumentGroupDate(documentGroupRaw)

      gallery.value?.show(
        sortedDocuments.map((document, index) => ({
          id: document.id,
          source: async () => {
            const { data: encryptedFile } = await MedcardApi.getDocument(document.file)

            const blobPart = [await decryptFile(encryptedFile, keyData.key, document.iv)]
            const fileIndex = index ? `(${index})` : ''
            const fileInfo = await getFileInfoFromBlob(new Blob(blobPart))
            const fileName = `${documentGroupRaw.documentInfo} ${documentDate}${fileIndex}${fileInfo?.extension ?? '.pdf'}`

            return new File(
              blobPart,
              fileName,
              {
                type: fileInfo?.type
              }
            )
          },
          name: documentGroupRaw.documentInfo,
          date: documentDate
        }))
      )

      documentIdOnPreview.value = null
    }

    return {
      gallery,
      MedcardDocumentType,
      isActive,
      documentSearchQuery,
      filteredDocumentsGroups,
      documentIdOnPreview,
      isSubmitButtonDisabled,
      selectedDocumentsIds,
      handleClickSubmit,
      handleShowGallery,
      handleCheckboxChange
    }
  }
})

