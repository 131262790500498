import {
  ActivityQuestionValue,
  BloodPressureQuestionValue,
  ChildbirthType,
  ContraceptionMethodValue,
  DentalAndSurgicalCommonQuestionId,
  DentalQuestionId,
  FrequencyQuestionValue,
  GynecologicalQuestionId,
  OralHygieneQuestionValue,
  PregnancyQuestionValue,
  RelativeQuestionValue,
  SexualPartnerValue,
  SurgicalQuestionId,
  TherapeuticQuestionId,
  ToothbrushChangeQuestionValue
} from '~/core/components/Questionnaire/enums'

export const COMPONENTS_BIND = {
  [TherapeuticQuestionId.Profile]: 'QuestionnaireFormProfile',
  [TherapeuticQuestionId.HeightWeight]: 'QuestionnaireFormWeightHeight',
  [TherapeuticQuestionId.CovidContacts]: 'QuestionnaireFormCovidContacts',
  [TherapeuticQuestionId.VriSymptoms]: 'QuestionnaireFormVriSymptoms',
  [TherapeuticQuestionId.CovidVaccination]: 'QuestionnaireFormCovidVaccination',
  [TherapeuticQuestionId.ChronicDiseases]: 'QuestionnaireFormChronicDiseases',
  [TherapeuticQuestionId.Smoking]: 'QuestionnaireFormSmoke',
  [TherapeuticQuestionId.Activity]: 'QuestionnaireFormActivity',
  [TherapeuticQuestionId.Alcohol]: 'QuestionnaireFormAlcohol',
  [TherapeuticQuestionId.BloodPressure]: 'QuestionnaireFormBloodPressure',
  [TherapeuticQuestionId.BloodSugar]: 'QuestionnaireFormBloodSugar',
  [TherapeuticQuestionId.RelativesDiseases]: 'QuestionnaireFormRelativeDiseases',
  [TherapeuticQuestionId.Monitoring]: 'QuestionnaireFormMonitoring',
  [TherapeuticQuestionId.Drugs]: 'QuestionnaireFormDrugs',
  [TherapeuticQuestionId.Allergy]: 'QuestionnaireFormAllergy',
  [TherapeuticQuestionId.Pregnancy]: 'QuestionnaireFormPregnancy',
  [TherapeuticQuestionId.OtherInformation]: 'QuestionnaireFormOtherInfo',
  [GynecologicalQuestionId.FirstMenstruation]: 'QuestionnaireFormFirstMenstruation',
  [GynecologicalQuestionId.LastMenstruation]: 'QuestionnaireFormLastMenstruation',
  [GynecologicalQuestionId.PregnanciesNumber]: 'QuestionnaireFormPregnancyCount',
  [GynecologicalQuestionId.PregnanciesWithBirth]: 'QuestionnaireFormPregnancySuccessCount',
  [GynecologicalQuestionId.Childbirth]: 'QuestionnaireFormChildbirth',
  [GynecologicalQuestionId.SexualPartner]: 'QuestionnaireFormSexualPartner',
  [GynecologicalQuestionId.ContraceptionMethod]: 'QuestionnaireFormContraceptionMethod',
  [SurgicalQuestionId.SurgicalOperations]: 'QuestionnaireFormSurgicalOperations',
  [SurgicalQuestionId.Hazards]: 'QuestionnaireFormHazards',
  [SurgicalQuestionId.InvasiveDiagnostics]: 'QuestionnaireFormInvasiveDiagnostics',
  [DentalAndSurgicalCommonQuestionId.Implants]: 'QuestionnaireFormImplants',
  [DentalQuestionId.OralHygiene]: 'QuestionnaireFormOralHygiene',
  [DentalQuestionId.ToothbrushChange]: 'QuestionnaireFormToothbrushChange',
  [DentalQuestionId.InflammatoryDiseases]: 'QuestionnaireFormInflammatoryDiseases'
}

export const THERAPEUTIC_ORDERED_QUESTIONS_IDS = [
  TherapeuticQuestionId.Profile,
  TherapeuticQuestionId.HeightWeight,
  TherapeuticQuestionId.CovidContacts,
  TherapeuticQuestionId.VriSymptoms,
  TherapeuticQuestionId.CovidVaccination,
  TherapeuticQuestionId.ChronicDiseases,
  TherapeuticQuestionId.Smoking,
  TherapeuticQuestionId.Activity,
  TherapeuticQuestionId.Alcohol,
  TherapeuticQuestionId.BloodPressure,
  TherapeuticQuestionId.BloodSugar,
  TherapeuticQuestionId.RelativesDiseases,
  TherapeuticQuestionId.Monitoring,
  TherapeuticQuestionId.Drugs,
  TherapeuticQuestionId.Allergy,
  TherapeuticQuestionId.Pregnancy,
  TherapeuticQuestionId.OtherInformation
] as const

export const GYNECOLOGICAL_ORDERED_QUESTIONS_IDS = [
  GynecologicalQuestionId.FirstMenstruation,
  GynecologicalQuestionId.LastMenstruation,
  GynecologicalQuestionId.PregnanciesNumber,
  GynecologicalQuestionId.PregnanciesWithBirth,
  GynecologicalQuestionId.Childbirth,
  GynecologicalQuestionId.SexualPartner,
  GynecologicalQuestionId.ContraceptionMethod
] as const

export const SURGICAL_ORDERED_QUESTIONS_IDS = [
  SurgicalQuestionId.SurgicalOperations,
  SurgicalQuestionId.Hazards,
  SurgicalQuestionId.InvasiveDiagnostics
] as const

export const DENTAL_QUESTION_ORDERED_QUESTIONS_IDS = [
  DentalQuestionId.OralHygiene,
  DentalQuestionId.ToothbrushChange,
  DentalQuestionId.InflammatoryDiseases
] as const

export const ORDERED_QUESTIONS_IDS = [
  ...THERAPEUTIC_ORDERED_QUESTIONS_IDS,
  ...GYNECOLOGICAL_ORDERED_QUESTIONS_IDS,
  DentalAndSurgicalCommonQuestionId.Implants,
  ...SURGICAL_ORDERED_QUESTIONS_IDS,
  ...DENTAL_QUESTION_ORDERED_QUESTIONS_IDS
] as const

export const QUESTION_TEXT = {
  [TherapeuticQuestionId.Profile]: 'Укажите как вас зовут, дату рождения, город и пол',
  [TherapeuticQuestionId.HeightWeight]: 'Укажите ваш рост и вес',
  [TherapeuticQuestionId.CovidContacts]: 'Были ли у вас контакты в последние 14 дней с людьми, которым поставили диагноз новой коронавирусной инфекции?',
  [TherapeuticQuestionId.VriSymptoms]: 'Наблюдалось ли у вас повышение температуры свыше 37,1 °С, першение, боль в горле, насморк, затруднённое дыхание, боли в груди в течение последних 14 дней?',
  [TherapeuticQuestionId.CovidVaccination]: 'Проходили ли вы вакцинацию от Covid-19?',
  [TherapeuticQuestionId.ChronicDiseases]: 'Страдаете ли вы какими-либо хроническими заболеваниями?',
  [TherapeuticQuestionId.Smoking]: 'Вы курите?',
  [TherapeuticQuestionId.Activity]: 'Ваша двигательная активность?',
  [TherapeuticQuestionId.Alcohol]: 'Как часто употребляете алкоголь?',
  [TherapeuticQuestionId.BloodPressure]: 'Вас беспокоит изменение артериального давления?',
  [TherapeuticQuestionId.BloodSugar]: 'Было ли у вас в анамнезе повышение сахара крови?',
  [TherapeuticQuestionId.RelativesDiseases]: 'Страдают ли ваши ближайшие родственники сердечно-сосудистыми заболеваниями, сахарным диабетом, онкологическими заболеваниями?',
  [TherapeuticQuestionId.Monitoring]: 'Наблюдались ли вы в последнее время у врача?',
  [TherapeuticQuestionId.Drugs]: 'Принимаете ли вы какие-либо лекарственные препараты постоянно?',
  [TherapeuticQuestionId.Allergy]: 'Есть ли у вас аллергические проявления на прием/введение препаратов, в том числе реакции на анестезию?',
  [TherapeuticQuestionId.Pregnancy]: 'Планируете ли вы беременность?',
  [TherapeuticQuestionId.OtherInformation]: 'Другие важные сведения, которые вы бы хотели сообщить о себе',
  [GynecologicalQuestionId.FirstMenstruation]: 'Укажите возраст наступления первой менструации',
  [GynecologicalQuestionId.LastMenstruation]: 'Укажите дату первого дня последней менструации',
  [GynecologicalQuestionId.PregnanciesNumber]: 'Количество беременностей в течение жизни',
  [GynecologicalQuestionId.PregnanciesWithBirth]: 'Количество беременностей, завершившихся родами',
  [GynecologicalQuestionId.Childbirth]: 'Дата родов, характер родоразрешения',
  [GynecologicalQuestionId.SexualPartner]: 'Вы имеете постоянного полового партнёра?',
  [GynecologicalQuestionId.ContraceptionMethod]: 'Какие методы контрацепции вы используете?',
  [SurgicalQuestionId.SurgicalOperations]: 'Были ли у вас в течение жизни оперативные вмешательства?',
  [SurgicalQuestionId.Hazards]: 'Связана ли ваша профессиональная деятельность с вредными факторами?',
  [SurgicalQuestionId.InvasiveDiagnostics]: 'Проводились ли вам инвазивные диагностические исследования?',
  [DentalAndSurgicalCommonQuestionId.Implants]: 'Установлены ли в вашем организме импланты, приборы, протезы?',
  [DentalQuestionId.OralHygiene]: 'Как часто вы проводите гигиену полости рта?',
  [DentalQuestionId.ToothbrushChange]: 'Как часто вы меняете зубную щётку?',
  [DentalQuestionId.InflammatoryDiseases]: 'Имеете ли воспалительные заболевания слизистой полости рта?'
} as const

export const ACTIVITY_QUESTION_LABEL = {
  [ActivityQuestionValue.Sedentary]: 'Преимущественно сидячий образ жизни',
  [ActivityQuestionValue.SportOnceAWeek]: 'Занимаюсь спортом 1-2 раза в неделю',
  [ActivityQuestionValue.SportRegularly]: 'Занимаюсь спортом регулярно',
  [ActivityQuestionValue.Walking]: 'Не занимаюсь, но много хожу пешком'
} as const

export const ALCOHOL_QUESTION_LABEL = {
  [FrequencyQuestionValue.No]: 'Не употребляю',
  [FrequencyQuestionValue.Year]: 'Несколько раз в год',
  [FrequencyQuestionValue.Month]: 'Несколько раз в месяц',
  [FrequencyQuestionValue.Week]: 'Несколько раз в неделю'
} as const

export const RELATIVES_DISEASES_ITEM_LABEL = {
  [RelativeQuestionValue.Father]: 'Папа',
  [RelativeQuestionValue.Mother]: 'Мама',
  [RelativeQuestionValue.Son]: 'Сын',
  [RelativeQuestionValue.Daughter]: 'Дочь',
  [RelativeQuestionValue.Brother]: 'Родной брат',
  [RelativeQuestionValue.Sister]: 'Родная сестра',
  [RelativeQuestionValue.Grandfather]: 'Дедушка',
  [RelativeQuestionValue.GrandMother]: 'Бабушка'
} as const

export const BLOOD_PRESSURE_LABEL = {
  [BloodPressureQuestionValue.No]: 'Нет, не беспокоит',
  [BloodPressureQuestionValue.Low]: 'Да, артериальное давление пониженное',
  [BloodPressureQuestionValue.High]: 'Да, артериальное давление повышенное',
  [BloodPressureQuestionValue.Hypertonie]: 'Нет, не беспокоит, но медицинский работник говорил о том, что у меня гипертония',
  [BloodPressureQuestionValue.DidNotCheck]: 'Не проверял(а)'
} as const

export const PREGNANCY_LABEL = {
  [PregnancyQuestionValue.Planning]: 'Планирую',
  [PregnancyQuestionValue.NotPlanning]: 'Не планирую',
  [PregnancyQuestionValue.Pregnant]: 'Беременна сейчас',
  [PregnancyQuestionValue.BreastFeeding]: 'Кормящая мать'
} as const

export const CONTRACEPTION_METHOD_LABEL = {
  [ContraceptionMethodValue.Hormonal]: 'Гормональные контрацептивные препараты',
  [ContraceptionMethodValue.Barrier]: 'Методы барьерной контрацепции',
  [ContraceptionMethodValue.Other]: 'Другое',
  [ContraceptionMethodValue.NotUsing]: 'Не использую'
} as const

export const CHILDBIRTH_TYPE_LABEL = {
  [ChildbirthType.Natural]: 'Естественное родоразрешение',
  [ChildbirthType.Surgical]: 'Оперативное родоразрешение'
} as const

export const SEXUAL_PARTNER_LABEL = {
  [SexualPartnerValue.Yes]: 'Да',
  [SexualPartnerValue.No]: 'Нет',
  [SexualPartnerValue.NoContacts]: 'Не было половых контактов'
} as const

export const ORAL_HYGIENE_LABEL = {
  [OralHygieneQuestionValue.OnceADay]: 'Один раз в день',
  [OralHygieneQuestionValue.TwiceADay]: 'Два раза в день (утром и вечером)',
  [OralHygieneQuestionValue.AfterEating]: 'После каждого приема пищи'
} as const

export const TOOTHBRUSH_CHANGE_LABEL = {
  [ToothbrushChangeQuestionValue.ThreeMonths]: 'Раз в три месяца',
  [ToothbrushChangeQuestionValue.SixMonths]: 'Раз в шесть месяцев',
  [ToothbrushChangeQuestionValue.Year]: 'Раз в год или реже'
} as const

export * from './injects'
