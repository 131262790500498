import Cookies from 'js-cookie'
import { CookieKey } from '~/core/enums'
import { CORE_MUTATIONS } from '~/core/constants'
import { isAppSupported } from '~/core/functions'
import { onBeforeMount, useNuxtApp } from '~/bridge'

export const useSmartAppBannerSetup = () => {
  const { $store, $device, $sentry } = useNuxtApp()

  onBeforeMount(() => {
    const isIOSSafari = $device.isIos && $device.isSafari

    const isCookieWasSet = Cookies.get(CookieKey.AppSmartBannerWasClosed)

    const isActive = !isCookieWasSet && !isIOSSafari && isAppSupported({ $device, $sentry })

    $store.commit(CORE_MUTATIONS.SET_IS_SMART_APP_BANNER_ACTIVE, isActive)
  })
}
