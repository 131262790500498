import { masterClient } from '~/core/utils/clients/masterClient'
import { RateType } from '~/features/Rates/enums'

const RESOURCE = '/rates'

export type RatesHitsItemDto = {
  id: number
  hits: number
  newHits: number
}

export type RatesHitsDto = {
  total: {
    allHits: number
    newAllHits: number
    doctorHits: number
    newDoctorHits: number
    lpuHits: number
    newLpuHits: number
  }
  doctorRatesCount: RatesHitsItemDto[]
  lpuRatesCount: RatesHitsItemDto[]
}

export default {
  getRatesHit() {
    return masterClient.get<RatesHitsDto | null>(`${RESOURCE}/hits/`, { camelize: true })
  },
  getRateHitsById({ id, type }: { id: number, type: RateType.Clinic | RateType.Doctor }) {
    return masterClient.get<RatesHitsItemDto | null>(
      `${RESOURCE}/${type}/${id}/hits/`,
      { camelize: true }
    )
  }
}
