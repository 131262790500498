import { camelizeKeys } from 'humps'
import { QuestionnaireApi } from '~/core/api'
import { QuestionnaireAnswerDto } from '~/core/api/questionnaire.api.types'
import { aesDecryptor } from '~/core/utils/aesDecryptor'

export const getDecryptedQuestionnaire = async () => {
  const { data: decryptKeys } = await QuestionnaireApi.getDecryptKey()
  const { data } = await QuestionnaireApi.getEncryptedQuestionnaire()

  const promises = data.map(({ iv, answer, questionId }) => {
    const value = aesDecryptor('cbc', answer, decryptKeys.key, iv)
    const id = aesDecryptor('cbc', questionId, decryptKeys.key, decryptKeys.iv)
    const parsedAnswer = JSON.parse(value)

    return {
      questionId: id.trim(),
      answer: Object.keys(parsedAnswer).length ? camelizeKeys(parsedAnswer) : null
    } as QuestionnaireAnswerDto
  })

  return Promise.all(promises)
}
