export * from './setImage'
export * from './composeHits'

export const fieldLength = (field: string) => field
  ? field.replace(/[\s\n]+/g, ' ').length
  : 0

export const isFieldsLengthSmall = (fields: string[], minCharLength = 100) => {
  const fieldsLength = fields.reduce((sum, field) => sum + fieldLength(field), 0)

  if (fieldsLength < minCharLength) {
    return true
  }
}
