import Cookies from 'js-cookie'
import { masterClient } from '~/core/utils/clients/masterClient'
import { CookieKey } from '~/core/enums'
import { getFamilyProfileUuidHeader } from '~/core/functions/client/getFamilyProfileUuidHeader'
import { medcardClient } from '~/core/utils/clients/medcardClient'

const RESOURCE = '/mt_link'

export type MedcardSessionData = {
  // NOTE: Если undefined, значит все папки
  folderIds?: number[] | null
  femaleCalendarId?: number | null
}

export type MtlinkCreateSessionPayloadDto = {
  anonymousToken: string
  uuid: string
} & MedcardSessionData

export default {
  createMasterMtlinkSession({ anonymousToken, uuid }: { anonymousToken: string, uuid: string }) {
    return masterClient.post(`${RESOURCE}/session_tokens/`, {
      clientUuid: uuid,
      anonymousToken
    }, {
      headers: getFamilyProfileUuidHeader(),
      camelize: true
    })
  },
  createInfoMtlinkSession(payload: MtlinkCreateSessionPayloadDto) {
    const { anonymousToken, uuid, folderIds, femaleCalendarId } = payload

    return medcardClient.post(`${RESOURCE}/session_tokens/`, {
      clientUuid: uuid,
      anonymousToken,
      folderIds,
      femaleCalendarId
    }, { camelize: true })
  },
  closeMtlinkSession(payload: { id: number }) {
    return masterClient.delete(`${RESOURCE}/sessions/${payload.id}/`, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || ''
      },
      camelize: true
    })
  }
}
