export const parseFileToDataUrl = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const onLoad = () => {
      if (typeof reader.result !== 'string') {
        return reject(new Error('upload'))
      }

      reader.removeEventListener('load', onLoad)
      resolve(reader.result)
    }

    reader.addEventListener('load', onLoad)
    reader.readAsDataURL(file)
  })
}
