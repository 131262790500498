
import { defineComponent, onMounted, ref } from '~/bridge'

export default defineComponent({
  name: 'BottomControls',
  props: {
    row: { type: Boolean, default: false }
  },
  setup(props, { slots }) {
    const isExtended = ref(false)

    onMounted(() => {
      // NOTE: приходится создавать эту переменную, чтобы определять высоту статично позиционируемого элемента
      isExtended.value = !props.row && Number(slots.default?.().filter(v => v.elm instanceof Element).length) >= 2
    })

    return {
      isExtended
    }
  }
})
