
import { AppSmartBanner } from '~/core/components'
import RulesAgree from '~/core/components/RulesAgree/RulesAgree.vue'
import { MainDialog, MainSnackbar } from '~/core/components/dialogs'
import TownSelect from '~/core/components/TownSelect/TownSelect.vue'
import EnvironmentBanner from '~/core/components/EnvironmentBanner/EnvironmentBanner.vue'
import { computed, defineComponent, useNuxtApp, useRoute } from '~/bridge'

export default defineComponent({
  name: 'MobileLayout',
  components: {
    EnvironmentBanner,
    TownSelect,
    AppSmartBanner,
    MainDialog,
    MainSnackbar,
    RulesAgree
  },
  setup() {
    const { $device, $store } = useNuxtApp()
    const $route = useRoute()

    const shouldShowRulesAgreement = computed(() => $route.name !== 'authorization')
    const isShowAppSmartBanner = computed(() => $device.isMobile && $store.state.profile.phone)
    const isTelemedPage = computed(() => $route.name === 'appointments-id-telemed')

    return {
      shouldShowRulesAgreement,
      isShowAppSmartBanner,
      isTelemedPage
    }
  }
})
