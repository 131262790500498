import { medcardClient } from '~/core/utils/clients/medcardClient'

const RESOURCE = '/medcard/folders'

export type MedcardFolderDto = {
  id: number
  name: string
  documentGroups: number[]
}

type TransferDocumentsPayloadDto = {
  documentGroupIds: number[],
  folderIds: number[]
}

export const FoldersApi = {
  getMedcardFolders() {
    return medcardClient.get<MedcardFolderDto[]>(`${RESOURCE}/`, { camelize: true })
  },
  createMedcardFolder(payload: { name: string, documentGroupIds: number[] }) {
    return medcardClient.post(`${RESOURCE}/create/`, payload, { camelize: true })
  },
  updateMedcardFolder(id: number, payload: { name: string, documentGroupIds: number[] }) {
    return medcardClient.post(`${RESOURCE}/${id}/update/`, payload, { camelize: true })
  },
  transferDocuments(payload: TransferDocumentsPayloadDto) {
    return medcardClient.post(`${RESOURCE}/document_transfer/`, payload, { camelize: true })
  },
  deleteFolder(id: number) {
    return medcardClient.delete(`${RESOURCE}/${id}/delete/`, { camelize: true })
  }
}
