export const SNACKBAR_MESSAGE = {
  CLINIC_ADDED: {
    text: 'Клиника успешно сохранена.'
  },
  CLINIC_ADD_ERROR: {
    text: 'Произошла ошибка, филиал не сохранён. Попробуйте еще раз.'
  },
  CLINIC_REMOVED: {
    text: 'Клиника успешно удалена.'
  },
  CLINIC_REMOVE_ERROR: {
    text: 'Произошла ошибка, не получилось удалить клинику. Попробуйте еще раз.'
  }
}
