import { AxiosRequestHeaders } from 'axios'
import { FAMILY_PROFILE_UUID_STORAGE_KEY } from '~/core/constants/sessionStorage'

export function getFamilyProfileUuidHeader(): AxiosRequestHeaders {
  const familyProfileUuid = sessionStorage.getItem(FAMILY_PROFILE_UUID_STORAGE_KEY)

  return familyProfileUuid
    ? { 'Family-Profile-Uuid': familyProfileUuid }
    : {}
}
