import { infoClient } from '~/core/utils/clients/infoClient'

const RESOURCE = '/profile/email'

export type ProfileSettingsDto = {
  allowReceiveNewsEmails: boolean
  allowReceiveMedcardEmails: boolean
}

export default {
  requestAddition(payload: { email: string, masterMedcardToken: string }) {
    return infoClient.post<{ message: string, code: string }>(`${RESOURCE}/addition/request/`, payload, {
      // Здесь не нужен retry
      'axios-retry': { retries: 0 },
      camelize: true
    })
  },
  confirmAddition(payload: { email: string, masterMedcardToken: string, secretCode: string }) {
    return infoClient.post<{ message: string, code: string, medcardToken: string }>(
      `${RESOURCE}/addition/confirm/`,
      payload,
      {
      // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true
      }
    )
  },
  requestChange(payload: { masterMedcardToken: string }) {
    return infoClient.post<{ message: string, code: string }>(`${RESOURCE}/change/request/`, payload, {
      // Здесь не нужен retry
      'axios-retry': { retries: 0 },
      camelize: true
    })
  },
  confirmChange(payload: { masterMedcardToken: string, secretCode: string }) {
    return infoClient.post<{ message: string, code: string, medcardToken: string }>(
      `${RESOURCE}/change/confirm/`,
      payload,
      {
      // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true
      }
    )
  },
  requestMedcard(payload: { masterMedcardToken: string }) {
    return infoClient.post<{ message: string, code: string }>('/medcard/access/request/', payload, {
      // Здесь не нужен retry
      'axios-retry': { retries: 0 },
      camelize: true
    })
  },
  confirmMedcard(payload: { masterMedcardToken: string, secretCode: string }) {
    return infoClient.post<{ message: string, code: string, medcardToken: string }>(
      '/medcard/access/confirm/',
      payload,
      {
        // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true
      }
    )
  },
  requestProfileSettings(payload: ProfileSettingsDto) {
    return infoClient.post(`${RESOURCE}/settings/`, payload, { camelize: true })
  }
}
