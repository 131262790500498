export const getAndroidVersion = () => {
  try {
    return navigator.userAgent
      .match(/Android (\d+(?:\.\d+)*)/)?.[0]
      .replace('Android ', '')
      .replace(';', '') ?? null
  } catch {
    return null
  }
}
