import { HttpStatusCode } from '~/core/enums'

export const serverErrors = {
  userDoctor: {
    response: {
      status: 666
    },
    message: 'Вы авторизованы как врач. Чтобы открыть эту страницу, войдите в личный кабинет пациента.'
  },
  userLpu: {
    response: {
      status: 666
    },
    message: 'Вы авторизованы как представитель клиники. Чтобы открыть эту страницу, войдите в личный кабинет пациента.'
  }
} as const

export const globalErrors = {
  [HttpStatusCode.Unauthorized]: {
    code: HttpStatusCode.Unauthorized,
    title: 'Требуется регистрация',
    text: () => 'Попробуйте обновить страницу',
    img: '/graphics/401.svg',
    width: 232,
    height: 209,
    buttonColor: 'primary',
    buttonText: 'Зарегистрироваться'
  },
  [HttpStatusCode.Forbidden]: {
    code: HttpStatusCode.Forbidden,
    title: 'Нет доступа',
    text: () => 'К сожалению, у вас нет доступа к данной странице.',
    img: '/graphics/403.svg',
    width: 113,
    height: 150,
    buttonColor: 'primary',
    buttonText: 'На главную'
  },
  [HttpStatusCode.NotFound]: {
    code: HttpStatusCode.NotFound,
    title: 'Страница не найдена',
    text: () => 'К сожалению, мы не можем найти данную страницу.',
    img: '/graphics/404.svg',
    width: 174,
    height: 214,
    buttonColor: 'primary',
    buttonText: 'На главную'
  },
  [HttpStatusCode.InternalServerError]: {
    code: HttpStatusCode.InternalServerError,
    title: 'Что-то пошло не так',
    text: () => 'Попробуйте немного подождать и\u00A0обновить страницу',
    img: '/graphics/500.svg',
    width: 235,
    height: 228,
    buttonColor: 'primary',
    buttonText: 'На главную'
  },
  [serverErrors.userDoctor.response.status]: {
    code: serverErrors.userDoctor.response.status,
    title: 'Только для пациентов',
    text: (type: string) => type,
    img: '/graphics/403.svg',
    width: 235,
    height: 228,
    buttonColor: 'secondary',
    buttonText: 'войти как пациент'
  }
} as const
