import { tryOnBeforeUnmount, tryOnMounted, useKeyModifier } from '@vueuse/core'
import { Ref } from '~/bridge'

type UseScrollZoomPayload = {
  target: Ref<HTMLElement | null>,
  onZoom: (zoomFactor: number) => void
}

type UseScrollZoom = (payload: UseScrollZoomPayload) => void

export const useScrollZoom: UseScrollZoom = ({ target, onZoom }) => {
  const isControlActive = useKeyModifier('Control')

  const wheelListener = (ev: WheelEvent) => {
    if (!isControlActive.value) {
      return
    }

    ev.preventDefault()
    onZoom(ev.deltaY < 0 ? 1.1 : 0.9)
  }

  tryOnMounted(() => {
    target.value?.addEventListener('wheel', wheelListener, { passive: false })
  })

  tryOnBeforeUnmount(() => {
    target.value?.removeEventListener('wheel', wheelListener)
  })
}
