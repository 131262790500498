
import BottomControls from '~/core/components/BottomControls/BottomControls.vue'
import { defineComponent, PropType } from '~/bridge'

/**
 * Компонент обёртка вокруг BottomControls с двумя/одной кнопкой */
export default defineComponent({
  name: 'BottomControlsButtons',
  components: { BottomControls },
  props: {
    submitProps: { type: Object as PropType<Record<string, unknown>>, default: () => ({}) },
    cancelProps: { type: Object as PropType<Record<string, unknown>>, default: () => ({}) },
    onlySubmit: { type: Boolean, default: false },
    column: { type: Boolean, default: false },
    submitText: { type: String, default: 'подтвердить' },
    cancelText: { type: String, default: 'отменить' },
    submitIcon: { type: String, default: '' },
    cancelIcon: { type: String, default: '' }
  },
  emits: ['click:submit', 'click:cancel'],
  setup(_, { emit }) {
    function emitClickSubmit() {
      emit('click:submit')
    }

    function emitClickCancel() {
      emit('click:cancel')
    }

    return {
      emitClickSubmit,
      emitClickCancel
    }
  }
})
