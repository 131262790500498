
import AppMenu from '~/core/components/AppMenu/AppMenu.vue'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import { computed, defineComponent, onMounted, ref, useNuxtApp } from '~/bridge'

export default defineComponent({
  name: 'AppBar',
  components: {
    AppMenu
  },
  props: {
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    backTo: { type: Boolean, default: false },
    exact: { type: Boolean, default: false },
    logo: { type: Boolean, default: false },
    title: { type: String, default: '' },
    borderless: { type: Boolean, default: false },
    hideOnScroll: { type: Boolean, default: true },
    extensionHeight: { type: [Number, String], default: 48 }
  },
  emits: [
    'click:prepend-icon',
    'click:append-icon'
  ],
  setup(props, { emit }) {
    const { $device, $store, $vuetify } = useNuxtApp()

    const isMenuActive = ref(false)
    const appBar = ref<{
      currentScroll: number,
      currentThreshold: number,
      isActive: boolean
    } | null>(null)

    const appBarStyle = computed(() => ({
      paddingRight: $device.isDesktop && $store.state.overlaysCount > 0
        ? `${$vuetify.breakpoint.scrollBarWidth}px`
        : '0'
    }))

    const prepend = computed(() => {
      if (props.prependIcon) {
        return props.prependIcon
      }

      if (props.backTo) {
        return 'ui-icon-arrow-back'
      }

      return 'ui-icon-menu'
    })

    onMounted(() => {
      // @hack: https://github.com/vuetifyjs/vuetify/issues/9993
      window.addEventListener('scroll', () => {
        if (!appBar.value) {
          return
        }

        if (appBar.value.currentScroll < appBar.value.currentThreshold) {
          appBar.value.isActive = true
        }
      })
    })

    function handleClickPrependIcon() {
      if (!props.backTo && !props.prependIcon) {
        ymGoal('clickMain')
        isMenuActive.value = true
      }

      emit('click:prepend-icon')
    }

    return {
      isMenuActive,
      appBarStyle,
      prepend,
      handleClickPrependIcon
    }
  }
})
