import type { MutationTree } from 'vuex'

export const state = () => ({
  isActive: false,
  afterTownSelectHandler: null
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  open(state, payload) {
    state.isActive = true
    state.afterTownSelectHandler = payload?.afterTownSelectHandler
  },
  close(state) {
    state.isActive = false
    state.afterTownSelectHandler = null
  }
}
