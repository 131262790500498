import { masterClient } from '~/core/utils/clients/masterClient'

const RESOURCE = '/analytics'

export default {
  sendReferralId(id: number) {
    return masterClient.post(`${RESOURCE}/referral/metric/${id}/`, {}, { camelize: true })
  },
  sendOpenMt() {
    return masterClient.post(`${RESOURCE}/open_mt/`, {}, { camelize: true })
  },
  getRespondentContacts() {
    return masterClient.get<{ hasRespondentContact: boolean }>(
      `${RESOURCE}/respondents/retrieve/`,
      { camelize: true }
    )
  },
  sendRespondentContacts(contact: string) {
    return masterClient.post(`${RESOURCE}/respondents/create/`, { contact }, { camelize: true })
  },
  skipRespondentFeedback() {
    return masterClient.patch(`${RESOURCE}/respondents/skip/`, {}, { camelize: true })
  }
}
