export const newDateFromStr = (date: string): Date => {
  const dateArray = date.split(/[- T:]/).map(date => +date)
  let result!: Date

  try {
    result = new Date(
      dateArray[0],
      dateArray[1] - 1,
      dateArray[2],
      dateArray[3] || 0,
      dateArray[4] || 0,
      dateArray[5] || 0
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`${error}; INVALID DATE. INPUT DATE: ${date}`)
  }

  return result
}
