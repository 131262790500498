import { reduce } from 'lodash-es'

export const removeNamespace = <T extends Record<string, string>>(namespace: string, types: T) => {
  return reduce<T, T>(
    types,
    (result, value, key) => {
      return {
        ...result,
        [key]: value.replace(namespace, '')
      }
    },
    {} as T
  )
}
